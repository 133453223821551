<script>
  export let key;
  export let value;
</script>

<span
  class:text-green-500={key === "success"}
  class:text-red-500={key === "fail"}
  class:text-purple-500={key === "ready"}
  class:text-blue-500={key === "sent"}
  class:text-yellow-500={key === "created"}
  class:text-indigo-500={key.startsWith("repair")}
>
  {@html value}
</span>
