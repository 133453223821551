<script>
  import page from "page";
  import { onMount } from "svelte";
  import DateChoices from "../lib/components/crm/DateChoices.svelte";
  import Checkbox from "../lib/components/Checkbox.svelte";
  import IMask from "imask";
  import { request } from "../lib/tools/request";
  import { getSearchParam } from "../lib/tools/searchParams";
  import { formatSize, reverseSizesMap } from "../lib/stores/sizeLabels";

  export let params = {};
  const saleReturnItems = {};
  let loading = false;
  let saleReturn = {
    returnDate: "",
    comment: "",
    sale: {},
  };

  let saleId = getSearchParam("saleId")[0] ?? null;
  let sale = {
    saleItems: [],
    startDate: "",
    id: "",
  };

  let errors = {};

  $: saleReturnId = params.id === "add" ? null : params.id;
  $: saleTitle = sale.id ? `#${sale.id} от ${sale.startDate}` : "";

  const fetchSale = async () => {
    if (!saleId) {
      return;
    }
    const { ok, data } = await request({
      path: `sales/${saleId}`,
    });

    if (ok) {
      sale = data;
      sale.saleItems.map((s) => {
        saleReturnItems[s.id] = s.saleReturnId === saleReturn.id;
      });
    }
  };

  const fetchSaleReturn = async () => {
    if (!saleReturnId) {
      return;
    }

    const { ok, data } = await request({
      path: `sale-returns/${saleReturnId}`,
    });

    if (ok) {
      saleReturn = data;
      saleId = saleReturn.saleId;
    }
  };

  const save = async () => {
    loading = true;
    errors = {};
    const path = saleReturn.id
      ? `sale-returns/${saleReturn.id}`
      : "sale-returns";

    const { ok, data, error } = await request({
      path,
      json: {
        ...saleReturn,
        sale: undefined,
        saleId: sale.id ?? saleReturn.sale.id,
        items: Object.entries(saleReturnItems)
          .filter((v) => v[1])
          .map((v) => v[0]),
      },
    });

    loading = false;
    if (!ok) {
      errors = error;
    } else {
      page(`/crm/sale-returns/${data.id}`);
    }
  };

  onMount(async () => {
    loading = true;
    document.querySelectorAll("[data-date]").forEach((e) => {
      IMask(e, {
        mask: Date,
      });
    });
    await fetchSaleReturn();
    await fetchSale();
    loading = false;
  });
</script>

<div class="w-full bg-white px-10 pt-5 pb-14 min-h-screen">
  <div class="flex flex-col gap-5 w-full md:w-1/2">
    <div><h1 class="text-2xl font-bold">Возврат</h1></div>
    <div class="flex flex-col gap-5">
      <div class="flex flex-col gap-1">
        <span class="text-sm">Сделка</span>
        <div
          class="text-blue-400 flex items-center w-full h-11 border border-slate-200 rounded-xl px-2"
        >
          {saleTitle}
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <span class="text-sm">Дата сделки</span>
        <div
          class="w-full flex items-center h-11 border border-slate-200 rounded-xl px-2"
        >
          {sale.id ? sale.startDate : ""}
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <label for="returnDate" class="text-sm">Дата возврата</label>
        <input
          data-date
          name="returnDate"
          type="text"
          class:border-red-400={!!errors.returnDate}
          class:border-slate-200={!errors.returnDate}
          class="w-full h-11 border rounded-xl px-2"
          bind:value={saleReturn.returnDate}
        />
        <div class="flex gap-2">
          <DateChoices bind:value={saleReturn.returnDate} />
        </div>
        <div class="text-red-400 text-sm w-full">{errors.returnDate ?? ""}</div>
      </div>
    </div>
    <div class="h-px w-full bg-slate-200" />
    <div><h2 class="text-xl font-bold">Товары</h2></div>
    <div>
      <h3 class="text-sm">Выберите товары, которые необходимо вернуть</h3>
    </div>
    <div>
      <h3 class="text-red-400">{errors.items ?? ""}</h3>
    </div>
    <div class="flex flex-col gap-1">
      {#each sale.saleItems as saleItem, index}
        <div class="flex flex-col gap-1">
          <label for="saleItem{index}" class="text-sm font-bold"
            >Позиция {index + 1}
            <span class="text-red-400 text-sm font-normal"
              >{errors.itemList?.[saleItem.id] ?? ""}
            </span>
          </label>
          <div class="flex w-full justify-between items-center gap-3">
            <div class="size-6 aspect-square">
              <Checkbox
                disabled={saleItem.saleReturnId != saleReturn.id}
                bind:checked={saleReturnItems[saleItem.id]}
              />
            </div>
            <div
              class="flex items-center w-full h-11 border border-slate-200 rounded-xl px-2"
            >
              {saleItem.item.title}
            </div>
            <div
              class="flex min-w-24 items-center h-11 border border-slate-200 rounded-xl px-2 w-fit grow-0"
            >
              {formatSize(reverseSizesMap[saleItem.size])}
            </div>
          </div>
        </div>
      {/each}
    </div>
    <div class="h-px w-full bg-slate-200" />
    {#if !saleReturn.id}
      <div
        class="flex flex-col gap-3 w-full rounded-xl bg-yellow-500/5 border border-b-4 border-yellow-500 px-2 py-5"
      >
        <div class="flex gap-2 h-5 w-full">
          <div class="size-6 aspect-square">
            <Checkbox
              disabled={saleReturn.id}
              bind:checked={saleReturn.createStorageTransfer}
              id="returnToStorageCheckbox"
            />
          </div>
          <label for="returnToStorageCheckbox" class="font-bold cursor-pointer"
            >Вернуть на склад</label
          >
        </div>
        <div>Товары вернутся на склад списания</div>
      </div>
      <div class="h-px w-full bg-slate-200" />
    {/if}
    <div class="flex flex-col gap-1">
      <label for="comment" class="text-sm">Комментарий</label>
      <textarea
        name="comment"
        class="w-full border border-slate-200 rounded-xl px-2 py-2 h-32"
        bind:value={saleReturn.comment}
      ></textarea>
    </div>
    {#if saleReturnId === null}
      <div class="w-full">
        <button
          on:click={save}
          class="w-full bg-blue-400 rounded-3xl text-white flex justify-center items-center py-4"
        >
          {#if loading}
            <span class="material-symbols-outlined animate-spin"
              >progress_activity</span
            >
          {:else}
            Сохранить
          {/if}
        </button>
      </div>
    {/if}
  </div>
</div>
