let csrfToken = "";

export const getBaseUrl = () => {
  const envOrigin = import.meta.env.VITE_API_ENDPOINT;
  return envOrigin ? envOrigin : window.location.origin + "/api/";
};

export async function refreshCSRF() {
  const { ok, data, error } = await request({
    path: "csrf",
  });

  if (ok) {
    csrfToken = data.csrf;
  }

  return {
    ok,
    data,
    error,
  };
}

export async function request({
  path = "",
  params = null,
  json = null,
  method = "GET",
}) {
  const url = new URL(path, getBaseUrl());

  if (!url.pathname.endsWith("/")) {
    url.pathname += "/";
  }

  for (const key in params || []) {
    if (params[key] !== undefined && params[key] !== null) {
      if (Array.isArray(params[key])) {
        params[key].forEach((p) => url.searchParams.append(key, p));
      } else {
        url.searchParams.set(key, params[key]);
      }
    }
  }

  let body;
  if (json) {
    method = "POST";
    if (json.tagName === "FORM") {
      json = Object.fromEntries(new FormData(json));
    }
    body = JSON.stringify(json);
  }

  if (json?.id) {
    method = "PATCH";
  }

  const options = {
    method,
    params,
    body,
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  let response;
  let jsonResponse;

  try {
    response = await fetch(url, options);
    if (method !== "DELETE") {
      jsonResponse = await response.json();
    }
  } catch {
    return {
      ok: false,
      data: null,
      error: { detail: "Bad response type" },
    };
  }

  let [data, error] = [null, null];
  if (response.ok) {
    data = jsonResponse;
  } else {
    error = jsonResponse;
  }

  if (error?.detail && error.detail.search(/csrf/gi) !== -1) {
    const { ok } = await refreshCSRF();
    if (ok) {
      return await request({
        path,
        params,
        json,
        method,
      });
    }
  }

  return {
    ok: response.ok,
    data,
    error,
  };
}

export async function uploadFile(file) {
  if (!csrfToken) {
    await refreshCSRF();
  }

  const fd = new FormData();
  fd.append("file", file);

  const url = new URL("file-upload/", getBaseUrl());
  const response = await fetch(url, {
    method: "POST",
    body: fd,
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken,
    },
  });

  return await response.json();
}
