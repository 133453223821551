<script>
    export let tablet = false;
    export let header = false;
    export let bold = false;
    export let light = false;
    export let wide = false;
    export let centered = false;
    export let order = '';

</script>

<div class="field x-order-{order}" class:header class:tablet class:bold class:light class:wide class:centered>
    <slot></slot>
</div>


<style>
    .field {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .tablet {
        display: none;
    }

    .bold {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }

    .light {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #9598AC;
    }

    .wide {
        grid-column: span 3;
    }

    .header {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #9598AC;
        display: flex;
        justify-content: center;
    }

    @media only screen and (max-width: 1180px) {
        .x-order-0 {
            order: 0;
        }

        .x-order-1 {
            order: 1;
        }

        .x-order-2 {
            order: 2;
        }

        .x-order-3 {
            order: 3;
        }

        .x-order-4 {
            order: 4;
        }

        .x-order-5 {
            order: 5;
        }

        .x-order-6 {
            order: 6;
        }
    }

    @media only screen and (min-width: 1180px) {
        .tablet {
            display: flex;
        }

        .centered {
            width: 100%;
            align-items: center;
        }
    }
</style>
