<script>
  export let label;
  export let name;
  export let value = null;
  export let error = {};
  export let required = false;
</script>

<div class="flex flex-col md:flex-row gap-1 w-full md:gap-5">
  <div class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400">
    {label}
  </div>
  <div class="grow">
    <div class="h-12">
      <slot name="input">
        <input
          type="text"
          {name}
          bind:value
          {required}
          class="h-full w-full border-1 border-slate-200 px-2.5"
        />
      </slot>
    </div>
    {#if error[name]}
      <div class="text-red-400">
        <span>{error[name]}</span>
      </div>
    {/if}
  </div>
</div>
