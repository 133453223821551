<script>
  import { request } from "../tools/request";
  import { sizeLabels } from "../stores/sizeLabels.js";
  import StorageItemPopup from "./StorageItemPopup.svelte";
  import { tableRowKey } from "../stores/tableRowKey.js";
  import { storagePopupKey } from "../stores/storagePopupKey.js";
  import Num from "./Num.svelte";

  export let storageId;
  export let item;
  export let storageItem = null;
  export let readonly = false;
  export let showVendorCode = false;

  let loading = false;

  $: key = `${storageId || "total"}_${storageItem?.item}_${
    storageItem?.id || "total"
  }`;
  $: active = $tableRowKey === key;
  $: fetchStorageItem(storageId);
  $: zero = storageItem?.total === 0;

  const fetchStorageItem = async (storageId, force) => {
    if (storageItem && !force) {
      return;
    }
    const path = storageId ? "storage-items" : "storage-items/total";
    loading = true;
    const { ok, data } = await request({
      path,
      params: {
        item: item.id,
        storage: storageId,
      },
    });

    if (!ok) {
      loading = false;
      return;
    }

    // wtf is this
    let thisStorageItem = data.results.find((i) => {
      return i.item.id === item.id;
    });

    if (!thisStorageItem && storageId) {
      const { ok } = await request({
        path: "storage-items",
        json: {
          storage: storageId,
          item: item.id,
        },
      });
      if (!ok) {
        loading = false;
        return;
      }
      await fetchStorageItem(storageId);
      return;
    }

    loading = false;
    storageItem = { ...thisStorageItem };
  };

  const selectItem = () => {
    tableRowKey.set(active ? "" : key);
    storagePopupKey.set("");
  };
</script>

<tr
  on:click={selectItem}
  class="text-center cursor-pointer {active ? 'bg-blue-300' : ''}"
>
  {#if storageItem}
    <td
      class="{zero
        ? 'opacity-20'
        : ''} border border-l-0 border-r-0 md:pl-5 px-2 align-middle w-[500px]"
      colspan="4"
    >
      <slot />
    </td>
    {#if showVendorCode && storageItem.item.vendorCode}
      <td
        class="{zero
          ? 'opacity-20'
          : ''} text-xs border border-l-0 border-r-0 py-1 px-2 align-middle"
      >
        {storageItem.item.vendorCode}
      </td>
    {/if}
    <td
      class="{zero
        ? 'opacity-20'
        : ''} border border-l-0 border-r-0 py-1 px-2 align-middle"
    >
      <span>
        {#if loading}
          <span class="material-symbols-outlined animate-spin"
            >progress_activity</span
          >
        {:else}
          <Num value={storageItem.total} />
        {/if}
      </span>
    </td>
    {#each sizeLabels as size}
      <StorageItemPopup
        on:updated={() => {
          fetchStorageItem(storageId, true);
        }}
        readonly={!storageId}
        showPopup={!readonly}
        bind:storageItem
        {item}
        rowKey={key}
        {active}
        {size}
      />
    {/each}

    <td
      class="{zero
        ? 'opacity-20'
        : ''} border border-l-0 border-r-0 py-1 px-2 align-middle"
    >
      <span><Num value={item.price} /></span>
    </td>
    <td
      class="{zero
        ? 'opacity-20'
        : ''} border border-l-0 border-r-0 py-1 px-2 align-middle"
    >
      <span><Num value={storageItem.totalPrice} /></span>
    </td>
  {:else}
    <td></td>
  {/if}
</tr>
