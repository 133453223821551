<script>
  import Modal from "./Modal.svelte";
  import Button from "./Button.svelte";
  import {request} from "../tools/request";
  import {categories} from "../stores/categories";

  export let active = false;
  let activeCategory;
  let form;

  $: if (active) {
    activeCategory = null;
  }

  async function save(e) {
    e.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    let path = "categories";
    if (activeCategory.id) {
      path += `/${activeCategory.id}`;
    }
    const {ok} = await request({
      path: path,
      json: form,
    });
    if (ok) {
      categories.get();
      active = false;
    }
  }

  async function deleteCategory(e) {
    e.preventDefault();
    const {ok} = await request({
      path: `categories/${activeCategory.id}`,
      method: "DELETE",
    });

    if (ok) {
      categories.get();
      active = false;
    }
  }

  const download = async (categoryId) => {
    const {ok, data} = await request({
      path: 'items/create_export_url',
      method: "POST",
      params: {
        category: categoryId,
      },
    })
    if (ok) {
      window.open(data.url, '_blank')
    }
  }
</script>

<Modal bind:active>
  <form bind:this={form} class="contents">
    <div class="flex h-full flex-col gap-2.5">
      <div class="text-xl font-bold">
        {#if activeCategory}
          {#if activeCategory.id}
            Редактирование {activeCategory.title}
          {:else}
            Новая категория
          {/if}
        {:else}
          Категории
        {/if}
      </div>
      {#if activeCategory}
        <input type="hidden" bind:value={activeCategory.id} name="id"/>
        <input
          class="h-12 w-full border-1 border-slate-200 px-2.5"
          type="text"
          name="title"
          bind:value={activeCategory.title}
          required
        />
      {:else}
        <div>
          {#each $categories as category}
            <div
              on:keydown
              class="flex h-12 w-full cursor-pointer items-center border-t-1 border-l-1 border-r-1 border-slate-200 px-2.5 last:border-b-1"
              on:click={() => {
                activeCategory = category;
              }}
            >
              {category.title}
              <div class="ml-auto">
                <button class="transition flex h-full w-full items-center justify-center rounded-xl border border-slate-200 px-2.5 text-sm"
                  on:click|stopPropagation|preventDefault={() => download(category.id)}>
                  <i class="material-symbols-outlined text-xl text-green-500">download</i>
                </button>
              </div>
            </div>
          {/each}
        </div>
      {/if}
      <div class="h-9">
        {#if activeCategory}
          <Button accent on:click={save}>Сохранить</Button>
        {:else}
          <Button
            accent
            on:click={(e) => {
              e.preventDefault();
              activeCategory = {};
            }}
          >Добавить
          </Button>
        {/if}
      </div>
      <div class="h-9">
        {#if activeCategory}
          <div class="flex h-full gap-2">
            <Button
              danger
              disabled={activeCategory.id === 1}
              on:click={deleteCategory}
            >Удалить
            </Button>
            <Button
              secondary
              on:click={() => {
                activeCategory = null;
              }}
            >Назад
            </Button>
          </div>
        {:else}
          <Button
            secondary
            on:click={() => {
              active = false;
            }}
          >Закрыть
          </Button>
        {/if}
      </div>
    </div>
  </form>
</Modal>
