<script>
    export let title;
    export let count;
</script>

<div class="wrapper bg-white">
    <div>
        <span class="title">
            {title}
        </span>
        <span class="count">
            {count}
        </span>
    </div>
    <div class="button-row">
        <slot></slot>
        <div class="last">
            <slot name="last">

            </slot>
        </div>
    </div>
</div>

<style>
    .wrapper {
        display: flex;
        padding: 20px 20px 10px 20px;
        flex-direction: column;
        gap: 10px;
        border-bottom: 1px solid #F1F2F5;
    }

    .button-row {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 6px;
    }

    .last {
        width: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
    }

    .count {
        color: #9598AC;
        font-weight: 500;
        font-size: 18px;
    }

    @media only screen and (min-width: 1180px) {
        .last {
            margin-left: auto;
            width: auto;
        }
    }
</style>
