<script>
    import {slide} from 'svelte/transition';

    export let errors;
</script>

{#if errors?.data}
    <div class="fixed bottom-0 left-0 p-5 flex flex-col gap-2.5" transition:slide>
        {#each errors.data?.items || [] as item, index}
            {#each Object.entries(item.sizes || {}) as size}
                {#each Object.entries(size[1] || {}) as err}
                    <div class="bg-red-300 text-red-500 px-2 py-2 rounded-lg">
                        <span>{index + 1}: {err[1]}</span>
                    </div>
                {/each}
            {/each}
        {/each}
        {#each (Object.entries(errors?.data?.nonFieldErrors || [])) as err}
            <div class="bg-red-300 text-red-500 px-2 py-2 rounded-lg">
                <span>{err[1]}</span>
            </div>
        {/each}
    </div>
{/if}