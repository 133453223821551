<script>
    export let title;
    export let onClick = () => {};
    export let accent = false;
</script>


<button class="button" class:accent on:click={onClick}>
    {title}
</button>


<style>
    .button {
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D8D8DA;
        border-radius: 8px;
        padding: 6px 10px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        background-color: white;
    }

    .button:hover {
        color: black;
    }

    .button.accent {
        color: white;
        background-color: #0071E8;
    }
</style>