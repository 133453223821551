<script>
  const inputId = $$restProps.id ?? `${Math.round(Math.random() * 10000000)}`;
  export let checked = false;
</script>

<label
  for={inputId}
  class="has-[:disabled]:bg-slate-100 has-[:disabled]:cursor-not-allowed cursor-pointer flex size-full rounded border border-slate-200 p-1"
>
  <div
    class:bg-blue-500={checked}
    class:border-blue-400={checked}
    class:border={checked}
    class="transition-colors duration-100 size-full rounded-sm"
  ></div>
  <input
    {...$$restProps}
    id={inputId}
    type="checkbox"
    class="size-[0.1px]"
    bind:checked
  />
</label>
