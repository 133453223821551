<script>
  import { storageTransferHistoryItem } from "../stores/storageTransferHistoryItem";
  import { request } from "../tools/request";
  import Modal from "./Modal.svelte";
  import SizeLabel from "./SizeLabel.svelte";
  import { reverseSizesMap, sizeLabels, sizesMap } from "../stores/sizeLabels";
  import { getTransferTitle } from "../tools/transfer";

  export let storage;

  $: active = !!$storageTransferHistoryItem?.id;

  let selectedSize = [];
  let loading = true;
  let error = false;
  let next = {};
  let history = {
    storage: {},
    history: [],
  };

  $: if (active) {
    next = {};
    selectedSize = [$storageTransferHistoryItem.size];
    fetchStorageItemHistory();
  }

  const selectSize = async (size) => {
    if (selectedSize.find((s) => s === size)) {
      selectedSize = selectedSize.filter((s) => s !== size);
    } else {
      selectedSize = [...selectedSize, size];
    }
    next = {};
    await fetchStorageItemHistory();
  };

  const fetchStorageItemHistory = async () => {
    loading = true;
    error = false;
    const { ok, data } = await request({
      path: `storage-items/${$storageTransferHistoryItem.id}/history`,
      params: {
        ...next,
        size: selectedSize.map((s) => sizesMap[s]).join(","),
      },
    });

    error = !ok;
    if (ok) {
      history = data;
      if (data.next) {
        next = Object.fromEntries(new URL(data.next).searchParams.entries());
      }
    }
    loading = false;
  };
</script>

<Modal bind:active onClose={() => storageTransferHistoryItem.set({})}>
  <div class="w-full flex flex-col gap-1 p-2.5">
    {#if $storageTransferHistoryItem.item.title}
      <h2 class="text-lg font-bold">
        {$storageTransferHistoryItem.item.title}
      </h2>
    {/if}
    <div class="w-full py-2.5 flex flex-wrap gap-1">
      {#each sizeLabels as size}
        <button
          on:click={() => selectSize(size)}
          class:bg-blue-400={selectedSize.find((s) => s === size)}
          class:text-white={selectedSize.find((s) => s === size)}
          class="uppercase px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
        >
          <SizeLabel {size} />
        </button>
      {/each}
    </div>
    <h3 class="font-bold">{storage.title}</h3>
    {#if loading}
      <div class="w-full flex justify-center items-center py-5">
        <span class="material-symbols-outlined animate-spin"
          >progress_activity</span
        >
      </div>
    {:else if error}
      <div class="w-full py-5">
        <span class="text-red-400">Не удалось загрузить историю</span>
      </div>
    {:else}
      {#if history.results.length}
        <div class="flex flex-col gap-1 pt-2">
          {#each history.results as each}
            <div class="w-full flex flex-wrap gap-2 text-sm items-center">
              {#if each.transfer}
                <span class="material-symbols-outlined text-blue-400"
                  >arrow_split</span
                >
              {:else if each.hasOrder}
                <span class="material-symbols-outlined text-green-500"
                  >shopping_cart_checkout</span
                >
              {:else if each.sale}
                <span class="material-symbols-outlined text-yellow-500"
                  >shopping_bag</span
                >
              {:else}
                <span class="material-symbols-outlined text-gray-500"
                  >app_registration</span
                >
              {/if}
              <span class="uppercase"
                ><SizeLabel size={reverseSizesMap[each.size]} />:</span
              >
              <div>
                <span class="line-through">{each.old}</span> →
                <span>{each.new}</span>
              </div>
              {#if each.transfer}
                <a
                  href="/transfers/{each.transfer.id}"
                  class="text-slate-400 underline"
                  target="_blank"
                  >({getTransferTitle(each.transfer)} #{each.transfer.id})</a
                >
              {/if}
              <span class="text-slate-400">({each.user.username})</span>
              <span class="text-slate-400"
                >{new Date(each.createdAt).toLocaleString()}</span
              >
              <span class="text-slate-400">{each.comment}</span>
            </div>
          {/each}
          <div class="w-full h-12 pt-2">
            {#if history.next}
              <button
                class="h-full flex gap-1 justify-center whitespace-nowrap items-center rounded-xl border-slate-200 px-2.5 text-sm disabled:cursor-not-allowed bg-blue-400 text-white border-0"
                on:click={fetchStorageItemHistory}
                >Далее
              </button>
            {/if}
          </div>
        </div>
      {:else}
        <div class="w-full py-5">
          <span>Нет истории</span>
        </div>
      {/if}
      <div class="w-full">
        <span class="text-xs text-slate-400"
          >История записывается с 04.05.2024</span
        >
      </div>
    {/if}
  </div>
</Modal>
