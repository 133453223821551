<script>
  import page from "page";
  import Button from "../lib/components/crm/Button.svelte";
  import SalesList from "../lib/components/crm/SalesList.svelte";
  import ListHeader from "../lib/components/crm/ListHeader.svelte";
  import RoundButton from "../lib/components/crm/RoundButton.svelte";
  import Error from "../lib/components/crm/Error.svelte";
  import { request } from "../lib/tools/request.js";
  import { onMount } from "svelte";
  import SimpleAutocomplete from "../lib/components/crm/autocomplete/SimpleAutocomplete.svelte";
  import SaleStatus from "../lib/components/crm/SaleStatus.svelte";
  import IMask from "imask";
  import CrmInput from "../lib/components/crm/CrmInput.svelte";
  import CrmTextArea from "../lib/components/crm/CrmTextArea.svelte";

  const sourceItems = [
    { label: "Другое", key: "other" },
    { label: "Звонок", key: "call" },
    { label: "Реклама", key: "ad" },
    { label: "Офлайн магазин", key: "offline_shop" },
    { label: "Онлайн магазин", key: "online_shop" },
    { label: "Рекомендация", key: "recommendation" },
    { label: "Мероприятие", key: "event" },
  ];
  const addressChoices = [
    {
      label: "Москва",
      value: "Москва",
    },
    {
      label: "Санкт-Петербург",
      value: "Санкт-Петербург",
    },
  ];

  const sourceChoices = [
    {
      label: "Хлебзавод",
      value: "Хлебзавод",
    },
    {
      label: "Авиапарк",
      value: "Авиапарк",
    },
  ];

  export let params = {};
  let contact = {};
  let salesData;
  let error = {};
  let saved;
  let selectedSource = sourceItems[0];
  let dateInput;
  let fullName = "Новый контакт";

  $: hasData = params.id !== "add";
  $: hasError = Object.keys(error).length !== 0;

  const fetchContact = async () => {
    if (!hasData) {
      return;
    }

    const { ok, data } = await request({
      path: `contacts/${params.id}`,
    });

    if (!ok) {
      return;
    }

    contact = { ...data };

    selectedSource = sourceItems.find((s) => s.key === data.source);

    fullName = `${contact?.firstName} ${contact?.lastName}`;

    if (contact.isAutoSalesSum) {
      contact.salesSum = `${contact.autoSalesSum || 0} (Авто)`;
    }

    if (contact.isAutoSalesCount) {
      contact.salesCount = `${contact.autoSalesCount || 0} (Авто)`;
    }

    if (contact.hasBirthdayDiscount && contact.isAutoDiscount) {
      contact.discount = `${contact.autoDiscount || 0}% (Авто - День Рождения)`;
    } else if (contact.isAutoDiscount) {
      contact.discount = `${contact.autoDiscount || 0}% (Авто)`;
    } else if (`${contact.discount}`.search("%") === -1) {
      contact.discount = `${contact.discount}%`;
    }
  };

  const fetchSales = async () => {
    if (!hasData) {
      return;
    }

    const { ok, data } = await request({
      path: "sales",
      params: {
        contact: params.id,
      },
    });
    if (ok) {
      salesData = data;
    }
  };

  onMount(async () => {
    IMask(dateInput, {
      mask: Date,
    });

    await Promise.all([fetchContact(), fetchSales()]);
  });

  $: whatsAppText = `${contact?.firstName?.trim() ?? ""}, добрый день!
IGAN поздравляет Вас с Днём рождения и дарит Вам 20% на весь ассортимент!
Воспользоваться комплиментом можно в течение 7-ми дней.

Сайт: igandesigner.ru/hb
Поддержка: 89034366848

Магазин по адресу
Москва, улица Новодмитровская 1с27

Пн-Чт, Вс 11:00 — 21:00
Пт-Сб 11:00 — 22:00
Ежедневно

*подписаться на наш телеграм канал
t.me/igan_official`;

  $: whatsAppText1 = encodeURIComponent(
    `IGAN приглашает на клиентские дни 26.07–28.07 в магазин на Хлебозавод №9 и на сайт igandesigner.ru
   Комплимент от дизайнера 25% + личная скидка.

   Чтобы воспользоваться скидкой покажите данное сообщения сотруднику магазина IGAN по адресу г. Москва, ул. Новодмитровская 1 стр. 27. Или введите промокод CDAYID25 на сайте.`,
  );

  const copyWhatsAppText = async () => {
    await navigator.clipboard.writeText(whatsAppText);
  };

  const handleSubmit = async () => {
    error = {};

    const path = hasData ? `contacts/${params.id}` : "contacts";
    const salesCount = new Number(contact.salesCount) || null;
    const salesSum = new Number(contact.salesSum) || null;
    const discount = new Number(contact.discount) || null;
    const birthday = contact.birthday || null;

    const {
      ok,
      data,
      error: e,
    } = await request({
      path,
      json: {
        ...contact,
        source: selectedSource?.key || null,
        salesCount,
        salesSum,
        discount,
        birthday,
      },
    });

    if (ok) {
      if (!hasData) {
        await page(`/crm/contacts/${data.id}`);
        await fetchContact();
        return;
      }
      saved = true;
      setTimeout(() => {
        saved = false;
      }, 3000);
    } else {
      error = e;
    }
  };
  const handleDelete = async () => {
    if (!confirm("Вы уверены?")) {
      return;
    }
    const { ok } = await request({
      path: `contacts/${params.id}`,
      method: "DELETE",
    });

    if (ok) {
      page("/crm/contacts");
    }
  };

  const select = (e) => {
    e.target.select();
  };
</script>

<svelte:head>
  <title>LPID - Контакты {contact?.id || ""} {fullName}</title>
</svelte:head>

<div class="wrapper bg-white">
  <div class="header">
    <h2 class="header-text">
      {fullName}
    </h2>
  </div>
  <div class="form-wrap">
    <div class="form">
      <div class="form-row">
        <div class="label">ФИО*</div>
        <div class="inputs">
          <CrmInput
            bind:value={contact.lastName}
            placeholder="Фамилия"
            bind:error={error.lastName}
          />
          <CrmInput
            bind:value={contact.firstName}
            placeholder="Имя"
            bind:error={error.firstName}
          />
        </div>
      </div>
      <div class="form-row">
        <div class="label">Рейтинг</div>
        <div class="inputs">
          <div class="flex gap-1 items-center h-full">
            {#each Array(5).fill(null) as _, index}
              <button
                class="p-1 hover:bg-gray-100"
                on:click={() => {
                  contact.rating = index + 1;
                }}
              >
                {#if index + 1 > (contact.rating || 0)}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_1449_274"
                      style="mask-type:alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16"
                    >
                      <rect width="16" height="16" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1449_274)">
                      <path
                        d="M5.86628 11.1173L7.99961 9.85059L10.1496 11.1173L9.58294 8.71725L11.4329 7.13392L8.98294 6.91725L7.99961 4.63392L7.01628 6.91725L4.56628 7.13392L6.43294 8.71725L5.86628 11.1173ZM4.04961 13.6006L5.09961 9.16725L1.59961 6.18392L6.19961 5.78392L7.99961 1.60059L9.79961 5.80059L14.3996 6.18392L10.8996 9.16725L11.9496 13.6006L7.99961 11.2506L4.04961 13.6006Z"
                        fill="#0071E8"
                      />
                    </g>
                  </svg>
                {:else}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_1449_271"
                      style="mask-type:alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16"
                    >
                      <rect width="16" height="16" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1449_271)">
                      <path
                        d="M4.04961 13.6006L5.09961 9.16725L1.59961 6.18392L6.19961 5.78392L7.99961 1.60059L9.79961 5.80059L14.3996 6.18392L10.8996 9.16725L11.9496 13.6006L7.99961 11.2506L4.04961 13.6006Z"
                        fill="#0071E8"
                      />
                    </g>
                  </svg>
                {/if}
              </button>
            {/each}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="label">Телефон</div>
        <div class="inputs">
          <div class="w-full h-full flex flex-col gap-2.5">
            <CrmInput
              bind:value={contact.phone}
              placeholder="89990000000"
              bind:error={error.phone}
            >
              {#if hasData}
                <div>
                  <a
                    rel="noreferrer"
                    class="whatsapp-link"
                    target="_blank"
                    href="https://wa.me/{contact.cleanedPhone}?text={whatsAppText1}"
                    >Акция Клиентские дни</a
                  >
                </div>
              {/if}
            </CrmInput>
          </div>
        </div>
      </div>
      {#if hasData}
        <div class="form-group">
          <div class="form-row">
            <div class="label">Сумма покупок</div>
            <div class="inputs">
              <CrmInput
                bind:value={contact.salesSum}
                bind:error={error.salesSum}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="label">Количество покупок</div>
            <div class="inputs">
              <CrmInput
                bind:value={contact.salesCount}
                bind:error={error.salesCount}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="label">Скидка</div>
            <div class="inputs">
              <CrmInput
                bind:value={contact.discount}
                bind:error={error.discount}
              />
            </div>
          </div>
        </div>
      {/if}
      <div class="form-row">
        <div class="label">День рождения</div>
        <div class="inputs">
          <div class="h-full w-full flex flex-col gap-2.5">
            <input
              on:click={select}
              type="text"
              value={contact.birthday}
              on:change={(e) =>
                (contact = { ...contact, birthday: e.target.value })}
              bind:this={dateInput}
              class="w-full min-h-[46px] h-full border border-slate-200 rounded-xl px-2"
            />
            {#if hasData && contact.hasBirthdayDiscount}
              <div>
                <a
                  rel="noreferrer"
                  class="whatsapp-link"
                  target="_blank"
                  href="https://wa.me/{contact.cleanedPhone}?text={encodeURIComponent(
                    whatsAppText,
                  )}">Поздравить в WhatsApp</a
                >
                <span class="whatsapp-link">|</span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  class="whatsapp-link"
                  href="https://t.me/+{contact.cleanedPhone}">Telegram</a
                >
              </div>
            {/if}
          </div>
        </div>
      </div>
      {#if contact.hasBirthdayDiscount}
        <div class="form-row" on:keydown on:click={copyWhatsAppText}>
          <div class="label"></div>
          <div class="inputs">
            <span class="whatsapp-link"
              >{@html whatsAppText.replace(/\n/g, "<br>")}</span
            >
          </div>
        </div>
      {/if}
      <div class="form-row">
        <div class="label">Соцсети</div>
        <div class="inputs">
          <input
            on:click={select}
            type="text"
            bind:value={contact.socialNetworks}
            placeholder="Telegram, VK, Instagram и т.д."
            class="w-full min-h-[46px] h-full border border-slate-200 rounded-xl px-2"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="label">Адрес</div>
        <div class="inputs">
          <CrmInput
            bind:value={contact.address}
            placeholder="Например, Москва"
            bind:error={error.address}
          >
            {#each addressChoices as choice}
              <button
                class="text-xs bg-slate-100 hover:bg-slate-200 px-2 py-1 rounded-full"
                on:click={() =>
                  (contact = { ...contact, address: choice.value })}
              >
                <span>{choice.label}</span>
              </button>
            {/each}
          </CrmInput>
        </div>
      </div>
      <div class="form-row">
        <div class="label">Источник</div>
        <div class="inputs">
          <CrmInput bind:value={contact.source} bind:error={error.source}>
            <svelte:fragment slot="input">
              <SimpleAutocomplete
                items={sourceItems}
                bind:error={error.source}
                bind:selectedItem={selectedSource}
                labelFieldName="label"
                valueFieldName="key"
                hideArrow
              >
                <div slot="item" let:item let:label class={`${item.key}`}>
                  <SaleStatus key={item.key} value={label} />
                </div>
              </SimpleAutocomplete>
            </svelte:fragment>
          </CrmInput>
        </div>
      </div>
      <div class="form-row">
        <div class="label">Дополнительно об источнике</div>
        <div class="inputs">
          <div class="w-full h-full flex flex-col gap-2.5">
            <div class="h-11">
              <input
                on:click={select}
                type="text"
                bind:value={contact.sourceExtra}
                placeholder="Например, Хлебозавод"
                class="w-full h-full border border-slate-200 rounded-xl px-2"
              />
            </div>
            <div class="w-full flex gap-2">
              {#each sourceChoices as choice}
                <button
                  class="text-xs bg-slate-100 hover:bg-slate-200 px-2 py-1 rounded-full"
                  on:click={() =>
                    (contact = { ...contact, sourceExtra: choice.value })}
                >
                  <span>{choice.label}</span>
                </button>
              {/each}
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="label">Комментарий</div>
        <div class="inputs">
          <CrmTextArea bind:value={contact.comment} />
        </div>
      </div>
      {#if error}
        <div class="form-row error-row">
          <div class="label" />
          <div class="inputs">
            <Error {error} />
          </div>
        </div>
      {/if}
      <div class="form-row">
        <div class="label" />
        <div
          class="inputs checkbox"
          on:keydown
          on:click={() => {
            contact.isSubscribed = !contact.isSubscribed;
          }}
        >
          <div>
            <input
              name="isSubscribed"
              on:click={() => {}}
              class="checkbox"
              type="checkbox"
              bind:checked={contact.isSubscribed}
            />
          </div>
          <div>
            <span>Получать рассылку</span>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="label" />
        <div
          on:keydown
          class="inputs checkbox"
          on:click={() => {
            contact.needCheck = !contact.needCheck;
          }}
        >
          <div>
            <input
              name="needCheck"
              on:click={() => {}}
              class="checkbox"
              type="checkbox"
              bind:checked={contact.needCheck}
            />
          </div>
          <div>
            <span>Требует проверки</span>
          </div>
        </div>
      </div>
      {#if !hasData}
        <div class="form-row">
          <div class="label" />
          <div class="inputs">
            <Button
              accent
              bind:error={hasError}
              title="Создать"
              on:click={handleSubmit}
            />
          </div>
        </div>
      {/if}
    </div>
    <div class="form">
      <div class="form-row">
        <div class="label" />
        <div class="inputs">
          {#if hasData}
            <Button title="Удалить" on:click={handleDelete} />
            <Button
              {saved}
              bind:error={hasError}
              on:click={handleSubmit}
              accent
              title="Сохранить"
            />
          {/if}
        </div>
      </div>
    </div>
  </div>
  <div class="sales-header">
    {#if salesData}
      <ListHeader title="Сделки" count={salesData.count | 0} />
      <SalesList data={salesData} />
    {/if}
  </div>
  {#if hasData}
    <RoundButton url="/crm/sales/add?contactId={contact.id}" />
  {/if}
</div>

<style>
  .wrapper {
    padding: 20px 0;
  }

  .header {
    padding-left: 30px;
  }

  .header-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .form-wrap {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 10px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f7f8fa;
    border-radius: 20px;
    padding: 5px 0 10px 0;
  }

  .form-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px 0 20px;
    min-height: 46px;
  }

  .error-row {
    min-height: 0px;
  }

  .label {
    width: 100%;
    padding: 0 13px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #9598ac;
  }

  .button-wrap {
    width: 100%;
  }

  .inputs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
  }

  .checkbox {
    cursor: pointer;
    align-items: center;
  }

  .sales-header {
    padding-top: 20px;
  }

  .whatsapp-link {
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: #9598ac;
    margin-bottom: 5px;
    line-height: 18px;
  }

  .whatsapp-link:visited {
    color: #9598ac;
  }

  @media only screen and (min-width: 1180px) {
    .header {
      padding-left: 20px;
    }

    .form-wrap {
      max-width: 55vw;
    }

    .form-group {
      padding: 20px 0;
      margin: 0 10px;
    }

    .form-group .form-row {
      padding: 0px 10px;
    }

    .form-row {
      flex-direction: row;
      padding-top: 0;
    }

    .label {
      max-width: 145px;
      padding: 0;
    }
  }
</style>
