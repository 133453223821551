<script>
  export let value
  export let error
  export let placeholder = ""
  const select = (e) => {
    e.target.select()
  }
</script>

<div class="w-full h-full flex flex-col gap-2.5">
  <div class="min-h-[46px]">
    <slot name="input">
      <input on:click={select}
             bind:value
             type="text"
             placeholder={placeholder}
             class="w-full min-h-[46px] h-full border border-slate-200 rounded-xl px-2">
    </slot>
  </div>
  {#if error}
    <div class="text-sm text-red-400">{error}</div>
  {/if}
  <div class="w-full flex gap-2">
    <slot/>
  </div>
</div>