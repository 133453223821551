<script>
  import { sizeLabels } from "../stores/sizeLabels.js";
  import SizeLabel from "./SizeLabel.svelte";
  import StockItemRow from "./StockItemRow.svelte";
  import BarcodeSearch from "./BarcodeSearch.svelte";
  import { request } from "../tools/request.js";
  import { onMount } from "svelte";
  import { storagePopupKey } from "../stores/storagePopupKey.js";

  export let storageFrom;
  export let transfer;
  export let transferItems = [];
  export let errors;

  let loading = true;
  let storageItems = {};
  let categories = [];
  let transferItemTimers = {};
  let transferItemStatuses = {};

  const fetchCategories = async () => {
    const { ok, data } = await request({
      path: "stock-categories",
    });

    if (ok) {
      categories = data.results;
    }
  };

  const fetchStorageItems = async () => {
    if (!storageFrom) {
      storageItems = {};
      return;
    }
    let page = 1;
    let res = [];
    while (true) {
      const { ok, data } = await request({
        path: "storage-items",
        params: {
          page,
          storage: storageFrom,
        },
      });
      if (ok) {
        res = res.concat(data.results);
      }
      if (!data.next) {
        break;
      }
      page += 1;
    }

    storageItems = {};
    res.forEach((i) => (storageItems[i.item.id] = i));
  };

  const fetchTransferItems = async () => {
    transferItems = [];

    let page = 1;
    while (true) {
      const { ok, data } = await request({
        path: `storage-transfer-items`,
        params: {
          transfer: transfer.id,
          page,
        },
      });
      if (!ok) {
        break;
      }

      transferItems = transferItems.concat(data.results);
      page += 1;

      if (!data.next) {
        break;
      }
    }
  };

  const selectItem = (itemId, size) => {
    const k = `${itemId}_${size}`;
    storagePopupKey.set($storagePopupKey === k ? "" : k);
  };

  const isSelected = (itemId, size) => {
    const k = `${itemId}_${size}`;
    return $storagePopupKey === k;
  };

  const addTransferItem = async (itemId, size) => {
    await request({
      path: "storage-transfer-items/add-to-transfer",
      json: {
        transferId: transfer.id,
        itemId,
        size,
      },
    });

    await fetchTransferItems();
  };

  const updateTransferItem = async (itemId, size, value) => {
    if (transferItemTimers[itemId]) {
      clearTimeout(transferItemTimers[itemId]);
    }

    transferItemTimers[itemId] = setTimeout(async () => {
      const { ok, data } = await request({
        path: `storage-transfer-items/${itemId}`,
        json: {
          id: itemId,
          [size]: value,
        },
      });
      transferItemStatuses[`${itemId}${size}`] = ok;
      setTimeout(() => {
        transferItemStatuses[`${itemId}${size}`] = undefined;
      }, 1000);
    }, 500);
  };

  onMount(async () => {
    loading = true;
    await Promise.all([
      fetchCategories(),
      fetchTransferItems(),
      fetchStorageItems(),
    ]);
    loading = false;
  });

  const filterItems = (items, category) => {
    return items.filter((i) => i.itemDict.category === category.id);
  };

  function getError(e, itemId, size) {
    try {
      return e[itemId][size] || "";
    } catch {
      return "";
    }
  }

  const getStorageQuantity = (_, itemId, size) => {
    try {
      return storageItems[itemId][size];
    } catch {
      return 0;
    }
  };

  function isZero(_, __, itemId, size, currentValue) {
    return getStorageQuantity(_, itemId, size) + currentValue === 0;
  }
</script>

<div class="w-full flex flex-col gap-2 py-1 md:px-5 pb-10">
  {#if !loading}
    <div
      class="w-full fixed bottom-5 left-0 z-20 flex items-center gap-2 rounded-xl px-5"
    >
      <div
        class="flex bg-white gap-2 items-center h-11 w-full shadow-lg border border-slate-200 rounded-xl pl-5"
      >
        <span class="material-symbols-outlined text-black">barcode</span>
        <BarcodeSearch
          side="top"
          onSearch={(i) => addTransferItem(i.id, i.sizeByCode)}
        />
      </div>
    </div>
    <div class="w-full flex">
      <textarea
        bind:value={transfer.comment}
        class="resize-none w-full md:w-1/2 border rounded-xl border-slate-200 p-2"
        rows="2"
        placeholder="Комментарий"
      ></textarea>
    </div>
    <div
      class="justify-start whitespace-nowrap overflow-x-scroll print:whitespace-pre-wrap"
    >
      <table class="w-full text-sm border-collapse">
        <tbody>
          {#each categories as category}
            {#if filterItems(transferItems, category).length}
              <tr>
                <td
                  colspan="4"
                  class="pl-5 md:pl-0 pt-2 pb-1 text-xl font-bold text-left text-black"
                  >{category.title}</td
                >
                {#each sizeLabels as size}
                  <td
                    class:text-black={$storagePopupKey.search(`_${size}`) !==
                      -1}
                    class="font-bold px-2 uppercase text-center text-slate-400"
                  >
                    <SizeLabel {size} />
                  </td>
                {/each}
              </tr>
            {/if}

            {#each filterItems(transferItems, category) as item}
              <tr
                class:bg-blue-300={$storagePopupKey.search(
                  `${item.itemDict.id}_`,
                ) !== -1}
                on:click={() => selectItem(item.id, "")}
              >
                <td colspan="4" class="border-t border-b border-slate-200">
                  <div class="flex gap-1">
                    <StockItemRow item={item.itemDict} />
                  </div>
                </td>
                {#each sizeLabels as size}
                  <td class="text-center border-t border-b border-slate-200">
                    <div class="relative flex justify-center">
                      <div
                        class="{isSelected(
                          item.itemDict.id,
                          size,
                          $storagePopupKey,
                        )
                          ? 'block'
                          : 'hidden'} pointer-events-none mb-2 absolute -top-0 px-2 text-xs border border-white rounded-full left-1/2 -translate-y-1/2 -translate-x-1/2 bg-gray-200 text-slate-400 font-light"
                      >
                        <span class="uppercase"
                          ><SizeLabel {size} />:
                        </span>{getStorageQuantity(
                          storageItems,
                          item.itemDict.id,
                          size,
                        )}
                      </div>
                      <div
                        role="presentation"
                        class:border-blue-400={isSelected(
                          item.itemDict.id,
                          size,
                          $storagePopupKey,
                        )}
                        class:!border-red-400={getError(
                          errors,
                          item.itemDict.id,
                          size,
                        )}
                        class:opacity-10={!isSelected(
                          item.itemDict.id,
                          size,
                          $storagePopupKey,
                        ) &&
                          isZero(
                            transferItems,
                            storageItems,
                            item.itemDict.id,
                            size,
                            item[size],
                          )}
                        on:keydown
                        on:click|stopPropagation={() =>
                          selectItem(item.itemDict.id, size)}
                        class="font-bold px-2 py-1 rounded-xl border-2 border-slate-100"
                        class:bg-slate-100={transferItemStatuses[
                          `${item.id}${size}`
                        ] === undefined}
                        class:bg-green-100={transferItemStatuses[
                          `${item.id}${size}`
                        ] === true}
                        class:bg-red-100={transferItemStatuses[
                          `${item.id}${size}`
                        ] === false}
                      >
                        <input
                          type="text"
                          title={getError(errors, item.itemDict.id, size)}
                          class="text-center cursor-pointer w-[30px] bg-transparent selection:bg-blue-400 selection:text-white"
                          bind:value={item[size]}
                          on:input={(e) =>
                            updateTransferItem(
                              item.id,
                              size,
                              e.currentTarget.value,
                            )}
                        />
                      </div>
                    </div>
                  </td>
                {/each}
              </tr>
            {/each}
          {/each}
        </tbody>
      </table>
    </div>
  {:else}
    <span>Нет наименований</span>
  {/if}
</div>
