<script>
    export let to = '';
    export let header = false;
    export let columns = 'minmax(0, 1fr)'
</script>

<a style={`grid-auto-columns: ${columns}`} class="card" data-item={header ? '' : true} class:header href="{to}">
    <slot></slot>
</a>


<style>
    .card {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        padding: 10px 20px;
        background-color: #F7F8FA;
        border-radius: 12px;
        min-height: 50px;
    }

    .header {
        display: none;
        background-color: white;
    }

    @media only screen and (min-width: 1180px) {
        .card {
            display: grid;
            grid-auto-flow: column;
        }
    }
</style>
