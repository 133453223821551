<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import TransferStatus from "../lib/components/TransferStatus.svelte";
  import DatesFilter from "../lib/components/filters/DatesFilter.svelte";
  import StorageFilter from "../lib/components/filters/StorageFilter.svelte";
  import { getTitle } from "../lib/tools/transfers.js";
  import StockItemFilter from "../lib/components/filters/StockItemFilter.svelte";
  import { getSearchParam } from "../lib/tools/searchParams.js";
  import TransferTypeFilter from "../lib/components/filters/TransferTypeFilter.svelte";

  let timer = 0;
  let loading = false;
  let transfers = [];
  let next = {};
  let storages = [];
  let searchString = null;

  const fetchStorageTransfers = async (nextPage) => {
    if (!nextPage && searchString === location.search) {
      return;
    }

    if (!nextPage) {
      next = {};
    }

    searchString = location.search;
    loading = true;
    const { ok, data } = await request({
      path: "storage-transfers",
      params: {
        storage: getSearchParam("storage"),
        item: getSearchParam("item"),
        type: getSearchParam("type"),
        created_at_after: getSearchParam("created_at_after"),
        created_at_before: getSearchParam("created_at_before"),
        ...next,
      },
    });

    if (!ok) {
      loading = false;
      transfers = [];
      return;
    }

    if (nextPage) {
      transfers = transfers.concat(data.results);
    } else {
      transfers = data.results;
    }

    next = {};
    if (data?.next) {
      next = Object.fromEntries(
        new URLSearchParams(new URL(data.next).search).entries(),
      );
    }
    loading = false;
  };

  function getStorage(storageId) {
    return storages.find((s) => s.id === storageId)?.title || "";
  }

  onMount(async () => {
    timer = setInterval(fetchStorageTransfers, 1000);
  });

  onDestroy(() => clearInterval(timer));
</script>

<div class="w-full bg-white">
  <div class="w-full flex justify-between items-center p-5">
    <span class="text-xl font-bold">Перемещения</span>
    <div class="flex gap-2 items-center">
      <div class="text-blue-500 text-sm">
        <a href="/transfers/import">Импорт</a>
      </div>
      <a href="/transfers/add">
        <button
          class="py-2 px-4 bg-blue-400 hover:bg-blue-600 text-white rounded-xl"
          >Создать</button
        >
      </a>
    </div>
  </div>
  <div class="w-full flex flex-col items-center px-5 pb-2">
    <StorageFilter bind:storages />
    <TransferTypeFilter />
    <DatesFilter />
    <StockItemFilter />
  </div>
  <div class="overflow-hidden overflow-x-scroll">
    <div class="md:w-full inline-flex flex-col">
      {#if loading}
        <div class="w-full flex justify-center text-2xl">
          <span class="material-symbols-outlined animate-spin"
            >progress_activity</span
          >
        </div>
      {:else}
        {#each transfers as transfer}
          <a
            href="/transfers/{transfer.id}"
            class="whitespace-nowrap pointer w-full flex justify-between px-5 py-2 border-t last:border-b"
          >
            <div class="flex gap-2">
              <span>{getTitle(transfer)}</span>
              <span class="font-bold"
                >{getStorage(transfer.storageFrom)}
                {#if transfer.storageFrom && transfer.storageTo}→{/if}
                {getStorage(transfer.storageTo)}</span
              >
              <span>#{transfer.id} от </span>
              <span>{new Date(transfer.createdAt).toLocaleDateString()}</span>
              <span>({transfer.quantity} штук)</span>
              <TransferStatus
                value={transfer.status}
                label={transfer.statusLabel}
              />
              <div class="h-full flex items-center text-sm text-slate-400">
                {transfer.comment}
              </div>
            </div>
            <div class="text-sm text-gray-400 pl-5">
              Создал {transfer.user.username}
            </div>
          </a>
        {/each}
      {/if}
      {#if next?.page}
        <div class="w-full px-5 py-2 pb-5">
          <div>
            <button
              on:click={async () => await fetchStorageTransfers(true)}
              disabled={loading}
              class="disabled:bg-gray-400 disabled:text-white py-2 px-4 bg-blue-400 hover:bg-blue-600 text-white rounded-xl"
            >
              Загрузить ещё
            </button>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
