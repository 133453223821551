<script>
  export let active = false;
  export let disabled = false;
</script>

<div
  class="relative w-full h-full flex justify-center items-center"
  on:keydown
  on:click={() => {
    if (disabled) {
      return;
    }
    active = !active;
  }}
>
  <div
    class="absolute bg-slate-200 w-8 h-2.5 rounded-xl transition-all duration-300 {active && !disabled
      ? '!bg-blue-400 opacity-20'
      : ''}"
  />
  <div
    class="-translate-x-2 absolute w-5 h-5 bg-white rounded-2xl border-1 border-slate-200 transition-all duration-300 {active &&
    !disabled
      ? '!bg-blue-400'
      : ''} {active ? '!translate-x-2' : ''}"
  />
</div>
