export const makeThumbnail = ({ url, filters }) => {
  if (!url) {
    return "";
  }

  const key = url.replace(import.meta.env.VITE_PUBLIC_IMG_BUCKET_PREFIX, "");
  const path = `${filters}${key}`;

  return `https://img.igandesigner.ru/unsafe/${path}`;
};
