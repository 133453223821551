<script>
  import { onMount } from "svelte";
  import {
    getSearchParam,
    setSearchParam,
  } from "../../../lib/tools/searchParams.js";

  let types = [
    {
      title: "Перемещение",
      key: "transfer",
      selected: false,
    },
    {
      title: "Зачисление",
      key: "income",
      selected: false,
    },
    {
      title: "Списание",
      key: "expense",
      selected: false,
    },
  ];

  const selectType = (index) => {
    types[index].selected = !types[index].selected;
    types = [...types];
    console.log(types.filter((t) => t.selected).map((t) => t.key));
    setSearchParam([
      ["type", types.filter((t) => t.selected).map((t) => t.key)],
    ]);
  };

  onMount(() => {
    const selectedTypes = getSearchParam("type");
    types = types.map((t) => {
      t.selected = !!selectedTypes.find((s) => s === t.key);
      return t;
    });
  });
</script>

<div class="w-full flex items-center gap-1 py-2 flex-wrap">
  <span class="text-sm pr-2 font-bold">Тип</span>
  {#each types as type, index}
    <button
      on:click={() => selectType(index)}
      class:bg-blue-400={type.selected}
      class:text-white={type.selected}
      class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
    >
      {type.title}
    </button>
  {/each}
</div>
