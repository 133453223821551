<script>
  import {request} from "../lib/tools/request.js";
  import {onMount} from "svelte";

  let reports = []
  const dateFormat = new Intl.DateTimeFormat("ru-ru", {month: "long", year: "numeric"})


  const fetchReports = async () => {
    const res = []
    const {ok, data} = await request({
      path: 'sale-reports',
    })
    if (ok) {
      data.results.forEach(report => {
        res.push({
          ...report,
          createdAt: new Date(report.createdAt),
          updatedAt: new Date(report.updatedAt)
        })
      })
      reports = res
    }

  }

  onMount(fetchReports)
</script>

<div class="w-full flex flex-col gap-4 px-7 text-xs sm:text-base">
  <span class="text-xl font-bold">Отчёты</span>
  <div class="py-5 flex flex-col gap-2">
    {#each reports as report}
      <a href="/crm/sale-reports/{report.id}" class="rounded-xl bg-white px-4 py-3 flex gal-2 justify-between">
        <span>Отчёт #{report.id} за {dateFormat.format(report.createdAt)}</span><span
        class="text-slate-400"><span class="hidden sm:inline">Обновлено: </span>{report.updatedAt.toLocaleString()}</span>
      </a>
    {/each}
  </div>
</div>