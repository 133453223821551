<script>
  import {
    formatSize,
    sizeLabels,
    sizesMap,
  } from "../lib/stores/sizeLabels.js";
  import AutoComplete from "simple-svelte-autocomplete";
  import { request, uploadFile } from "../lib/tools/request.js";
  import { onMount } from "svelte";
  import IMask from "imask";
  import DateChoices from "../lib/components/crm/DateChoices.svelte";
  import page from "page";
  import CrmTextArea from "../lib/components/crm/CrmTextArea.svelte";
  import SaleStatus from "../lib/components/crm/SaleStatus.svelte";
  import { getSearchParam } from "../lib/tools/searchParams.js";
  import Discount from "../lib/components/crm/Discount.svelte";
  import { user } from "../lib/stores/user.js";
  import BarcodeSearch from "../lib/components/BarcodeSearch.svelte";

  const today = new Date().toLocaleDateString("ru");

  const statusChoices = [
    { label: "Новый", key: "created" },
    { label: "Готов к отправке", key: "ready" },
    { label: "Отправлен", key: "sent" },
    { label: "Успешный", key: "success" },
    { label: "Ремонт принят", key: "repair_accepted" },
    { label: "Ремонт на производстве", key: "repair_in_progress" },
    { label: "Ремонт завершён", key: "repair_done" },
    { label: "Отмена", key: "fail" },
  ];
  const sourceChoices = [
    { label: "Другое", key: "other" },
    { label: "Ремонт", key: "repair" },
    { label: "Звонок", key: "call" },
    { label: "Реклама", key: "ad" },
    { label: "Офлайн магазин", key: "offline_shop" },
    { label: "Онлайн магазин", key: "online_shop" },
    { label: "Рекомендация", key: "recommendation" },
    { label: "Мероприятие", key: "event" },
    { label: "Социальные сети", key: "social_network" },
  ];

  const salesmanCutValues = [0, 3, 4, 5, 6, 10];

  export let params;

  $: saleId = params.id === "add" ? null : params.id;
  let errors = {};
  let transferErrors = [];
  let userIsHleb = $user.username === "hleb";
  let sale = {
    id: null,
    shop: null,
    storage: null,
    quantity: 0,
    price: 0,
    useAutoValues: false,
    discountValue: 0,
    startDate: today,
    endDate: today,
    needCheck: true,
    extraExpenses: 0,
    cardPrice: 0,
    cashPrice: 0,
    discount: 0,
    isLocked: false,
    source: sourceChoices[0].key,
    status: statusChoices[0].key,
    sum: 0,
    comment: "",
    salesmen: [],
    saleItems: [],
    order: {
      id: 0,
      firstName: "",
      lastName: "",
      phone: "",
      extraPhone: "",
      transactionUrl: "",
      deliveryTypeLabel: "",
      address: "",
    },
  };

  let savedOk = false;
  let loading = false;
  let transferring = false;
  let sentOk = false;
  let error = {};
  let storages = [];
  let shops = [];
  let salesmen = [];
  let selectedSalesman = null;
  let title = "";
  const defaultSaleItem = {
    item: null,
    size: sizesMap[sizeLabels[0]],
    price: null,
    discountPrice: null,
    discount: null,
  };

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = [...data.results];
    }
  };

  const fetchShops = async () => {
    const { ok, data } = await request({
      path: "shops",
    });

    if (ok) {
      shops = [...data.results];
    }
  };

  const fetchSalesmen = async () => {
    const { ok, data } = await request({
      path: "salesmen",
    });

    if (ok) {
      salesmen = [...data.results];
    }
  };

  const fillInitialSaleData = (tryNumber) => {
    if (!(storages.length && shops.length) && tryNumber < 5) {
      return setTimeout(() => fillInitialSaleData(tryNumber + 1), 500);
    }

    title = "Новая сделка";
    if (!userIsHleb) {
      return;
    }

    sale = {
      ...sale,
      storage: storages.find((s) => s.title === "Хлебозавод").id,
      shop: shops.find((s) => s.title === "Хлебозавод").id,
      createStorageTransfer: true,
      status: "success",
      source: "offline_shop",
    };
  };

  const fetchSale = async () => {
    if (!saleId) {
      fillInitialSaleData(1);
      return;
    }
    const { ok, data } = await request({
      path: `sales/${saleId}`,
    });

    if (ok) {
      sale = { ...data };
      title = `#${sale.id} от ${sale.startDate} ${sale.shop?.title ?? ""}`;
    }
  };

  const formatSearchResult = (contact) => {
    if (!contact.id) {
      return "";
    }
    return `${contact.lastName} ${contact.firstName} (${contact.phone}) ${
      contact.discount || contact.autoDiscount || 0
    }%`;
  };

  const searchContact = async (search) => {
    const { ok, data } = await request({
      path: "contacts",
      params: {
        search,
      },
    });
    if (ok) {
      return data.results;
    }
  };

  const addSaleItem = (selectedItem) => {
    if (!selectedItem) {
      return;
    }
    const size = selectedItem.sizeByCode
      ? selectedItem.sizeByCode
      : defaultSaleItem.size;
    const item = { ...defaultSaleItem, item: selectedItem, size };
    calcItemDiscount(item);
    sale.saleItems = sale.saleItems.concat({ ...item });
    calculateTotal();
  };

  const calcItemDiscount = (item) => {
    item.discountPrice = Math.round(item.price * ((100 - item.discount) / 100));
    calculateTotal();
  };

  const calculateTotal = () => {
    let quantity = 0;
    let sum = 0;
    let discountValue = 0;
    sale.saleItems = sale.saleItems.map((item) => {
      const discount =
        item.discount ??
        sale.contact?.discount ??
        sale.contact?.autoDiscount ??
        0;
      item.price = item.item.price;
      item.discount = discount;
      item.discountPrice = Math.round(item.price * ((100 - discount) / 100));
      quantity += 1;
      sum += item.discountPrice;
      discountValue += item.price - item.discountPrice;
      return item;
    });
    sale = {
      ...sale,
      quantity,
      sum,
      discount: discountValue,
    };
  };

  const removeSaleItem = (index) => {
    sale.saleItems.splice(index, 1);
    sale.saleItems = [...sale.saleItems];
  };

  onMount(async () => {
    document.querySelectorAll("[data-date]").forEach((e) => {
      IMask(e, {
        mask: Date,
      });
    });
    loading = true;
    await Promise.all([
      fetchStorages(),
      fetchShops(),
      fetchSalesmen(),
      fetchSale(),
    ]);

    const contactId = getSearchParam("contactId")[0] ?? null;
    if (!saleId && contactId) {
      const { ok, data } = await request({
        path: `contacts/${contactId}`,
      });
      if (ok) {
        sale.contact = data;
      }
    }
    loading = false;
  });

  const selectSalesman = () => {
    const index = sale.salesmen.findIndex((s) => s.id === selectedSalesman?.id);
    if (index === -1) {
      sale.salesmen.push(selectedSalesman);
    } else {
      sale.salesmen.splice(index, 1);
    }
    sale = { ...sale };
    selectedSalesman = null;
  };

  const save = async () => {
    if (loading) {
      return new Promise((resolve) => {
        resolve({ ok: false });
      });
    }

    transferErrors = [];
    errors = {};

    loading = true;
    const path = sale.id ? `sales/${sale.id}` : "sales";
    const { ok, data, error } = await request({
      path,
      json: {
        ...sale,
        contact: sale.contact?.id || null,
        endDate: sale.endDate || null,
        salesmen: sale.salesmen.map((s) => s.id),
        saleItems: sale.saleItems.map((s) => {
          return { ...s, stock_item: s.item.id };
        }),
      },
    });

    if (ok && !sale.id) {
      window.location.pathname = `/crm/sales/${data.id}`;
      return;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        errors = error || {};
        loading = false;
        if (ok) {
          savedOk = true;
          setTimeout(() => {
            savedOk = false;
          }, 800);
        }
        resolve({ ok });
      }, 1000);
    });
  };

  const deleteSale = async () => {
    if (!saleId) {
      return;
    }

    if (!confirm("Вы уверены?")) {
      return;
    }

    const { ok } = await request({
      path: `sales/${sale.id}`,
      method: "DELETE",
    });

    if (ok) {
      page("/crm/sales");
    }
  };

  const createSaleReturn = () => {
    if (!sale.id) {
      return;
    }
    page(`/crm/sale-returns/add?saleId=${sale.id}`);
  };

  const transferSale = (action) => {
    return async () => {
      transferErrors = [];
      if (!saleId) {
        return;
      }

      transferring = true;
      const { ok: saveOk } = await save();
      if (!saveOk) {
        return;
      }

      const { ok, error } = await request({
        path: `sales/${saleId}/${action}/`,
        method: "POST",
      });

      if (ok) {
        sentOk = true;
        await fetchSale();
        setTimeout(() => {
          sentOk = false;
        }, 2000);
      } else {
        transferErrors = error?.["errors"] ?? [];
      }
      transferring = false;
    };
  };

  const uploadMedia = async (event) => {
    loading = true;

    const media = await Promise.all(
      Object.keys(event.target.files)
        .sort()
        .map((k) => uploadFile(event.target.files[k])),
    );

    sale = {
      ...sale,
      media: [...sale.media, ...media],
    };

    await saveFiles();
    loading = false;
  };

  const saveFiles = async () => {
    if (!sale.id) {
      return;
    }

    await request({
      path: `sales/${sale.id}`,
      json: {
        id: sale.id,
        media: sale.media,
      },
    });
  };
  const deleteFile = async (media) => {
    sale = {
      ...sale,
      media: sale.media.filter((m) => m.url !== media.url),
    };
    await saveFiles();
  };

  const moveFile = async (index, direction) => {
    if (index === 0 && direction < 0) {
      return;
    }

    if (index === sale.media.length - 1 && direction > 0) {
      return;
    }
    const temp = sale.media[index];
    sale.media[index] = sale.media[index + direction];
    sale.media[index + direction] = temp;
    sale = {
      ...sale,
    };
    await saveFiles();
  };
</script>

<div class="w-full bg-white py-5 pb-10 md:px-10 px-3">
  <div class="flex flex-col gap-3 w-full lg:w-2/3">
    <div class="w-full flex md:items-center">
      <span class="text-xl font-bold">{title}</span>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Контакт*</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        {#if sale.contact}
          <div class="flex items-center gap-2">
            <a href="/crm/contacts/{sale.contact.id}" class="text-blue-400"
              >{sale.contact.firstName} {sale.contact.lastName}</a
            >
            <span
              >({sale.contact.phone}) <Discount contact={sale.contact} /></span
            >
            <button
              class="h-full flex items-center"
              on:click={() => {
                sale = { ...sale, contact: null };
              }}><span class="material-symbols-outlined">close</span></button
            >
          </div>
        {:else}
          <AutoComplete
            searchFunction={searchContact}
            delay="500"
            placeholder="Начните ввод"
            localFiltering={false}
            labelFunction={formatSearchResult}
            minCharactersToSearch="2"
            bind:selectedItem={sale.contact}
            hideArrow
            error={error?.contact}
            inputClassName="border !h-11 rounded-xl !py-2 border-slate-200"
          />
        {/if}
        <div class="text-red-400">
          {errors.contact || ""}
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Дата начала*</span>
      </div>
      <div class="w-full flex flex-col gap-1">
        <input
          type="text"
          data-date
          on:input={(e) => (sale.startDate = e.target.value)}
          value={sale.startDate}
          class="w-full h-11 border border-slate-200 rounded-xl px-2"
        />
        <div class="flex gap-2">
          <DateChoices bind:value={sale.startDate} />
        </div>
        <div class="text-red-400">
          {errors.startDate || ""}
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Дата завершения</span>
      </div>
      <div class="w-full flex flex-col gap-1">
        <input
          type="text"
          data-date
          on:input={(e) => (sale.endDate = e.target.value)}
          value={sale.endDate}
          class="w-full h-11 border border-slate-200 rounded-xl px-2"
        />
        <div class="flex gap-2">
          <DateChoices bind:value={sale.endDate} />
        </div>
        <div class="text-red-400">
          {errors.endDate || ""}
        </div>
      </div>
    </div>
    <hr />
    <div>
      <span class="text-xl font-bold"
        >Товары
        {#if sale.isLocked}<span class="font-normal text-slate-400 text-xs"
            >Чтобы редактировать товары, сделайте возврат на склад</span
          >{/if}</span
      >
    </div>
    {#each sale.saleItems as item, index}
      <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
        <div
          class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
        >
          <div class="flex flex-col gap-1">
            <span>Позиция {index + 1}</span>
            {#if item.returnedAt}
              <span class="text-xs"
                >Возврат {new Date(item.returnedAt).toLocaleDateString()}</span
              >
            {/if}
          </div>
        </div>
        <div class="w-full flex flex-col gap-2">
          <div class="w-full flex gap-2">
            <div class="basis-2/3">
              <input
                class:text-red-500={item.saleReturnId}
                class:line-through={item.saleReturnId}
                class="w-full h-11 border border-slate-200 rounded-xl px-2"
                type="text"
                disabled
                value={item.item.title}
              />
            </div>
            <div class="basis-1/3 flex gap-2 md:items-center">
              <div class="basis-5/6">
                <select
                  autocomplete="off"
                  class:text-red-500={item.saleReturnId}
                  class:line-through={item.saleReturnId}
                  disabled={sale.isLocked || item.saleReturnId}
                  bind:value={item.size}
                  class="w-full bg-white h-11 border border-slate-200 rounded-xl px-2 disabled:bg-slate-100"
                >
                  {#each sizeLabels as size}
                    <option value={sizesMap[size]}>{formatSize(size)}</option>
                  {/each}
                </select>
              </div>
              <div
                class="flex justify-center items-center w-full h-full basis-1/6"
              >
                <button
                  disabled={sale.isLocked || item.saleReturnId}
                  on:click={() => {
                    removeSaleItem(index);
                  }}
                  class="flex items-center justify-center w-full h-full"
                  ><span class="text-slate-400 material-symbols-outlined"
                    >cancel</span
                  ></button
                >
              </div>
            </div>
          </div>
          <div class="flex gap-2 w-full">
            <div class="w-full flex flex-col gap-1">
              <span class="text-xs sm:text-sm text-slate-400">Цена</span>
              <input
                disabled={sale.isLocked || item.saleReturnId}
                placeholder="Цена"
                bind:value={item.price}
                on:change={() => calcItemDiscount(item)}
                type="text"
                class="w-full h-11 border border-slate-200 rounded-xl px-2"
              />
            </div>
            <div class="w-full flex flex-col gap-1 whitespace-nowrap">
              <span class="text-xs sm:text-sm text-slate-400"
                >Цена со скидкой</span
              >
              <input
                placeholder="Цена со скидкой"
                disabled
                bind:value={item.discountPrice}
                type="text"
                class="w-full h-11 border border-slate-200 rounded-xl px-2"
              />
            </div>
            <div class="w-full flex flex-col gap-1">
              <span class="text-xs sm:text-sm text-slate-400">Скидка</span>
              <input
                disabled={sale.isLocked || item.saleReturnId}
                placeholder="Скидка"
                on:change={() => calcItemDiscount(item)}
                bind:value={item.discount}
                type="text"
                class="w-full h-11 border border-slate-200 rounded-xl px-2"
              />
            </div>
          </div>
          {#if errors.saleItems?.filter((e) => Number(e.itemId) === item.item.id && e.size === item.size).length}
            <div class="text-red-400">
              На складе нет достаточного количества
            </div>
          {/if}
        </div>
      </div>
      <hr class="border-dotted" />
    {/each}
    {#if !sale.isLocked}
      <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
        <div
          class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
        ></div>
        <div class="w-full h-11 border border-slate-200 rounded-xl">
          <BarcodeSearch
            onSearch={(selectedItem) => addSaleItem(selectedItem)}
          />
        </div>
      </div>
    {/if}

    {#if !sale.id}
      <div
        class="flex flex-col md:flex-row gap-2 w-full md:items-center py-2.5"
      >
        <div
          class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
        />
        <label
          class="bg-yellow-500 w-full flex flex-col gap-2 bg-opacity-10 rounded-lg p-2.5 cursor-pointer"
        >
          <div class="font-bold flex items-center gap-3">
            <input bind:checked={sale.createStorageTransfer} type="checkbox" />
            <span>Списать со склада</span>
          </div>
          <div class="text-sm">
            После создания сделки товары будут списаны со склада. Чтобы вернуть
            товары на склад, необходимо добавить их вручную
          </div>
        </label>
      </div>
    {/if}

    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Магазин</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <select
          autocomplete="off"
          bind:value={sale.shop}
          class="w-full bg-white h-11 border border-slate-200 rounded-xl px-2"
        >
          <option value={null}>Выберите магазин</option>
          {#each shops as shop}
            <option value={shop.id}>{shop.title}</option>
          {/each}
        </select>
        <div class="text-red-400">
          {errors.shop || ""}
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Склад</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <select
          autocomplete="off"
          bind:value={sale.storage}
          class="w-full bg-white h-11 border border-slate-200 rounded-xl px-2"
        >
          <option value={null}>Выберите склад</option>
          {#each storages as storage}
            <option value={storage.id}>{storage.title}</option>
          {/each}
        </select>
        <div class="text-red-400">
          {errors.storage || ""}
        </div>
      </div>
    </div>
    <!--    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">-->
    <!--      <div class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm">-->
    <!--        <span>Заполнять автоматически</span>-->
    <!--      </div>-->
    <!--      <div class="w-full h-11 flex items-center">-->
    <!--        <label class="w-full">-->
    <!--          <input type="checkbox" bind:checked={sale.useAutoValues}>-->
    <!--        </label>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Количество*</span>
      </div>
      <div class="w-full h-11">
        <input
          disabled={sale.useAutoValues}
          bind:value={sale.quantity}
          type="text"
          class="w-full h-11 border border-slate-200 rounded-xl px-2 disabled:bg-slate-100"
        />
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Доп. расходы</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <input
          disabled={sale.useAutoValues}
          bind:value={sale.extraExpenses}
          type="text"
          class="w-full h-11 border border-slate-200 rounded-xl px-2 disabled:bg-slate-100"
        />
        <div class="text-red-400">
          {errors.extraExpenses || ""}
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Сумма*</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <input
          disabled={sale.useAutoValues}
          bind:value={sale.sum}
          type="text"
          class="w-full h-11 border border-slate-200 rounded-xl px-2 disabled:bg-slate-100"
        />
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Сумма скидки*</span>
      </div>
      <div class="w-full h-11">
        <input
          disabled={sale.useAutoValues}
          bind:value={sale.discount}
          type="text"
          class="w-full h-11 border border-slate-200 rounded-xl px-2 disabled:bg-slate-100"
        />
      </div>
    </div>
    <div>
      <span class="text-xl font-bold">Оплата</span>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Наличные</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <input
          bind:value={sale.cashPrice}
          type="text"
          class="w-full h-11 border border-slate-200 rounded-xl px-2"
        />
        <div class="text-red-400">
          {errors.cashPrice || ""}
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Безналичные</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <input
          bind:value={sale.cardPrice}
          type="text"
          class="w-full h-11 border border-slate-200 rounded-xl px-2"
        />
        <div class="text-red-400">
          {errors.cardPrice || ""}
        </div>
      </div>
    </div>
    <hr />
    <div>
      <span class="text-xl font-bold">Дополнительная информация</span>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Продавец</span>
      </div>
      <div class="w-full flex flex-col gap-2 justify-end">
        <div class="flex gap-1">
          {#each sale.salesmen as salesman}
            <button
              class="px-2 py-1 border rounded-xl flex justify-center items-center"
              on:keydown
              on:click={() => {
                selectedSalesman = salesman;
                selectSalesman();
              }}
            >
              <span>{salesman.firstName} {salesman.lastName}</span>
              <span class="material-symbols-outlined">close</span>
            </button>
          {/each}
          {#if !sale.salesmen?.length}
            <div
              class="px-2 py-1 border rounded-xl flex justify-center items-center"
            >
              Нет продавцов
            </div>
          {/if}
        </div>
        <select
          autocomplete="off"
          bind:value={selectedSalesman}
          on:change={selectSalesman}
          class="w-full bg-white h-11 border border-slate-200 rounded-xl px-2"
        >
          <option value={null}>Выберите продавца</option>
          {#each salesmen.filter((s) => !sale.salesmen.includes(s)) as salesman}
            <option value={salesman}
              >{salesman.firstName} {salesman.lastName}</option
            >
          {/each}
        </select>
      </div>
    </div>
    {#if sale.id}
      <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
        <div
          class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
        >
          <span>% с продажи</span>
        </div>
        <div class="w-full flex flex-col gap-2">
          <div class="w-full flex gap-2">
            {#each salesmanCutValues as value}
              <button
                on:click={() => (sale = { ...sale, salesmanCut: value })}
                class="py-2 px-3 border border-gray-200 rounded-lg"
                class:bg-blue-400={sale.salesmanCut === value}
                class:blue-400={sale.salesmanCut === value}
                class:text-white={sale.salesmanCut === value}>{value}</button
              >
            {/each}
            <input
              on:input={(e) =>
                (sale = { ...sale, salesmanCut: e.target.value })}
              value={salesmanCutValues.includes(sale.salesmanCut)
                ? null
                : sale.salesmanCut}
              class="py-2 border border-gray-200 rounded-lg w-20 text-center"
              placeholder="Другое"
              type="number"
            />
          </div>
          <div class="text-red-400">
            {errors.salesmanCut || ""}
          </div>
        </div>
      </div>
    {/if}
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Источник</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <div class="w-full">
          <select
            autocomplete="off"
            bind:value={sale.source}
            class="w-full bg-white h-11 border border-slate-200 rounded-xl px-2"
          >
            <option value="null">Выберите источник</option>
            {#each sourceChoices as source}
              <option value={source.key}>{source.label}</option>
            {/each}
          </select>
        </div>
        <div class="text-red-400">
          {errors.source || ""}
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Дополнительно об источнике</span>
      </div>
      <div class="w-full h-11">
        <div class="w-full h-11">
          <input
            bind:value={sale.sourceExtra}
            type="text"
            class="w-full h-11 border border-slate-200 rounded-xl px-2"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Комментарий</span>
      </div>
      <div class="w-full">
        <div class="w-full">
          <CrmTextArea bind:value={sale.comment} />
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span>Статус</span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <div class="w-full">
          <select
            autocomplete="off"
            class:text-green-500={sale.status === "success"}
            class:text-red-500={sale.status === "fail"}
            class:text-purple-500={sale.status === "ready"}
            class:text-blue-500={sale.status === "sent"}
            class:text-yellow-500={sale.status === "created"}
            class:text-indigo-500={sale.status.startsWith("repair")}
            bind:value={sale.status}
            class="w-full bg-white h-11 border border-slate-200 rounded-xl px-2"
          >
            {#each statusChoices as status}
              <option value={status.key}>
                <SaleStatus key={status.key} value={status.label} />
              </option>
            {/each}
          </select>
        </div>
        <div class="text-red-400">
          {errors.source || ""}
        </div>
      </div>
    </div>

    {#if sale.id}
      <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
        <div
          class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
        >
          <span>Медиа</span>
        </div>
        <div class="w-full flex flex-col gap-2">
          <div class="flex gap-2 w-full overflow-x-scroll whitespace-nowrap">
            {#if !sale.media?.length}
              <div
                class="group relative w-44 h-44 bg-slate-200/25 shrink-0 flex justify-center items-center"
              >
                <span class="text-black/50">Нет медиа</span>
              </div>
            {/if}
            {#each sale.media ?? [] as media, index}
              <div
                class="group relative w-44 h-44 b-slate-200/25 shrink-0 text-lg text-white flex justify-center items-center"
              >
                <button
                  on:click={async () => await deleteFile(media)}
                  class="hidden group-hover:flex absolute top-0 right-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <i class="material-symbols-outlined">delete</i>
                </button>
                <button
                  on:click={async () => await moveFile(index, -1)}
                  class="hidden group-hover:flex absolute bottom-0 left-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <span class="material-symbols-outlined"
                    >arrow_back_ios_new</span
                  >
                </button>
                <button
                  on:click={async () => await moveFile(index, 1)}
                  class="hidden group-hover:flex absolute bottom-0 right-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <span class="material-symbols-outlined"
                    >arrow_forward_ios</span
                  >
                </button>
                {#if media.contentType?.startsWith("image")}
                  <img
                    src={media.url}
                    class="w-full h-full object-cover object-center"
                    alt=""
                  />
                {:else}
                  <div
                    class="text-black w-full h-full flex flex-col gap-1 justify-center items-center border border-gray-200"
                  >
                    <span class="material-symbols-outlined">description</span>
                    <span>{media.name}</span>
                  </div>
                {/if}
              </div>
            {/each}
          </div>
          <div>
            <label class="flex h-full items-center cursor-pointer" for="file"
              >Добавить файл</label
            >
            <input
              accept="image/*"
              multiple
              on:change={(e) => uploadMedia(e)}
              type="file"
              id="file"
              class="opacity-0 w-[0.1px] h-[0.1px] absolute"
            />
          </div>
          <div class="text-red-400">
            {errors.media || ""}
          </div>
        </div>
      </div>
    {/if}
    {#if sale.order?.id}
      <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
        <div
          class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
        >
          <span>Детали заказа</span>
        </div>
        <div class="w-full flex flex-col gap-2 text-slate-400 text-sm">
          <div>
            <span>Транзакция </span>
            <a
              target="_blank"
              class="underline"
              href={sale.order.transactionUrl}
            >
              {sale.order.transactionId}
            </a>
          </div>
          <div>
            {sale.order.firstName}
            {sale.order.lastName}
            {sale.order.phone}
            {sale.order.extraPhone}
            {sale.order.deliveryTypeLabel}
            {sale.order.address}
          </div>
        </div>
      </div>
    {/if}
    <div class="flex flex-col md:flex-row gap-2 w-full md:items-center">
      <div
        class="flex basis-0 md:basis-52 shrink-0 h-full md:items-center text-slate-400 text-sm"
      >
        <span></span>
      </div>
      <div class="w-full flex flex-col gap-2">
        <div class="w-full">
          <button
            on:click={save}
            disabled={loading}
            class:bg-green-500={!loading && savedOk}
            class="w-full flex items-center justify-center h-11 border rounded-xl bg-blue-400 text-white cursor-pointer transition-colors"
          >
            {#if loading || transferring}
              <span class="material-symbols-outlined animate-spin"
                >progress_activity</span
              >
            {:else if saleId}Сохранить{:else}Создать{/if}
          </button>
        </div>
        {#if saleId}
          {#if sale.isLocked}
            <div class="w-full">
              <button
                on:click={transferSale("cancel")}
                disabled={loading || transferring}
                class:bg-green-500={!loading && sentOk}
                class:text-white={!loading && sentOk}
                class="w-full flex items-center justify-center h-11 border rounded-xl text-black cursor-pointer transition-colors"
              >
                {#if transferring}
                  <span class="material-symbols-outlined animate-spin"
                    >progress_activity</span
                  >
                {:else}
                  Вернуть на склад
                {/if}
              </button>
            </div>
          {:else}
            <div class="w-full hidden">
              <button
                on:click={transferSale("send")}
                disabled={loading || transferring}
                class:bg-green-500={!loading && sentOk}
                class:text-white={!loading && sentOk}
                class="w-full flex items-center justify-center h-11 border rounded-xl text-black cursor-pointer transition-colors"
              >
                {#if transferring}
                  <span class="material-symbols-outlined animate-spin"
                    >progress_activity</span
                  >
                {:else}
                  Списать со склада
                {/if}
              </button>
            </div>
            <div class="w-full">
              <button
                on:click={deleteSale}
                disabled={loading || transferring}
                class="w-full flex items-center justify-center h-11 border rounded-xl text-red-500 cursor-pointer transition-colors"
              >
                Удалить
              </button>
            </div>
            {#if sale.status === "success"}
              <div class="w-full">
                <button
                  on:click={createSaleReturn}
                  disabled={loading}
                  class="w-full flex items-center justify-center h-11 border rounded-xl cursor-pointer transition-colors"
                  >Оформить возврат</button
                >
              </div>
            {/if}
          {/if}
          <div class="flex flex-col gap-2">
            {#each transferErrors ?? [] as error}
              <div class="text-xs text-red-500">{error}</div>
            {/each}
          </div>
        {/if}
        <div class="w-full flex flex-col gap-1">
          {#each errors?.nonFieldErrors || [] as error}
            <div class="text-xs text-red-500">{error}</div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
