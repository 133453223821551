<script>
  import { refreshCSRF, getBaseUrl } from "../lib/tools/request";
  let loading = false;
  let file;
  let result = {};
  let error;
  let createSales = true;
  let createTransfers = true;

  const importFile = async () => {
    if (!file[0]) {
      return;
    }

    const fd = new FormData();
    fd.append("file", file[0]);
    fd.append("createSales", createSales);
    fd.append("createTransfers", createTransfers);

    const { data: csrfToken } = await refreshCSRF();
    const url = new URL("storage-transfers-import/", getBaseUrl());
    const response = await fetch(url, {
      method: "POST",
      body: fd,
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken.csrf,
      },
    });

    if (!response.ok) {
      error = await response.json();
    } else {
      result = await response.json();
    }
  };
</script>

<div class="w-full bg-white">
  <div class="w-full flex justify-between items-center p-5">
    <span class="text-xl font-bold">Импорт перемещения</span>
  </div>
  <div class="overflow-hidden overflow-x-scroll">
    <div class="px-5 pb-10 flex flex-col">
      {#if result.transfers || result.sales}
        {#if result.transfers}
          <div>Из файла были созданы перемещения:</div>
          {#each result.transfers as transfer}
            <a
              class="text-blue-500"
              href={`/transfers/${transfer.id}`}
              target="_blank"
            >
              #{transfer.id}
              {transfer.comment}
            </a>
          {/each}
        {/if}
        {#if result.sales}
          <div>Из файла были созданы сделки:</div>
          {#each result.sales as sale}
            <a
              class="text-blue-500"
              href={`/crm/sales/${sale.id}`}
              target="_blank"
            >
              Сделка #{sale.id}
            </a>
          {/each}
        {/if}
        <div class="pt-8">
          <button
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5 disabled:bg-gray-600 whitespace-nowrap"
            on:click={() => {
              result = {};
              error = null;
            }}
          >
            Новый импорт
          </button>
        </div>
      {:else}
        <div class="flex flex-col gap-5">
          <div>
            <select
              autocomplete="off"
              disabled
              class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
            >
              <option value={null}>Авиапарк</option>
            </select>
          </div>
          <div>
            <input name="file" type="file" bind:files={file} />
          </div>
          <div class="flex flex-col gap-1 text-sm">
            <div>
              <label>
                <input type="checkbox" bind:checked={createSales} />
                <span>Создать сделки</span>
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" bind:checked={createTransfers} />
                <span>Создать перемещения</span>
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-1 text-sm">
            <div>
              <button
                disabled={loading || !file || !(createTransfers || createSales)}
                class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5 disabled:bg-gray-600 whitespace-nowrap"
                on:click={importFile}
              >
                Начать импорт
              </button>
            </div>
          </div>
          <div class="text-red-500 flex flex-col gap-1">
            {#if error?.file}
              <div>{error.file}</div>
            {/if}
            {#if error?.rows}
              {#each error.rows as rowError}
                <div>Строка {rowError.index}: {rowError.msg}</div>
              {/each}
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
