<script>
  import noimage from "../../assets/noimage.png";

  export let item = {};

  let imgLoaded = false;
</script>

<div class="flex gap-2 w-full">
  <div class="relative basis-10 shrink-0 aspect-square">
    {#if !imgLoaded}
      <img
          class="absolute top-0 left-0 object-fit"
          src={noimage}
          alt={item.title}
      />
    {/if}
    {#if item.picture}
      <img
          alt={item.title}
          class="absolute top-0 left-0 object-contain"
          src={item.picture}
          on:load={() => {
          imgLoaded = true;
        }}
      />
    {/if}
  </div>
  <div class="flex flex-col justify-center w-full">
    <div>
      <slot name="category">
        <span class="text-slate-400">
          {item.category.title}
        </span>
      </slot>
    </div>
    <div>
      <slot name="vendorCode"><span>{item.vendorCode}</span></slot>
      <slot name="title"><span class="font-bold break-all">{item.title}</span></slot>
    </div>
  </div>
</div>
