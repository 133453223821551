<script>
  import page from "page"
  import { user } from "../lib/stores/user"
  import { request } from "../lib/tools/request"
  import loginImg from "../assets/login.png"
  import { onMount } from "svelte"

  const redirect_url = new URLSearchParams(location.search).get("next") || "/search"

  $: if ($user) {
    page.redirect(redirect_url)
  }

  let form
  let ok, error

  async function login(e) {
    e.preventDefault()
    if (!form.reportValidity()) {
      return
    }

    ({ ok, error } = await request({
      path: "login",
      json: form
    }))
    if (ok) {
      location.pathname = decodeURIComponent(redirect_url)
    }
  }

  onMount(async () => {
    const { ok, data } = await request({ path: "csrf" })
    if (ok) {
      localStorage.setItem("csrf", data.csrf)
    }
  })
</script>

{#if !$user?.id}
  <form bind:this={form}>
    <div class="w-screen h-screen bg-white flex justify-center items-center">
      <div class="flex flex-col gap-2 items-center w-full max-w-[440px] px-5">
        <div class="h-24">
          <img class="h-24" src={loginImg} alt="login" />
        </div>
        <h2 class="text-lg font-semibold">Авторизация</h2>
        <div class="text-red-400">{error?.detail ?? error?.nonFieldErrors ?? ''}</div>
        <div class="h-12 w-full">
          <input
            class="h-full w-full rounded-xl border-1 border-slate-400 px-2.5"
            type="text"
            name="username"
            required
            placeholder="Логин"
          />
          <div class="text-red-400">{error?.username ?? ''}</div>
        </div>
        <div class="h-12 w-full">
          <div class="text-red-400">{error?.password ?? ''}</div>
          <input
            class="h-full w-full rounded-xl border-1 border-slate-400 px-2.5"
            type="password"
            name="password"
            placeholder="Пароль"
          />
        </div>
        <div class="h-12 w-full">
          <button class="w-full h-full bg-blue-400 hover:bg-blue-500 text-white rounded-xl"
                  on:click={login}>
            Войти
          </button>
        </div>
        <div class="flex w-full justify-center text-sm text-slate-400">
          <span>Igan Designer</span>
        </div>
      </div>
    </div>
  </form>
{/if}