<script>
  import {createEventDispatcher} from "svelte";

  const dispatch = createEventDispatcher();

  let y;
  let height;
  let scrollHeight;

  $: if (document.body.scrollHeight * 0.9 <= y + height) {
    dispatch('pageEnd')
  }

</script>

<svelte:window bind:scrollY={y} bind:outerHeight={height}/>
