<script lang="ts">
  import { onMount } from "svelte";
  import { request } from "../lib/tools/request";

  let events = [];
  let allEventsLink = "";

  const extractDate = (dateTimeString) => {
    try {
      return dateTimeString.split(" ").slice(0, 3).join(" ");
    } catch {
      return dateTimeString;
    }
  };

  const fetchCalendar = async () => {
    const { ok, data } = await request({
      path: "external/yandex-calendar-export",
    });
    if (ok) {
      events = data.results.events;
      allEventsLink = data.results.links.all;
    }
  };
  onMount(fetchCalendar);
</script>

<div class="w-full flex justify-center pt-5">
  <div class="w-full md:max-w-[600px] px-2.5 md:px-0 flex flex-col gap-5">
    <div class="flex flex-col gap-1">
      <div class="flex w-full justify-between">
        <h1 class="font-bold text-xl">Календарь</h1>
        {#if allEventsLink}
          <a
            class="hover:bg-gray-100 text-sm flex items-center justify-center bg-white text-black border-1 border-gray-300 rounded-xl px-2"
            href={allEventsLink}
            target="_blank">Все события</a
          >
        {/if}
      </div>
      <h3 class="text-gray-400 text-xs">
        Показаны текущие события. Прошедшие события сохранены в календаре.
      </h3>
    </div>
    {#each events as event}
      <div
        class="bg-white rounded-xl border border-gray-200 p-2 flex flex-col gap-1.5"
      >
        <span class="font-semibold text-lg">{event.title}</span>
        <div
          class="w-fit bg-red-300/50 text-red-400 border-l-2 rounded-sm px-2 py-1 border-red-400 flex flex-col text-base"
        >
          <span>{extractDate(event.start)}</span>
        </div>
        <div class="text-slate-400 text-xs">
          <span>{event.location}</span>
        </div>
        <div class="text-xs">
          <span>{event.description}</span>
        </div>
      </div>
    {/each}
  </div>
</div>
