<script>
  import {onMount} from "svelte";
  import Button from "../lib/components/Button.svelte";
  import {request} from "../lib/tools/request";

  let cards = {
    results: [],
  };

  onMount(async () => {
    await getCards();
  });

  async function getCards(nextParams) {
    const {ok, data} = await request({
      path: "cards",
      params: {...nextParams},
    });
    if (ok) {
      if (nextParams) {
        data.results = [...cards.results, ...data.results];
      }
      cards = {...data};
    }
  }

  async function getNextCards() {
    getCards(Object.fromEntries(new URL(cards.next).searchParams.entries()));
  }
</script>

<div class="flex flex-col h-full w-full min-w-[1200px] justify-between gap-5">
  <div class="basis-full">
    <div class="h-full w-full bg-white py-5">
      <div class="flex flex-col gap-2.5">
        <div class="flex w-full px-5">
          <div class="flex h-9 w-full justify-between">
            <div class="text-xl font-bold">Лимитно-заборные карты</div>
          </div>
          <div>
            <a href="/warehouse/cards/add">
              <Button accent>Создать</Button>
            </a>
          </div>
        </div>
        <div>
          {#each cards.results as card}
            <a
              class="flex cursor-pointer px-5 justify-between border-t-1 border-slate-200 py-2.5 text-sm last:border-b-1"
              href="/warehouse/cards/{card.id}"
            >
              <div class="font-semibold">
                <span
                >Карта #{card.id}
                  {card.data.title} от {new Date(
                    card.createdAt
                  ).toLocaleDateString()}</span
                >
                {#if card.isFinalized}
                  <span class="text-green-400">Завершена</span>
                {/if}
                {#if card.isInProgress}
                  <span class="text-yellow-500">Проверка</span>
                {/if}
              </div>
              <div class="basis-1/4 text-slate-400">
                Создал {card.user.username}
              </div>
            </a>
          {/each}
        </div>
      </div>
    </div>
  </div>
  {#if cards.next}
    <div class="w-32 h-10 mt-5">
      <Button accent on:click={getNextCards}>Загрузить ещё</Button>
    </div>
  {/if}
</div>
