<script>
  import page from "page";

  import { user } from "../stores/user";

  import { userHasAccess } from "../tools/userHasAccess";
  import { request } from "../tools/request.js";
  import { onMount } from "svelte";

  let extraUrls = [];
  let showExtra = false;

  const urls = [
    {
      url: "/search",
      icon: "barcode",
      style: "text-black",
    },
    {
      url: "/calendar",
      icon: "calendar_month",
    },
    {
      url: "/storage",
      groups: ["Склад просмотр"],
      title: "Склад",
    },
    {
      url: "/transfers",
      groups: ["Склад просмотр"],
      title: "Перемещения",
    },
    {
      url: "/revisions",
      groups: ["Склад просмотр"],
      title: "Инвентаризация",
    },
    {
      url: "/transfer-history",
      groups: ["Склад просмотр"],
      title: "История",
    },
    {
      url: "/stock",
      groups: ["Ассортимент просмотр"],
      title: "Ассортимент",
    },
    {
      url: "/crm/contacts",
      groups: ["Контакты просмотр"],
      title: "Контакты",
    },
    {
      url: "/crm/sales",
      groups: ["Сделки просмотр"],
      title: "Сделки",
    },
    {
      url: "/warehouse/items",
      groups: ["Материалы просмотр"],
      title: "Материалы",
    },
    {
      url: "/warehouse/cards",
      groups: ["ЛЗ-карты просмотр"],
      title: "ЛЗ&#x2060;-&#x2060;карты",
    },
  ];

  $: visibleExtraUrls = extraUrls.filter((u) =>
    u.users.includes($user?.username),
  );

  async function logout() {
    await request({
      path: "logout",
      method: "post",
    });
    user.set(null);
    page("/login");
  }

  onMount(async () => {
    const { ok, data } = await request({
      path: "app-settings/EXTRA_PANEL_URLS",
    });
    if (ok) {
      extraUrls = data.value;
    }
  });
</script>

{#if $user}
  <div
    class="flex items-center h-11 w-screen px-5 py-1 overflow-x-scroll print:hidden whitespace-nowrap"
  >
    {#each urls as url}
      {#if userHasAccess($user, user.groups ?? [])}
        <a
          href={url.url}
          class="flex h-full text-sm items-center justify-center rounded-md px-2 py-1 font-semibold leading-4 text-slate-400 hover:bg-white/50 cursor-pointer"
        >
          {#if url.icon}
            <span class={`material-symbols-outlined ${url.style}`}
              >{url.icon}</span
            >
          {:else}
            {@html url.title}
          {/if}
        </a>
      {/if}
    {/each}
    {#if visibleExtraUrls.length}
      <div on:click={() => (showExtra = !showExtra)} role="presentation">
        <span
          class="flex h-full text-sm items-center justify-center rounded-md px-2 py-1 font-semibold leading-4 text-slate-400 hover:bg-white/50 cursor-pointer"
          >Экстра</span
        >
      </div>
    {/if}
    {#if showExtra}
      {#each visibleExtraUrls as extraUrl}
        <div class="h-full flex items-center">
          <a
            href={extraUrl.url}
            rel="noreferrer"
            target="_blank"
            class="flex justify-center items-center"
          >
            <span
              class="flex h-full text-sm items-center justify-center rounded-md px-2 py-1 font-semibold leading-4 text-slate-400 hover:bg-white/50 cursor-pointer"
            >
              {extraUrl.label}
            </span>
          </a>
        </div>
      {/each}
    {/if}
    <div class="ml-auto h-full flex gap-2 text-xs text-slate-400">
      <div class="h-full flex items-center">
        {import.meta.env.VITE_VERSION || "?"}
      </div>
      <div
        class="flex h-full text-sm items-center justify-center rounded-md px-2 py-1 font-semibold leading-4 text-slate-400 hover:bg-white/50 cursor-pointer"
      >
        <a
          href="https://unleashed-maxilla-d17.notion.site/7d894fc8e6d7434896df48f3cbf5fbda"
          target="_blank"
          rel="noreferrer"
          class=""
        >
          <span class="material-symbols-outlined text-base">info</span>
        </a>
      </div>
      <button
        on:click={logout}
        class="flex h-full text-sm items-center justify-center rounded-md px-2 py-1 font-semibold leading-4 text-slate-400 hover:bg-white/50 hover:text-red-400 cursor-pointer"
      >
        <span>{$user.username}</span>
        <span class="material-symbols-outlined text-base">logout</span>
      </button>
    </div>
    <!-- <div class="ml-auto h-full flex gap-2">
      <div class="h-full flex items-center text-xs text-slate-400">
        {import.meta.env.VITE_VERSION || "?"}
      </div>
      <div class="h-full flex items-center">
        <a
          href="https://unleashed-maxilla-d17.notion.site/7d894fc8e6d7434896df48f3cbf5fbda"
          target="_blank"
          rel="noreferrer"
          class=""
        >
          <span class="material-symbols-outlined text-base">info</span>
        </a>
      </div>
      <div
        class="h-full flex items-center"
        on:keydown
        on:click|stopPropagation={logout}
        role="presentation"
      >
        <div class="text-sm">
          {$user.username}<span class="material-symbols-outlined text-base"
            >logout</span
          >
        </div>
      </div>
    </div> -->
  </div>
{/if}
