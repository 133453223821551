<script>
  import List from "./List.svelte";
  import Card from "./Card.svelte";
  import Field from "./Field.svelte";
  import Date from "./Date.svelte";
  import SaleStatus from "./SaleStatus.svelte";
  import Discount from "./Discount.svelte";
  import FlexBreak from "./FlexBreak.svelte";
  import SizeLabel from "../SizeLabel.svelte";
  import {reformatSize} from "../../stores/sizeLabels.js";

  export let data = {};

  let next;
  if (data.next) {
    next = new URL(data.next).search;
  }

  const columns = `minmax(0, 60px) minmax(0, 95px) minmax(0, 1fr)
                     minmax(0, 1fr) minmax(0, 60px) minmax(0, 90px)
                     95px minmax(0, 1fr) minmax(0, 120px)`;

  function goToContact(contactId) {
    return () => {
      location.pathname = `/crm/contacts/${contactId}`;
    };
  }
</script>


<div class="wrapper bg-white">
  <input type="hidden" id="next" bind:value={next}>
  <List>
    <Card columns={columns} header>
      <Field header>
        ID
      </Field>
      <Field header>
        Дата
      </Field>
      <Field header>
        Покупатель
      </Field>
      <Field header>
        Позиции
      </Field>
      <Field header>
        Кол-во
      </Field>
      <Field header>
        Сумма
      </Field>
      <Field header>
        Стадия
      </Field>
      <Field header>
        Комментарий
      </Field>
      <Field header>
        Источник
      </Field>
    </Card>
    {#each data.results || [] as sale}
      <Card columns={columns} to={`/crm/sales/${sale.id}`}>
        <Field light tablet>
          {`${sale.id}`.padStart(6, '0')}
        </Field>
        <Field>
          <div class="dates">
            <div>
              <Date value={sale.startDate}/>
            </div>
            {#if sale.endDate}
              <div class="dates-separator">
                —
              </div>
            {/if}
            <div>
              <Date value={sale.endDate}/>
            </div>
          </div>
        </Field>
        <FlexBreak/>
        <Field>
          <div class="contact-info">
            <div class="contact" on:keydown on:click|preventDefault|stopPropagation={goToContact(sale.contact.id)}>
              <span>{sale.contact.lastName} {sale.contact.firstName}</span>
              <span class="discount"><Discount contact={sale.contact}/></span>
            </div>
            <div class="phone">
              {sale.contact.phone}
            </div>
          </div>
        </Field>
        <FlexBreak/>
        <Field bold>
          <div class="flex flex-col gap-1">
            {#each sale.saleItems as item}
              <span>{item.item.title}
                <SizeLabel size={reformatSize(item.size)}/></span>
            {/each}
            {#if !sale.saleItems.length}
              <span>Нет наименований</span>
            {/if}
          </div>
        </Field>
        <FlexBreak/>
        <Field>
          {sale.quantity}
        </Field>
        <Field bold>
          <div class="sum">{sale.sum}₽</div>
        </Field>
        <Field>
          <SaleStatus value="{sale.statusLabel}" key="{sale.status}"/>
        </Field>
        <FlexBreak/>
        <Field light>
          {sale.comment}
        </Field>
        <FlexBreak/>
        <Field light>
          <div class="source">
            <div>{sale.sourceLabel}</div>
            <div>{sale.sourceExtra}</div>
          </div>
        </Field>
      </Card>
    {/each}
  </List>
</div>


<style>
  .wrapper {
    padding: 20px 10px;
  }

  .contact {
    color: #0071E8;
    position: relative;
  }

  .dates {
    display: flex;
  }

  .contact-info {
    display: flex;
    gap: 5px;
  }

  .sum {
    padding: 0 15px;
  }

  .source {
    display: flex;
    gap: 10px;
  }

  @media only screen and (min-width: 1180px) {
    .sum {
      padding: 0;
    }

    .phone,
    .contact {
      width: 100%;
    }

    .dates {
      flex-direction: column;
    }

    .contact-info {
      flex-direction: column;
    }

    .dates-separator {
      display: none;
    }

    .source {
      flex-direction: column;
      gap: 0;
    }
  }
</style>