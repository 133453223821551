<script>
  export let value;
  export let choices;

</script>

<div class="wrapper">
  {#each choices as choice}
    <div class="choice" on:keydown on:click={() => value = choice.value}>
      <span>{choice.label}</span>
    </div>
  {/each}
</div>

<style>
  .wrapper {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .choice {
    cursor: pointer;
    display: flex;
    background-color: #F5F5F5;
    padding: 4px 10px;
    align-items: flex-start;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .choice:hover {
    background-color: #d5d5d5;
  }
</style>