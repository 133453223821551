<script>
  import Search from "./Search.svelte";
  import Button from "./Button.svelte";
  import {request} from "../tools/request";
  import {onMount} from "svelte";
  import {sizeLabels} from "../stores/sizeLabels.js";
  import {slide} from 'svelte/transition'
  import IMask from "imask";

  export let itemId = null

  let search
  let searchTimer
  let today = false
  let history = []
  let fromDate = null
  let toDate = null
  let next = null

  $: fetchHistory(null, fromDate, toDate, today, search)

  const fetchHistory = async (page) => {
    clearTimeout(searchTimer)
    searchTimer = setTimeout(async () => {
      const {ok, data} = await request({
        path: 'stock-items-history',
        params: {
          search,
          stock_item: itemId,
          today: today ? '1' : null,
          created_at__lte: toDate,
          created_at__gte: fromDate,
          page,
        }
      })

      if (ok) {
        data.results.forEach(h => {
          h.createdAt = new Date(h.createdAt)
          Object.keys(h.diff).forEach(k => {
            if (sizeLabels.includes(k)) {
              h.diff[k].key = `${h.storage.title} ${k.replace('size', '').toUpperCase()}`
            } else {
              h.diff[k].key = k
            }
          })
        })
        history = [...data.results]
      }
      if (data.next) {
        next = new URLSearchParams(new URL(data.next).search).get('page')
      } else {
        next = null
      }
    }, 200)
  }

  onMount(async () => {
    document.querySelectorAll('[data-date]').forEach((e) => {
      IMask(e, {
        mask: Date,
      });
    });
    await fetchHistory()
  })

</script>

<div class="flex flex-col gap-5">
  <div><span class="font-bold text-xl">История изменений</span></div>
  <div class="flex gap-2 h-9 items-center">
    <div class="h-full">
      <Button on:click={() => {today = !today; fetchHistory()}} accent={today}>Сегодня</Button>
    </div>
    <div class="h-full">
      <input data-date on:input={(e) => {fromDate = e.currentTarget.value}}
           class="overflow-hidden flex justify-center items-center px-2.5 text-sm w-24 h-full rounded-xl border-1 border-slate-200">
    </div>
    <div class="h-full flex items-center">—</div>
    <div class="h-full">
      <input data-input on:input={(e) => {toDate = e.currentTarget.value}}
           class="overflow-hidden flex justify-center items-center px-2.5 text-sm w-24 h-full rounded-xl border-1 border-slate-200">
    </div>
  </div>
  <div class="w-full">
    <Search on:input={fetchHistory} bind:search/>
  </div>
  <div class="text-xs flex flex-col gap-3">
    {#each history as item}
      <div class="flex flex-col gap-2" transition:slide|global>
        <div>
          <div class="font-gray-100 text-slate-400">
            <span>({item.user.username})</span>&nbsp;<span>{item.createdAt.toLocaleString()}</span>
          </div>
          <a href="/stock/{item.stockItem.id}" rel="noreferrer" target="_blank" class="block font-bold">
            <span>{item.stockItem.title}</span>
          </a>
          <div>
            {#each Object.keys(item.diff || {}) as key}
              <div>
                {item.diff[key].key}: <span
                class="line-through">{item.diff[key].old}</span><span>&nbsp;→&nbsp;</span><span>{item.diff[key].new}</span>
              </div>
            {/each}
          </div>
        </div>
      </div>
    {/each}
    {#if next}
      <div class="flex flex-col gap-2">
        <button on:click={() => fetchHistory(next)}
                class="py-2 px-4 bg-blue-400 text-white hover:bg-blue-600 rounded-xl">
          Загрузить ещё
        </button>
      </div>
    {/if}
  </div>
</div>