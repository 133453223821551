export const sizeLabels = [
  "sizeOne",
  "size2Xs",
  "sizeXs",
  "sizeS",
  "sizeM",
  "sizeL",
  "sizeXl",
  "sizeXxlVar5",
  "sizeXxlVar7",
  "sizeXxlVar9",
  "size3XlVar5",
  "size3XlVar7",
  "size3XlVar9",
  "size4XlVar5",
  "size4XlVar7",
  "size4XlVar9",
  "size5XlVar5",
  "size5XlVar7",
  "size5XlVar9",
];

export const sizesMap = {
  sizeOne: "size_one",
  size2Xs: "size_2_xs",
  sizeXs: "size_xs",
  sizeS: "size_s",
  sizeM: "size_m",
  sizeL: "size_l",
  sizeXl: "size_xl",
  sizeXxlVar5: "size_xxl_var_5",
  sizeXxlVar7: "size_xxl_var_7",
  sizeXxlVar9: "size_xxl_var_9",
  size3XlVar5: "size_3_xl_var_5",
  size3XlVar7: "size_3_xl_var_7",
  size3XlVar9: "size_3_xl_var_9",
  size4XlVar5: "size_4_xl_var_5",
  size4XlVar7: "size_4_xl_var_7",
  size4XlVar9: "size_4_xl_var_9",
  size5XlVar5: "size_5_xl_var_5",
  size5XlVar7: "size_5_xl_var_7",
  size5XlVar9: "size_5_xl_var_9",
};

export const reverseSizesMap = Object.fromEntries(
  Object.entries(sizesMap).map(([k, v]) => [v, k]),
);

export function formatSize(size) {
  const split = size.split("Var");
  const s = split[0].replace("size", "").toUpperCase();
  if (split[1]) {
    return `${s}/${split[1]}`;
  } else {
    return s;
  }
}

export function reformatSize(size) {
  // jesus...
  const s = Object.entries(sizesMap).find(([k, v]) => v === size);
  return formatSize(s[0]);
}
