<script>
  export let contact;
  export let showAuto = false;
</script>

{#if contact}
<span class="discount">{contact.discount || contact.autoDiscount || 0}%
  {#if contact.isAutoDiscount && showAuto}(Авто){/if}</span>
{:else}
  <span></span>
{/if}

<style>

  .discount {
    background: #9598AC;
    border-radius: 10px;
    padding: 2px 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 33px;
    max-width: 45px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    width: 24px;
    height: 18px;
    line-height: 14px;
  }
</style>