<script>
  import { request } from "../tools/request";

  export let onSearch;
  export let side = "bottom";

  let search = "";
  let status = true;
  let timer = null;
  let items = [];

  const clear = () => {
    items = [];
    search = "";
    if (timer) {
      clearTimeout(timer);
    }
  };

  const searchItems = (event) => {
    if (timer) {
      clearTimeout(timer);
    }

    status = true;
    timer = setTimeout(async () => {
      items = [];
      const { ok, data } = await request({
        path: "stock-items",
        params: {
          search: event.target.value,
        },
      });

      setTimeout(() => (status = true), 1500);

      if (!ok) {
        status = false;
        return;
      }

      const match = data.results[0];
      if (match?.sizeByCode) {
        onSearch(match);
        clear();
        return;
      }
      items = data.results;
      console.log(items.length);
      if (!items.length) {
        status = false;
      }
    }, 300);
  };
</script>

<div class="relative w-full flex items-center h-full">
  <input
    class:text-red-400={status === false}
    class="w-full h-full px-2 bg-transparent"
    placeholder="Введите название товара или штрихкод"
    type="text"
    bind:value={search}
    on:input={searchItems}
  />
  <button
    on:click={clear}
    class="w-12 hover:bg-slate-200 rounded-r-lg flex justify-center items-center h-full"
  >
    <span class="material-symbols-outlined">close</span>
  </button>
  {#if items.length}
    <div
      class:top-full={side === "bottom"}
      class:bottom-full={side === "top"}
      class="absolute mt-2 left-0 w-full text-sm bg-white z-10 flex flex-col max-h-32 overflow-y-scroll shadow-lg"
    >
      {#each items as item}
        <div
          on:click={() => {
            onSearch(item);
            clear();
          }}
          role="presentation"
          class="w-full flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-slate-100"
        >
          <div class="w-10 h-10">
            <img src={item.picture} alt="" />
          </div>
          <div>{item.title}</div>
          <div class="text-slate-400">({item.vendorCode})</div>
        </div>
      {/each}
    </div>
  {/if}
</div>
