<script>
  import { onDestroy, onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import StorageItemHistoryRow from "./StorageItemHistoryRow.svelte";
  import StorageFilter from "../lib/components/filters/StorageFilter.svelte";
  import DatesFilter from "../lib/components/filters/DatesFilter.svelte";
  import StockItemFilter from "../lib/components/filters/StockItemFilter.svelte";
  import { getSearchParam } from "../lib/tools/searchParams.js";

  let loading = true;

  let history = [];
  let next = {};
  let timer = 0;
  let searchString = null;

  const dateFormat = new Intl.DateTimeFormat("ru-ru", {
    month: "long",
    day: "numeric",
  });

  const fetchHistory = async (nextPage) => {
    if (!nextPage && searchString === location.search) {
      return;
    }
    if (!nextPage) {
      next = {};
    }
    searchString = location.search;
    loading = true;
    const { ok, data } = await request({
      path: "storage-transfers-history",
      params: {
        storage: getSearchParam("storage"),
        item: getSearchParam("item"),
        created_at_after: getSearchParam("created_at_after"),
        created_at_before: getSearchParam("created_at_before"),
        ...next,
      },
    });

    if (!ok) {
      loading = false;
      history = [];
      return;
    }

    data.results.map((h) => {
      h.createdAt = new Date(h.createdAt);
    });

    if (next.page) {
      history = [...history, ...data.results];
    } else {
      history = data.results;
    }

    if (data.next) {
      next = Object.fromEntries(new URL(data.next).searchParams.entries());
    } else {
      next = {};
    }

    loading = false;
  };

  const compareDates = (a, b) => {
    return (
      a?.createdAt.toLocaleDateString() === b?.createdAt.toLocaleDateString()
    );
  };

  onMount(() => setInterval(fetchHistory, 1000));

  onDestroy(() => clearInterval(timer));
</script>

<div class="w-full bg-white">
  <div class="w-full flex gap-5 items-center p-5">
    <span class="text-xl font-bold">История изменений на складе</span>
    {#if loading}<span class="material-symbols-outlined animate-spin"
        >progress_activity</span
      >{/if}
  </div>
  <div class="px-5">
    <StorageFilter />
    <DatesFilter />
    <StockItemFilter />
  </div>
  {#if history.length}
    <div class="w-full flex flex-col gap-2 px-5 py-5">
      {#each history as historyItem, index}
        {#if index === 0 || !compareDates(historyItem, history[index - 1])}
          <div class="text-xl font-bold pt-2">
            {dateFormat.format(historyItem.createdAt)}
          </div>
        {/if}
        <StorageItemHistoryRow history={historyItem}>
          <span slot="storage" class="font-medium">
            {historyItem.storage.title}
          </span>
          <a href="/stock/{historyItem.item.id}" target="_blank" slot="item">
            <span class="font-thin">|</span>
            <span class="font-bold">{historyItem.item.title}</span>
            <span class="font-thin">|</span>
          </a>
        </StorageItemHistoryRow>
        <div class="w-full h-px bg-gray-300"></div>
      {/each}
    </div>
  {:else}
    <div class="w-full flex justify-center min-h-screen">
      <div class="text-2xl">Не найдено</div>
    </div>
  {/if}
  {#if loading}
    <div class="w-full flex justify-center items-start text-2xl min-h-screen">
      <span class="material-symbols-outlined animate-spin"
        >progress_activity</span
      >
    </div>
  {:else if next?.page}
    <div class="w-full gap-2 px-5 py-5">
      <button
        on:click={async () => {
          await fetchHistory(true);
        }}
        class="p-2 bg-blue-500 text-white rounded-xl"
      >
        Загрузить ещё
      </button>
    </div>
  {/if}
</div>
