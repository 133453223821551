import page from "page";

export function setSearchParam(params) {
  const searchParams = new URLSearchParams(location.search);

  for (const [key, value] of params) {
    searchParams.delete(key);
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v));
      } else {
        searchParams.set(key, value);
      }
    }
  }

  let path = location.pathname;
  if ([...searchParams.values()].length) {
    path += "?" + searchParams;
  }
  page(path);
}

export function getSearchParam(key) {
  return new URLSearchParams(location.search).getAll(key) || [];
}
