<script>
    import FormFieldChoices from "./FormFieldChoices.svelte";

    export let value;

    function getDate(daysDelta) {
        const date = new Date(Date.now() + 24 * 60 * 60 * 1000 * daysDelta);
        return date.toLocaleString('ru').split(",")[0];
    }

    const dates = [
        {
            label: 'Сегодня',
            value: getDate(0),
        }, {
            label: 'Вчера',
            value: getDate(-1),
        }, {
            label: 'Позавчера',
            value: getDate(-2),
        },
    ];
</script>

<FormFieldChoices bind:value choices={dates}/>
