<script>
  import ListHeader from "../lib/components/crm/ListHeader.svelte";
  import ButtonSmall from "../lib/components/crm/ButtonSmall.svelte";
  import List from "../lib/components/crm/List.svelte";
  import Card from "../lib/components/crm/Card.svelte";
  import Field from "../lib/components/crm/Field.svelte";
  import Discount from "../lib/components/crm/Discount.svelte";
  import Date from "../lib/components/crm/Date.svelte";
  import RoundButton from "../lib/components/crm/RoundButton.svelte";
  import SelectSorting from "../lib/components/crm/SelectSorting.svelte";
  import FlexBreak from "../lib/components/crm/FlexBreak.svelte";
  import { onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import Search from "../lib/components/Search.svelte";
  import ScrollWatch from "../lib/components/ScrollWatch.svelte";
  import { getSearchParam, setSearchParam } from "../lib/tools/searchParams.js";

  let contacts = { results: [] };
  let block = false;
  let search = getSearchParam("search");
  let sort = getSearchParam("sort");
  let rating = null;

  const fetchContacts = async () => {
    setSearchParam([
      ["search", search],
      ["sort", sort],
      ["rating", rating],
    ]);

    if (block) {
      return;
    }
    block = true;
    const { ok, data } = await request({
      path: "contacts",
      params: {
        sort,
        search,
        rating,
      },
    });

    if (ok) {
      contacts = { ...data };
    }
    block = false;
  };

  const paginate = async () => {
    if (block) {
      return;
    }

    if (!contacts.next) {
      return;
    }

    block = true;

    const params = new URL(contacts.next).searchParams;
    const { ok, data } = await request({
      path: "contacts",
      params: Object.fromEntries(params.entries()),
    });

    if (ok) {
      contacts = {
        ...data,
        results: [...contacts.results, ...data.results],
      };
    }

    block = false;
  };

  const sortOptions = [
    { value: "", label: "По умолчанию" },
    { value: "birthday", label: "День Рождения 🎁" },
    { value: "today", label: "Сегодня 🎁" },
    { value: "name_asc", label: "ФИО А—Я" },
    { value: "name_desc", label: "ФИО Я—А" },
    { value: "sum_asc", label: "Сумма ↑" },
    { value: "sum_desc", label: "Сумма ↓" },
    { value: "discount_asc", label: "Скидка ↑" },
    { value: "discount_desc", label: "Скидка ↓" },
    { value: "id_desc", label: "Сначала новые" },
    { value: "id_asc", label: "Сначала старые" },
  ];

  const ratingOptions = [
    { value: null, label: "Рейтинг" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const columns = `60px minmax(0, 1fr) 115px
                    minmax(0, 60px) 90px 95px
                    200px minmax(0, 1fr) minmax(0, 120px)`;

  let exportLoading = false;
  const download = async (storage) => {
    if (exportLoading) {
      return;
    }
    exportLoading = true;
    const { ok, data } = await request({
      path: "contacts/create_export_url",
      method: "POST",
      params: {
        storage: storage?.id,
      },
    });
    if (ok) {
      window.open(data.url, "_blank");
    }
    setTimeout(() => {
      exportLoading = false;
    }, 1000);
  };

  onMount(fetchContacts);
</script>

<svelte:head>
  <title>LPID - Контакты</title>
</svelte:head>

<ScrollWatch on:pageEnd={paginate} />

<ListHeader title="Контакты" count={contacts.count || 0}>
  <SelectSorting
    bind:value={sort}
    on:change={fetchContacts}
    options={sortOptions}
  />
  <SelectSorting
    bind:value={rating}
    on:change={fetchContacts}
    options={ratingOptions}
  />
  <ButtonSmall onClick={download} title="Скачать" />
  <div slot="last">
    <Search bind:search on:updated={fetchContacts} />
  </div>
</ListHeader>
<div class="wrapper bg-white">
  <List>
    <Card {columns} header>
      <Field header>ID</Field>
      <Field header>ФИО</Field>
      <Field header>Телефон</Field>
      <Field header>Скидка</Field>
      <Field header>Сумма</Field>
      <Field header>ДР</Field>
      <Field header>Адрес</Field>
      <Field header>Комментарий</Field>
      <Field header>Источник</Field>
    </Card>
    <div class="hidden last:flex w-full justify-center text-xl">
      <span>Нет результатов</span>
    </div>
    {#each contacts.results as contact}
      <Card {columns} to={`/crm/contacts/${contact.id}`}>
        <Field light tablet>
          {`${contact.id}`.padStart(6, "0")}
        </Field>
        <Field order="0">
          <div>
            <span>{contact.lastName} {contact.firstName}</span>
            {#if contact.needCheck}<span class="need-check">Проверка</span>{/if}
          </div>
        </Field>
        <Field order="2" bold>
          {contact.phone}
        </Field>
        <Field order="1" centered>
          <span><Discount {contact} /></span>
        </Field>
        <FlexBreak order="2" />
        <Field order="3" bold>
          {contact.salesSum || contact.autoSalesSum || 0}₽
        </Field>
        <Field order="4">
          <Date value={contact.birthday} />
        </Field>
        <Field order="5">
          {contact.address}
        </Field>
        <Field tablet light>
          {contact.comment}
        </Field>
        <FlexBreak order="5" />
        <Field order="5" light>
          <div class="source">
            <div>{contact.sourceLabel}</div>
            <div>{contact.sourceExtra}</div>
          </div>
        </Field>
      </Card>
    {/each}
  </List>
</div>

<RoundButton url="/crm/contacts/add" />

<style>
  .wrapper {
    padding: 20px 10px;
  }

  .source {
    display: flex;
    gap: 10px;
  }

  .need-check {
    display: inline-flex;
    background-color: #ff9900;
    border-radius: 10px;
    color: white;
    height: 18px;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
  }

  @media only screen and (min-width: 1180px) {
    .source {
      flex-direction: column;
      gap: 0;
    }
  }
</style>
