<script>
  import Search from "../lib/components/Search.svelte";
  import Button from "../lib/components/Button.svelte";
  import StockSettingsModal from "../lib/components/StockSettingsModal.svelte";
  import { request } from "../lib/tools/request.js";
  import StockItemsTable from "../lib/components/StockItemsTable.svelte";
  import StockItemRow from "../lib/components/StockItemRow.svelte";
  import { tableRowKey } from "../lib/stores/tableRowKey.js";
  import { onMount } from "svelte";
  import Num from "../lib/components/Num.svelte";

  let search;
  let settingsModalActive;
  const timers = {};
  let table;
  let total = {
    quantity: 0,
    price: 0,
  };

  const updateItem = async (item, field, event) => {
    const k = `${item.id}${field}`;
    clearTimeout(timers[k]);
    timers[k] = setTimeout(async () => {
      const { ok } = await request({
        path: `stock-items/${item.id}`,
        json: {
          id: item.id,
          [field]: event.target.value,
        },
      });
      event.target.classList.remove(
        "border-slate-100",
        "border-red-500",
        "border-white",
        "focus:border-blue-400",
      );
      if (ok) {
        event.target.classList.add("border-green-400");
        setTimeout(() => {
          event.target.classList.remove("border-green-400");
          event.target.classList.add("border-slate-100");
          event.target.classList.add("border-white");
          event.target.classList.add("focus:border-blue-400");
        }, 1500);
      } else {
        event.target.classList.add("border-red-500");
      }
    }, 500);
  };

  const fetchTotal = async () => {
    const { ok, data } = await request({
      path: "storage-items/total-aggregate",
    });

    if (ok) {
      total = { ...data };
    }
  };

  const selectItem = (item) => {
    tableRowKey.set($tableRowKey === item.id ? "" : item.id);
  };

  onMount(() => {
    setTimeout(fetchTotal, 2000);
  });
</script>

<div class="w-full flex h-full justify-between gap-5">
  <StockSettingsModal
    title="Новая категория"
    path="stock-categories"
    on:updated={() => {
      table.fetchItems();
    }}
    bind:active={settingsModalActive}
  />
  <div class="h-full w-full bg-white py-5">
    <div class="flex flex-col gap-5 w-full">
      <div class="whitespace-nowrap">
        <div class="flex gap-5 px-5 w-full overflow-x-scroll">
          <div>
            <Search bind:search />
          </div>
          <div class="ml-auto flex gap-2">
            <div class="h-full">
              <a href="/stock/add">
                <Button>Добавить</Button>
              </a>
            </div>
            <div>
              <Button
                on:click={() => (settingsModalActive = !settingsModalActive)}
                ><i class="material-symbols-outlined text-xl">settings</i
                ></Button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="whitespace-nowrap w-full overflow-x-scroll">
        <StockItemsTable bind:this={table} bind:search>
          <tr
            let:category
            slot="header"
            class="text-slate-400 w-full text-center"
          >
            <td
              colspan="3"
              class="pl-5 pt-2 pb-1 text-xl font-bold text-left text-black"
              >{category.title}</td
            >
            <td>Кол-во</td>
            <td>Цена</td>
            <td>Сумма</td>
          </tr>
          <tr
            let:item
            on:click={() => selectItem(item)}
            on:keydown
            slot="item"
            class="group w-full text-center cursor-pointer {$tableRowKey ===
            item.id
              ? 'bg-blue-300'
              : ''}"
            class:opacity-50={item.totalQuantity === 0}
          >
            <td class="border border-l-0 border-r-0 pl-5 py-1" colspan="3">
              <div class="flex gap-1">
                {#key item.id}
                  <StockItemRow {item}
                    >&nbsp;
                    <span slot="extra" class="text-slate-400"
                      >({item.vendorCode})</span
                    >
                  </StockItemRow>
                {/key}
              </div>
            </td>
            <td class="border border-l-0 border-r-0 py-1" on:keydown>
              <span><Num value={item.totalQuantity} /></span>
            </td>
            <td class="border border-l-0 border-r-0 py-1 w-28">
              <input
                value={item.price}
                type="number"
                on:click|stopPropagation={() => {
                  if ($tableRowKey !== item.id) {
                    selectItem(item);
                  }
                }}
                on:input={(e) => {
                  updateItem(item, "price", e);
                }}
                class="h-full w-20 py-1 border-2 rounded-xl text-center font-bold {$tableRowKey ===
                item.id
                  ? 'bg-white border-white focus:border-blue-400'
                  : 'bg-slate-100 border-slate-100'}"
              />
            </td>
            <td class="border border-l-0 border-r-0 py-1">
              <span><Num value={item.totalQuantity * item.price || 0} /></span>
            </td>
          </tr>
          <tr slot="total" class="w-full text-center font-bold">
            {#if total.quantity}
              <td colspan="3" class="pl-5 pt-2 pb-1 text-xl text-left">Итого</td
              >
              <td>
                <Num value={total.quantity} />
              </td>
              <td></td>
              <td>
                <Num value={total.price} />
              </td>
            {/if}
          </tr>
        </StockItemsTable>
      </div>
    </div>
  </div>
</div>
