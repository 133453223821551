<script>
  import Modal from "./Modal.svelte";
  import Button from "./Button.svelte";
  import { request } from "../tools/request.js";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let active;
  export let path;
  export let title;

  let error = null;

  $: active ? fetchItems() : null;

  let items = [];

  const fetchItems = async () => {
    const { ok, data } = await request({
      path: path,
    });

    if (ok) {
      items = [...data.results];
    }
  };

  const updateItem = async (itemId, title) => {
    const { ok } = await request({
      path: `${path}/${itemId}/`,
      json: {
        id: itemId,
        title,
      },
    });

    if (ok) {
      dispatch("updated");
    }
  };

  const switchItemsPositions = async (fromItemPosition, toItemPosition) => {
    if (fromItemPosition < 0 || toItemPosition < 0) {
      return;
    }

    if (fromItemPosition >= items.length || toItemPosition >= items.length) {
      return;
    }

    const fromPosition = items[fromItemPosition].position;
    const toPosition = items[toItemPosition].position;

    await request({
      path: `${path}/switch_positions`,
      json: {
        fromPosition,
        toPosition,
      },
    });
    await fetchItems();

    dispatch("updated");
  };

  const deleteItem = async (itemId) => {
    if (!confirm("Вы уверены?")) {
      return;
    }
    const { ok } = await request({
      path: `${path}/${itemId}/`,
      method: "DELETE",
    });
    await fetchItems();

    if (ok) {
      dispatch("updated");
    } else {
      error = itemId;
      setTimeout(() => {
        error = null;
      }, 2000);
    }
  };

  const addItem = async () => {
    const position =
      items.reduce((acc, curr) => Math.max(acc, curr.position), 0) + 1;
    const { ok } = await request({
      path: path,
      json: {
        title: `${title} ${position}`,
      },
    });
    await fetchItems();

    if (ok) {
      dispatch("updated");
    }
  };
</script>

<Modal bind:active>
  <div class="flex flex-col gap-5">
    <div>
      <span class="text-xl font-bold">Настройки</span>
    </div>
    <div class="">
      <slot name="extra-item"></slot>
      {#each items as item, i}
        <div
          class="whitespace-nowrap w-full border border-slate-200 border-b-0 px-5 flex justify-between items-center last:border-b"
        >
          <div
            class="py-2.5 w-full basis-1/3 shrink-0"
            contenteditable
            on:input={(e) => {
              updateItem(item.id, e.target.innerText);
            }}
          >
            {item.title}
            {#if error === item.id}<span class="text-red-400">Ошибка</span>{/if}
          </div>
          <div class="flex shrink-0">
            {#if i === items.length - 1}
              <div class="h-full p-2.5">
                <i class="material-symbols-outlined text-xl text-slate-400"
                  >expand_more</i
                >
              </div>
            {:else}
              <div
                class="h-full p-2.5 cursor-pointer"
                on:keydown
                on:click={() => {
                  switchItemsPositions(i, i + 1);
                }}
              >
                <i class="material-symbols-outlined text-xl">expand_more</i>
              </div>
            {/if}
            {#if i === 0}
              <div class="h-full p-2.5">
                <i class="material-symbols-outlined text-xl text-slate-400"
                  >expand_less</i
                >
              </div>
            {:else}
              <div
                class="h-full p-2.5 cursor-pointer"
                on:keydown
                on:click={() => {
                  switchItemsPositions(i, i - 1);
                }}
              >
                <i class="material-symbols-outlined text-xl">expand_less</i>
              </div>
            {/if}
            <slot name="extra-button" {item}></slot>
            <div
              class="h-full p-2.5 cursor-pointer"
              on:keydown
              on:click={() => {
                deleteItem(item.id);
              }}
            >
              <i class="material-symbols-outlined text-xl text-red-400"
                >delete</i
              >
            </div>
          </div>
        </div>
      {/each}
    </div>
    <div class="h-9">
      <Button accent on:click={addItem}>Создать</Button>
    </div>
    <div class="h-9">
      <Button secondary on:click={() => (active = false)}>Закрыть</Button>
    </div>
  </div>
  <svelte:fragment slot="extra">
    <slot name="extra" />
  </svelte:fragment>
</Modal>
