<script>
  import { reverseSizesMap } from "../lib/stores/sizeLabels.js";
  import { getTransferTitle } from "../lib/tools/transfer.js";
  import SizeLabel from "../lib/components/SizeLabel.svelte";

  export let history;
</script>

<div class="w-full flex flex-wrap gap-2 text-sm items-center">
  {#if history.transfer}
    <span
      class:text-red-400={history.comment === "Отправление" ||
        (history.transfer?.storageFrom && !history.transfer?.storageTo)}
      class:text-green-500={history.comment === "Отмена" ||
        history.comment === "Поступление" ||
        (!history.transfer?.storageFrom && history.transfer?.storageTo)}
      class="material-symbols-outlined">arrow_split</span
    >
  {:else if history.hasOrder}
    <span class="material-symbols-outlined text-orange-500"
      >shopping_cart_checkout</span
    >
  {:else if history.sale}
    <span class="material-symbols-outlined text-yellow-500">shopping_bag</span>
  {:else}
    <span class="material-symbols-outlined text-gray-500">app_registration</span
    >
  {/if}
  <slot name="storage" />
  <slot name="item" />
  <span class="uppercase"
    ><SizeLabel size={reverseSizesMap[history.size]} />:</span
  >
  <div>
    <span class="line-through">{history.old}</span> → <span>{history.new}</span>
  </div>
  {#if history.transfer}
    <a
      href="/transfers/{history.transfer.id}"
      class="text-slate-400 underline"
      target="_blank"
      >({getTransferTitle(history.transfer)} #{history.transfer.id})</a
    >
  {/if}
  <span class="text-slate-400"
    >({`${history.user.firstName} ${history.user.lastName}`.trim()})</span
  >
  <span class="text-slate-400"
    >{new Date(history.createdAt).toLocaleString()}</span
  >
  <span class="text-slate-400">{history.comment}</span>
</div>
