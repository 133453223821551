<script>
  import { request } from "../lib/tools/request";
  import page from "page";
  import { onMount } from "svelte";
  import StockStorageTable from "../lib/components/StockStorageTable.svelte";
  import noimage from "../assets/noimage.png";
  import { makeThumbnail } from "../lib/tools/img.js";

  let result = null;
  let searchInput = null;
  let searchValue = "";
  let match = null;
  let promoCodeMatch = null;
  let searchTimer = null;
  let items = [];
  const format = new Intl.NumberFormat("ru-ru");

  const searchPromocode = async (code) => {
    const { ok, data } = await request({
      path: "promo-code-search",
      params: {
        code,
      },
    });

    if (!ok) {
      return;
    }

    return data.promoCode;
  };

  const search = (value) => {
    clearTimeout(searchTimer);
    result = null;

    if (value.length < 3) {
      return;
    }

    searchTimer = setTimeout(async () => {
      page(`${location.pathname}?q=${value}`);

      promoCodeMatch = await searchPromocode(value);

      if (promoCodeMatch) {
        match = null;
        items = [];
        return;
      }

      const { ok, data } = await request({
        path: "stock-items",
        params: {
          search: value,
        },
      });

      result = ok;
      if (!ok) {
        return;
      }

      items = data.results;
      if (items.length === 1 || items[0]?.sizeByCode) {
        match = items[0];
      }
      if (!items.length) {
        result = false;
      }
    }, 300);
  };

  const clear = () => {
    if (!match) {
      searchValue = "";
      items = [];
    }
    match = null;
    searchInput.focus();
  };

  onMount(() => {
    const q = new URLSearchParams(location.search).get("q");
    if (q) {
      search(q);
      searchValue = q;
    }
    searchInput.focus();
  });
</script>

<div class="py-10 px-2.5 md:px-5 flex md:justify-center bg-white">
  <div class="w-full md:max-w-[1100px] flex flex-col gap-5">
    <h1 class="text-2xl font-bold">Сканер</h1>
    <div class="flex flex-col gap-1">
      <input
        type="text"
        bind:value={searchValue}
        bind:this={searchInput}
        on:input={(e) => search(e.target.value)}
        placeholder="Поиск товара или промокода"
        class:border-red-400={result === false}
        class:bg-red-100={result === false}
        class:border-slate-200={result !== false}
        class="h-10 w-full border-1 px-2.5"
      />
      <button
        class="p-2 text-left text-slate-400 text-sm rounded w-full"
        on:click={clear}
      >
        {#if match}
          К списку
        {:else}
          Очистить
        {/if}
      </button>
    </div>
    <div class="w-full min-h-screen flex flex-col gap-4">
      {#if match}
        <div
          class="group relative w-44 h-44 bg-slate-200/25 shrink-0 flex justify-center items-center"
        >
          {#if match.picture}
            <img
              src={match.picture}
              class="w-full h-full object-cover object-center"
              alt=""
            />
          {:else}
            <span class="text-black/50">Нет фото</span>
          {/if}
        </div>
        <div>
          <span class="text-slate-400">{match.vendorCode}</span>
        </div>
        <div>
          <span class="text-lg font-bold">{match.title}</span>
        </div>
        <div>
          <span class="font-medium text-2xl">{format.format(match.price)}</span>
        </div>
        <div>
          <a
            href="/stock/{match.id}"
            target="_blank"
            class="block text-center w-full rounded-lg py-2.5 bg-slate-100"
          >
            Карточка товара
          </a>
        </div>
        <div class="w-full pt-5">
          <StockStorageTable readonly={true} bind:item={match} />
        </div>
      {:else}
        {#each items as item}
          <div
            role="presentation"
            on:click={() => (match = item)}
            class="cursor-pointer w-full flex gap-2 items-center text-left"
          >
            <div class="print:hidden block h-9 w-9 basis-11">
              {#if item.picture}
                <img
                  src={makeThumbnail({
                    url: item.picture,
                    filters: "40x40/filters:quality(80)",
                  })}
                  class="object-cover h-full"
                  alt={item.title}
                />
              {:else}
                <img
                  class="h-full w-full object-fit"
                  src={noimage}
                  alt={item.title}
                />{/if}
            </div>
            <div class="w-full print:text-xs">
              <span>{item.title}</span>
              <span class="text-slate-400">({item.vendorCode})</span>
              <span class="font-medium">{format.format(item.price)}₽</span>
            </div>
          </div>
        {/each}
      {/if}
      {#if promoCodeMatch}
        <div class="w-full flex flex-col gap-1">
          <p class="text-xl font-bold">{promoCodeMatch.code}</p>
          <p class:text-green-500={promoCodeMatch.status === "created"}>
            {promoCodeMatch.statusLabel}
          </p>

          {#if promoCodeMatch.discountValue}
            <p>
              Доступная сумма: <span class="font-bold"
                >{promoCodeMatch.discountValue - promoCodeMatch.usedValue}</span
              >
            </p>
          {:else}
            <p>
              Скидка в процентах: <span class="font-bold"
                >{promoCodeMatch.discountPercent}%</span
              >
            </p>{/if}
          <p>
            Активен до {new Date(
              promoCodeMatch.validUntil,
            ).toLocaleDateString()}
          </p>
          <p>
            Создан {new Date(promoCodeMatch.createdAt).toLocaleDateString()}
          </p>
          <p>Тип: {promoCodeMatch.typeLabel}</p>
        </div>
      {/if}
    </div>
  </div>
</div>
