import { writable } from "svelte/store";
import { request } from "../tools/request";

function createUser() {
  const { subscribe, update, set } = writable();

  async function get() {
    const { ok, data } = await request({ path: "users/me" });
    if (ok) {
      update(() => data);
    }
  }

  return {
    subscribe,
    update,
    get,
    set,
  };
}

export const user = createUser();
