<script>
  import TransferStatus from "./TransferStatus.svelte"
  import { getTransferTitle } from "../tools/transfer"

  export let transfer = {}

  $: transferTitle = getTransferTitle(transfer)
</script>

<div class="px-5 flex align-middle items-center gap-2 text-xl font-bold">
  <a href="/transfers" class="print:hidden flex h-full"><span class="material-symbols-outlined">arrow_back_ios_new</span></a>
  <span>{transferTitle}</span>
  {#if transfer.id}
    <div class="flex h-full">
      <TransferStatus cn="text-xs font-normal" value={transfer.status} label={transfer.statusLabel} />
    </div>
  {/if}
</div>
