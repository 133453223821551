<script>
  import { storagePopupKey } from "../stores/storagePopupKey.js"
  import { tableRowKey } from "../stores/tableRowKey.js"
  import { request } from "../tools/request.js"
  import { sizesMap } from "../stores/sizeLabels.js"
  import { storageSizeSelected } from "../stores/storageSizeSelected.js"

  import { createEventDispatcher } from "svelte"
  import Num from "./Num.svelte"
  import SizeLabel from "./SizeLabel.svelte"
  import { storageTransferHistoryItem } from "../stores/storageTransferHistoryItem.js"

  const dispatch = createEventDispatcher()

  export let size
  export let active
  export let storageItem
  export let item
  export let readonly = false
  export let rowKey
  export let showPopup = true

  let thisTd = null

  let error = ""

  let value = storageItem[size]

  $: key = `${rowKey}_${storageItem.id}_${size}`
  $: opened = active && key === $storagePopupKey
  $: valueDelta = value === null ? 0 : value - storageItem[size]
  $: if (!opened) {
    value = storageItem[size]
    error = ""
  }

  $: {
    if ($storageSizeSelected.size === sizesMap[size] && $storageSizeSelected.id === item.id) {
      thisTd?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      togglePopup()
    }
  }

  const togglePopup = () => {
    if (!showPopup) {
      return
    }
    storagePopupKey.set(opened ? "" : key)
    tableRowKey.set($storagePopupKey ? rowKey : "")
  }

  const showStorageItemHistory = () => {
    togglePopup()
    if (storageItem.id) {
      storageTransferHistoryItem.set({
        ...storageItem,
        size,
      })
    } else {
      storageTransferHistoryItem.set({})
    }
  }

  const save = async () => {
    const { ok, data, error: saveError } = await request({
      path: `storage-items/${storageItem.id}`,
      json: {
        id: storageItem.id,
        // let the api validate it
        [size]: `${value}`
      }
    })
    if (ok) {
      storageItem = {
        ...data,
        storage: storageItem.storage,
        item: storageItem.item
      }
      storagePopupKey.set("")
      dispatch("updated")
    } else {
      value = value || storageItem[size]
      error = saveError[size]
    }
  }
</script>

<td bind:this={thisTd} class="relative border border-l-0 border-r-0 py-1 pl-1 w-14 h-9 align-middle">
  <div on:keydown on:click|stopPropagation={() => togglePopup(size)}
       class="relative flex justify-center items-center border-2 mx-auto w-[60px] h-9 rounded-xl px-2 whitespace-nowrap text-center font-bold
              {opened ? '!border-blue-400': ''} {active ? 'bg-white border-white': 'border-slate-100 bg-slate-100'}">
    <div
      class="{active ? 'block' : 'hidden'} pointer-events-none mb-2 absolute -top-0 px-2 text-xs border border-white rounded-full left-1/2 -translate-y-1/2 -translate-x-1/2 bg-gray-200 text-slate-400 font-light">
      <span class="uppercase"><SizeLabel size={size} /></span>
    </div>
    <span class:opacity-10={value === 0}><Num value={storageItem[size]} /></span>
  </div>
  <div
    on:click|stopPropagation on:keydown
    class="{opened ? 'flex': 'hidden' } w-[19rem] flex-col gap-2 absolute z-40 top-11 left-1/2 -translate-x-1/2 border border-slate-100 shadow-xl bg-white rounded-3xl p-1.5">
    <div class="flex justify-between items-center px-1 pl-3 py-1 w-full h-11 bg-slate-100 rounded-[20px]">
      <span class="font-bold uppercase opacity-40"><SizeLabel size={size} />:</span>
      <input disabled={readonly} title={error} bind:value type="number" class:text-red-500={error}
             class="h-full w-full bg-transparent px-2">
      {#if !readonly}
        <button on:keydown on:click|stopPropagation={save}
                class="border h-full min-w-[115px] rounded-2xl bg-blue-400 text-white px-3 whitespace-nowrap overflow-hidden">
          Готово
          {#if valueDelta}({valueDelta > 0 ? `+${valueDelta}` : valueDelta}){/if}
        </button>
      {/if}
    </div>
    {#if !readonly}
      <div class="flex gap-2 justify-between items-center w-full h-8">
        <button on:click|stopPropagation={showStorageItemHistory} on:keydown
                class="flex h-full w-full justify-center items-center rounded-2xl bg-yellow-100">
          <span class="material-symbols-outlined text-lg">history</span>
        </button>
        <button on:click|stopPropagation={() => {error = ''; value *= 0}} on:keydown
                class="flex h-full w-full justify-center items-center rounded-2xl bg-slate-100">0
        </button>
        <button on:click|stopPropagation={() => {error = ''; value += 1}} on:keydown
                class="flex h-full w-full justify-center items-center rounded-2xl bg-green-300">+1
        </button>
        <button on:click|stopPropagation={() => {error = ''; value -= 1}} on:keydown
                class="flex h-full w-full justify-center items-center rounded-2xl bg-red-100">-1
        </button>
      </div>
    {/if}
    <div class="flex justify-center items-center w-full text-xs text-slate-400">
      {item.title}
    </div>
  </div>
</td>