<script>
  import { onMount } from "svelte";
  import { request } from "../tools/request";
  import SizeLabel from "./SizeLabel.svelte";
  import { sizeLabels } from "../stores/sizeLabels.js";
  import StorageItemSizesRow from "./StorageItemSizesRow.svelte";
  import StockItemRow from "./StockItemRow.svelte";
  import Num from "./Num.svelte";

  export let storageId;
  export let filter = null;

  let storage = null;

  $: fetchStorageData(filter);

  const fetchStorageData = async (_) => {
    const { ok, data } = await request({
      path: `storages/${storageId}`,
      params: { filter },
    });

    if (ok) {
      storage = data;
    }
  };
</script>

{#if storage}
  <table class="w-full h-full text-sm border-collapse">
    <tbody class="h-full">
      {#each storage?.categories as category}
        <tr class="text-slate-400 text-center">
          <th
            colspan="4"
            class="pl-5 pt-2 pb-1 w-[500px] text-xl font-bold text-left text-black"
            >{category.title}</th
          >
          <th>Артикул</th>
          <th class="whitespace-nowrap">Кол-во</th>
          {#each sizeLabels as size}
            <th
              class:text-black={size === "selectedSize"}
              class="w-14 font-bold uppercase text-center"
            >
              <SizeLabel {size} />
            </th>
          {/each}
          <th class="px-3">Цена</th>
          <th class="px-3">Сумма</th>
        </tr>

        {#each category.items as storageItem}
          <StorageItemSizesRow
            {storageId}
            item={storageItem.item}
            {storageItem}
            showVendorCode
          >
            <div class="w-[500px]">
              <StockItemRow item={storageItem.item} />
            </div>
          </StorageItemSizesRow>
        {/each}
        <tr>
          <td
            class="text-center bg-slate-100 text-slate-400 h-8 align-middle"
            colspan="5"
          >
          </td>
          <td
            class="text-center bg-slate-100 text-slate-400 font-bold align-middle"
          >
            <Num value={category.total} />
          </td>
          {#each sizeLabels as size}
            <td class="text-center bg-slate-100 text-slate-400 align-middle">
              <Num value={category.totalSizes[size]}></Num>
            </td>
          {/each}
          <td class="text-center bg-slate-100 text-slate-400 align-middle">
          </td>
          <td class="text-center bg-slate-100 text-slate-400 align-middle">
            <Num value={category.totalPrice} />
          </td>
        </tr>
        {#if !category.items.length}
          <tr>
            <td class="pl-5 text-slate-400 w-[500px]">
              <span>---</span>
            </td>
          </tr>
        {/if}
      {/each}
      <tr class="font-bold text-center">
        <td colspan="5" class="pl-5 pt-2 pb-1 text-xl text-left">Итого</td>
        <td>
          <Num value={storage.total} />
        </td>
        {#each sizeLabels as _}
          <td></td>
        {/each}
        <td></td>
        <td>
          <Num value={storage.totalPrice} />
        </td>
      </tr>
    </tbody>
  </table>
{:else}
  <div class="w-full h-52 flex justify-center items-center">
    <span class="material-symbols-outlined animate-spin">progress_activity</span
    >
  </div>
{/if}
