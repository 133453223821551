<script>
  import page from "page";
  import { onMount } from "svelte";
  import { request } from "../tools/request.js";

  export let storageFrom;
  export let storageTo;
  export let errors = {};
  export let status;
  export let transferId;
  export let disabled = [
    "shipped",
    "ready_to_ship",
    "reserved",
    "done",
  ].includes(status);

  let storages = [];
  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = [...data.results];
    }
  };

  const createRevisionFromTransfer = async () => {
    if (!transferId) {
      return;
    }

    const { ok, data } = await request({
      path: "revisions/create-from-transfer",
      json: {
        transferId: transferId,
      },
    });
    if (ok) {
      window.open(`/revisions/${data.revision}`, "_blank");
    }
  };
  onMount(fetchStorages);
</script>

<div class="flex gap-2 px-5 whitespace-nowrap overflow-x-scroll">
  <select
    autocomplete="off"
    {disabled}
    bind:value={storageFrom}
    title={errors.storageTo}
    class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center {errors.storageFrom
      ? 'border-red-500'
      : ''}"
  >
    <option value={null}>Добавить (+)</option>
    {#each storages as storage}
      <option value={storage.id}>{storage.title}</option>
    {/each}
  </select>
  <div class="flex items-center">
    <span class="text-xl">→</span>
  </div>
  <select
    autocomplete="off"
    {disabled}
    bind:value={storageTo}
    title={errors.storageTo}
    class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center {errors.storageTo
      ? 'border-red-500'
      : ''}"
  >
    <option value={null}>Вычесть (-)</option>
    {#each storages as storage}
      <option value={storage.id}>{storage.title}</option>
    {/each}
  </select>
  <button
    on:click={() => window.print()}
    class="bg-white hover:bg-slate-100 px-2 py-1 border-1 border-slate-200 rounded-xl flex justify-center items-center"
  >
    <span class="material-symbols-outlined">download</span></button
  >
  {#if status !== "done"}
    <button
      on:click={createRevisionFromTransfer}
      class="bg-white hover:bg-slate-100 px-2 py-1 border-1 border-slate-200 rounded-xl flex justify-center items-center"
    >
      <span class="material-symbols-outlined">barcode_reader</span>
    </button>
  {/if}
  <slot />
</div>
