<script>
  export let size

  $: sizeName = size.split('Var')[0].replace('size', '')
  $: sizeSubName = size.split('Var')[1] || ''

</script>

<span>
  {sizeName}{#if sizeSubName}<span class="text-xs font-normal uppercase">/{sizeSubName}</span>{/if}
</span>