<script>
  import { fade } from "svelte/transition"
  import { request } from "../tools/request.js"
  import { onMount } from "svelte"

  export let path
  export let onSelect

  let showResults = false
  let items = []
  let timer = 0

  const search = async (value) => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(async () => {
      const { ok, data } = await request({
        path,
        params: {
          search: value
        }
      })

      if (ok) {
        items = data.results
      }
    }, 300)
  }

  onMount(search)
</script>

<div
  class="grow-1 relative"
  on:focusin={() => showResults = true}
>
  <input
    class="px-4 text-xs py-1 border-1 border-slate-200 rounded-lg w-[200px]"
    placeholder="Поиск"
    on:input={(e) => search(e.target.value)}
  >
  <button on:click|stopPropagation={() => {showResults = false}} class="absolute right-0 top-0 h-full w-5 bg-red-50/20 text-center">
    <span class="material-symbols-outlined text-xs">close</span>
  </button>
  {#if showResults}
    <div transition:fade={{duration: 150}}
         class="z-10 absolute top-full left-0 max-h-36 bg-white overflow-y-scroll rounded-b-lg shadow-2xl text-xs min-w-[300px]">
      <table class="w-full">
        {#each items as item}
          <tr
            on:click={() => {onSelect(item); showResults = false}}
            role="button"
            class="cursor-pointer hover:bg-gray-100"
          >
            <slot name="item" {item} />
          </tr>
        {/each}
      </table>
    </div>
  {/if}
</div>
