<script>
  import WarehouseItemInfo from "./WarehouseItemInfo.svelte";

  export let items;
  export let itemModalActive = false;
  export let activeItem;
</script>

<div class="text-sm">
  <div
    class="px-5 grid grid-cols-9 gap-1 border-b-1 border-slate-200 pb-3 font-bold text-slate-400"
  >
    <div class="col-span-4">Наименование</div>
    <div>Артикул</div>
    <div>Стоимость</div>
    <div>Количество</div>
    <div class="col-span-2">Брак</div>
  </div>
  {#each items.results as item}
    <div
      class="px-5 grid h-12 cursor-pointer grid-cols-9 gap-1 border-b-1 border-slate-200 py-1 overflow-y-hidden"
      on:keydown
      on:click={() => {
        activeItem = item;
        itemModalActive = true;
      }}
    >
      <div class="col-span-4 flex items-center">
        <WarehouseItemInfo {item}>
          <span slot="vendorCode" />
        </WarehouseItemInfo>
      </div>
      <div class="flex items-center">{item.vendorCode}</div>
      <div class="flex items-center">{item.price} ₽/{item.unitLabel}.</div>
      <div class="flex items-center">{item.quantity?.toFixed(2)} {item.unitLabel}.</div>
      <div class="col-span-2 flex items-center">
        {item.defect}
        {item.unitLabel}.
      </div>
    </div>
  {/each}
</div>
