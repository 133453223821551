<script>
    export let title;
    export let accent = false;
    export let saved = false;
    export let error = false;
    export let disabled = false;

    setTimeout(() => {saved = false}, 2000);
</script>

<button class:saved class:error class="button" class:accent on:click disabled={disabled}>
    {title}
</button>


<style>
    .button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #9598AC;
        background-color: #F1F2F5;
        border-radius: 12px;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        cursor: pointer;
        transition: background-color .3s ease-in-out;
    }

    .button:hover {
        color: black;
    }

    .button.accent {
        background-color: #0071E8;
        color: white;
    }

    .button.saved {
        background-color: #34C759 !important;
        color: white;
    }

    .button.error {
      background-color: #F51111 !important;
    }

    .button.error:hover {
      background-color: #da1010 !important;
    }

    .button.accent:hover {
        background-color: #197FEA;
    }

    .button.saved:hover {
        background-color: #42d767 !important;
        color: white;
    }
    .button:disabled,
    .button:disabled:hover {
        background-color: #F1F2F5;
        color: #9598AC;
    }
</style>