<script>
  import { onMount } from "svelte";
  import { getSearchParam, setSearchParam } from "../../tools/searchParams";
  import SearchInput from "../SearchInput.svelte";
  import { request } from "../../tools/request";
  let selectedItems = [];

  const selectItem = (item) => {
    if (selectedItems.find((t) => t.id === item.id) === undefined) {
      selectedItems = [...selectedItems, item];
    } else {
      selectedItems = selectedItems.filter((t) => t.id !== item.id);
    }
    selectedItems = selectedItems.map((t) => ({ ...t, selected: true }));
    setSearchParam([["item", selectedItems.map((s) => s.id)]]);
  };

  onMount(async () => {
    const selected = getSearchParam("item");
    if (!selected?.length) {
      return;
    }
    const { ok, data } = await request({
      path: "stock-items",
      params: {
        id: getSearchParam("item"),
      },
    });
    if (ok) {
      selectedItems = data.results.map((i) => ({ ...i, selected: true }));
    }
  });
</script>

<div class="w-full flex items-center gap-1 py-2">
  <span class="text-sm pr-2 font-bold">Наименования</span>
  <SearchInput path="stock-items" onSelect={selectItem}>
    <div class="w-full flex items-center" slot="item" let:item>
      <div class="h-10 w-10">
        <img class="h-full" src={item.picture} alt="" />
      </div>
      <span class="font-bold">{item.title}</span>
    </div>
  </SearchInput>
  <div>
    {#each selectedItems as item}
      <button
        on:click={() => selectItem(item)}
        class:bg-blue-400={true}
        class:text-white={true}
        class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
      >
        {item.title}
      </button>
    {/each}
  </div>
</div>
