<script>
  import {onMount} from "svelte";
  import {request} from "../tools/request.js";
  import SizeLabel from "./SizeLabel.svelte";
  import {sizeLabels} from "../stores/sizeLabels.js";
  import StockItemRow from "./StockItemRow.svelte";
  import {storagePopupKey} from "../stores/storagePopupKey.js";

  export let transfer = {}
  let transferItems = []
  let categories = []

  const fetchTransferItems = async () => {
    const {ok, data} = await request({
      path: `storage-transfer-items`,
      params: {
        transfer: transfer.id,
      }
    })

    if (ok) {
      transferItems = data.results
    }
  }

  const fetchCategories = async () => {
    const {ok, data} = await request({
      path: 'stock-categories',
    })

    if (ok) {
      categories = data.results
    }
  }

  onMount(async () => {
    await Promise.all([
      fetchTransferItems(),
      fetchCategories(),
    ])
  })

  const selectItem = (itemId, size) => {
    const k = `${itemId}_${size}`
    storagePopupKey.set($storagePopupKey === k ? '' : k)
  }

  const filterCategory = (category) => {
    return filterItems(category).length > 0
  }

  const filterItems = (category) => {
    return transferItems.filter(i => {
      return i.itemDict.category === category.id && sizeLabels.some(s => !!i[s])
    })
  }
</script>

<div class="w-full flex flex-col gap-2 py-1 md:px-5">
  <div class="w-full flex">
    <textarea value={transfer.comment} disabled class="resize-none w-full md:w-1/2 border rounded-xl border-slate-200 p-2"
              rows="2" placeholder="Комментарий"></textarea>
  </div>
  {#if transferItems.length}
    <div class="justify-start whitespace-nowrap overflow-x-scroll print:whitespace-pre-wrap">
      <table class="w-full text-sm border-collapse">
        <tbody>
        {#each categories as category}
          {#if filterCategory(category)}
            <tr>
              <td colspan="4"
                  class="pl-5 md:pl-0 pt-2 pb-1 text-xl font-bold text-left text-black">{category.title}</td>
              {#each sizeLabels as size}
                <td class="font-bold px-2 uppercase text-center text-slate-400">
                  <SizeLabel size={size}/>
                </td>
              {/each}
            </tr>
            {#each filterItems(category) as transferItem}
              <tr class:bg-blue-300={$storagePopupKey.search(`${transferItem.id}_`) !== -1}
                  on:click={() => selectItem(transferItem.id, '')}>
                <td colspan="4" class="border-t border-b border-slate-200">
                  <div class="flex gap-1">
                    <StockItemRow item={transferItem.itemDict}/>
                  </div>
                </td>
                {#each sizeLabels as size}
                  <td class="text-center border-t border-b border-slate-200">
                    <div class="flex justify-center" class:opacity-10={(transferItem[size] || 0) === 0}>
                      <div
                        class="font-bold px-2 py-1 bg-slate-100 rounded-xl">{transferItem[size] || 0}</div>
                    </div>
                  </td>
                {/each}
              </tr>
            {/each}
          {/if}
        {/each}
        </tbody>
      </table>
    </div>
  {:else}
    <span>Нет наименований</span>
  {/if}
</div>