<script>
  import page from 'page'
  import {onMount} from "svelte";
  import {request} from "../lib/tools/request";
  import SelectTransferStorage from "../lib/components/SelectTransferStorage.svelte";
  import TransferTitle from "../lib/components/TransferTitle.svelte";

  let storageFrom
  let storageTo
  let saving = false
  let storages = []
  let errors = {}

  const fetchStorages = async () => {
    const {ok, data} = await request({
      path: 'storages'
    })

    if (ok) {
      storages = [...data.results]
    }
  }

  const create = async () => {
    saving = true
    const {ok, data, error} = await request({
      path: 'storage-transfers',
      json: {
        storageFrom,
        storageTo
      },
    })

    errors = {
      ...(error || {})
    }

    if (ok) {
      page(`/transfers/${data.id}`)
    }
    saving = false
  }

  onMount(async () => {
    await fetchStorages()
  })
</script>

<div class="w-full h-screen bg-white py-2">
  <div class="w-full flex flex-col gap-3">
    <TransferTitle/>
    <div class="w-full flex gap-2 py-1 md:px-5 items-stretch justify-start whitespace-nowrap overflow-y-scroll">
      <SelectTransferStorage
        bind:storageFrom
        bind:storageTo
        bind:errors
        bind:disabled={saving}
        showDownload={false}
      >

        <button class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5" on:click={create}>Создать
        </button>

        <div class="flex gap-1 text-red-400 text-sm items-center">
          {#each errors?.nonFieldErrors || [] as error}
            <span>{error}</span>
          {/each}
        </div>
      </SelectTransferStorage>
    </div>
  </div>
</div>
