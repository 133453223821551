<div id="results">
    <slot></slot>
</div>


<style>
    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
</style>
