<script>
  import noimage from "../../assets/noimage.png";
  import { makeThumbnail } from "../tools/img";

  export let item;

  let thumbnail = item.picture
    ? makeThumbnail({ url: item.picture, filters: "40x40/filters:quality(80)" })
    : null;
</script>

<div class="h-full w-full flex gap-2 items-center text-left">
  <a href="/stock/{item.id}" class="print:hidden block h-9 w-9 basis-11">
    {#if thumbnail}
      <img src={thumbnail} class="object-cover h-full" alt={item.title} />
    {:else}
      <img
        class="h-full w-full object-fit"
        src={noimage}
        alt={item.title}
      />{/if}
  </a>
  <div class="w-full print:text-xs">
    <span>{item.title}</span>
    <slot name="extra" />
  </div>
</div>
