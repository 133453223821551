<script>
  export let url;
</script>

<a class="round-button" href={url}>
  <i class="material-symbols-outlined">add</i>
</a>

<style>
  .round-button {
    text-decoration: none;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    font-size: 25px;
    bottom: 25px;
    right: 25px;
    background-color: #0071e8;
    color: white;
    width: 50px;
    border-radius: 25px;
    height: 50px;
    box-shadow: 0px 2px 30px rgba(0, 113, 232, 0.35);
  }
  .round-button:hover {
    box-shadow: 0px 2px 30px 10px rgba(0, 113, 232, 0.35);
  }
</style>
