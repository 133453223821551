<script>
  export let accent = false;
  export let success = false;
  export let secondary = false;
  export let danger = false;
  export let disabled = false;
</script>

<button
  on:click
  class="transition flex h-full w-full items-center justify-center rounded-xl border border-slate-200 px-2.5 text-sm
 {accent ? 'bg-blue-400 text-white border-0' : ''}
 {success ? 'bg-green-500 text-white border-0' : ''}
 {disabled || secondary ? 'bg-slate-400 text-white' : ''}
 {disabled ? 'cursor-not-allowed' : ''}
 {!disabled && danger ? 'border-none bg-slate-100 text-red-400' : ''}"
  {disabled}
>
  <slot/>
</button>
