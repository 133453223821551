<script>
  import { onMount } from "svelte";
  import { categories } from "../stores/categories";
  import Select from "./Select.svelte";

  $: choices = [{ id: null, title: "Все категории" }, ...$categories];
  export let selected;

  onMount(async () => {
    await categories.get();
    selected = choices[0].id;
  });
</script>

<Select bind:selected bind:options={choices} />
