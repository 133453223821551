<script lang="ts">
  import { onMount } from "svelte";
  import { request } from "../../tools/request";
  import { getSearchParam, setSearchParam } from "../../tools/searchParams";

  export let storages = [];

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    const selectedStorages = getSearchParam("storage").map((s) => Number(s));

    if (ok) {
      storages = data.results.map((s) => {
        s.selected = !!selectedStorages.find(
          (selectedId) => s.id === selectedId,
        );
        return s;
      });
    }
  };

  const selectStorage = async (index) => {
    storages[index].selected = !storages[index].selected;
    storages = [...storages];
    setSearchParam([
      ["storage", storages.filter((s) => s.selected).map((s) => s.id)],
    ]);
  };

  onMount(fetchStorages);
</script>

<div class="w-full flex items-center gap-1 py-2 flex-wrap">
  <span class="text-sm pr-2 font-bold">Склады</span>
  {#each storages as storage, index}
    <button
      on:click={() => selectStorage(index)}
      class:bg-blue-400={storage.selected}
      class:text-white={storage.selected}
      class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
    >
      {storage.title}
    </button>
  {/each}
</div>
