<script>
  import {onMount} from "svelte";
  import {request} from "../lib/tools/request.js";


  let loading = false
  let revisions = []

  const fetchRevisions = async () => {
    loading = true
    const {ok, data} = await request({
      path: 'revisions',
    })

    if (ok) {
      revisions = data.results
    }
    loading = false

  }
  onMount(fetchRevisions)
</script>

<div class="w-full bg-white">
  <div class="w-full flex justify-between p-5">
    <span class="text-xl font-bold">Инвентаризация</span>
    <a href="/revisions/add">
      <button class="py-2 px-4 bg-blue-400 hover:bg-blue-600 text-white rounded-xl">Создать</button>
    </a>
  </div>
  {#if loading}
    <div class="w-full flex justify-center text-2xl">
      <span class="material-symbols-outlined animate-spin">progress_activity</span>
    </div>
  {:else}
    <div class="overflow-hidden overflow-x-scroll">
      <div class="md:w-full inline-flex flex-col">
        {#each revisions as revision}
          <a href="/revisions/{revision.id}"
             class="whitespace-nowrap pointer w-full flex justify-between px-5 py-2 border-t last:border-b">
            <div class="flex gap-2">
              <span>Инвентаризация</span>
              <span>#{revision.id}</span>
              {#if revision.storage}<span class="font-bold">{revision.storage.title}</span>{/if}
              {#if revision.category}<span class="text-slate-400">({revision.category.title})</span>{/if}
              <span>от {new Date(revision.createdAt).toLocaleDateString()}</span>
              <div class="h-full flex items-center text-sm text-slate-400">{revision.comment}</div>
            </div>
            <div class="text-sm text-gray-400 pl-5">Создал {revision.user.username}</div>
          </a>
        {/each}
      </div>
    </div>
  {/if}
</div>

