<script>
  import { onMount } from "svelte"

  export let content
  let element


  const updateHeight = () => {
    element.style.height = ""
    element.style.height = element.scrollHeight + "px"
  }

  onMount(() => setTimeout(updateHeight, 500))

</script>

<textarea bind:this={element}
          bind:value={content}
          on:input={updateHeight}
     class="border border-gray-200 resize-none w-full p-2 overflow-scroll"
     data-editor />
