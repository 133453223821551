<script>
  import { onMount } from "svelte";
  import { sizeLabels, sizesMap } from "../stores/sizeLabels";
  import { request } from "../tools/request";

  export let stockItemId;
  let vendorCodes = {};
  const timers = {};
  const results = {};

  const fetchStockItemVendorCodes = async () => {
    const { ok, data } = await request({
      path: "stock-item-vendor-codes",
      params: {
        stock_item: stockItemId,
      },
    });
    if (ok) {
      data.results.forEach((d) => {
        vendorCodes[d.size] = d;
      });
      vendorCodes = { ...vendorCodes };
    }
  };

  const save = async (vendorCode, value) => {
    if (timers[vendorCode.id]) {
      clearTimeout(timers[vendorCode.id]);
    }

    timers[vendorCode.id] = setTimeout(async () => {
      results[vendorCode.id] = undefined;
      const { ok } = await request({
        path: `stock-item-vendor-codes/${vendorCode.id}`,
        json: {
          id: vendorCode.id,
          vendorCode: value,
        },
      });
      results[vendorCode.id] = ok;
      setTimeout(() => (results[vendorCode.id] = undefined), 1000);
    }, 300);
  };

  onMount(fetchStockItemVendorCodes);
</script>

<div class="shrink-0 flex flex-col gap-2">
  {#each sizeLabels as size}
    {#if vendorCodes[sizesMap[size]]}
      <div class="h-[46px] pr-2 font-bold flex items-center">
        <input
          type="text"
          value={vendorCodes[sizesMap[size]].vendorCode ?? ""}
          on:input={(e) => save(vendorCodes[sizesMap[size]], e.target.value)}
          class="transition-colors font-semibold border h-[46px] md:w-[250px] max-w-full px-1"
          class:border-red-500={results[vendorCodes[sizesMap[size]].id] ===
            false}
          class:border-green-500={results[vendorCodes[sizesMap[size]].id] ===
            true}
          class:border-slate-200={results[vendorCodes[sizesMap[size]].id] ===
            undefined}
        />
      </div>
    {/if}
  {/each}
</div>
