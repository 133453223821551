<script>
    export let error = {};
</script>

{#if error.detail}
    <div class="error">
        {error.detail}
    </div>
{/if}

<style>
    .error {
        color: #F51111;
    }
</style>