<script lang="ts">
  import { getSearchParam, setSearchParam } from "../../tools/searchParams";

  let startDate = getSearchParam("created_at_after")[0] ?? null;
  let endDate = getSearchParam("created_at_before")[0] ?? null;

  $: setSearchParam([
    ["created_at_after", startDate],
    ["created_at_before", endDate],
  ]);
</script>

<div class="w-full flex items-center gap-1 py-2">
  <span class="text-sm pr-2 font-bold">Даты</span>
  <input
    bind:value={startDate}
    class="px-4 text-xs py-1 border-1 border-slate-200 rounded-lg"
    placeholder="От"
    type="date"
  />
  <div>—</div>
  <input
    bind:value={endDate}
    class="px-4 text-xs py-1 border-1 border-slate-200 rounded-lg"
    placeholder="До"
    type="date"
  />
</div>
