<script>
  import WarehouseItemInfo from "./WarehouseItemInfo.svelte";
  import Toggle from "./Toggle.svelte";
  import { slide } from "svelte/transition";

  export let item;
  export let card;
  $: {
    if (!item.sizes["one size"]) {
      item.sizes["one size"] = {};
    }
    item.total = {};
    ["cut", "footage", "quantityPerPiece", "thisQuantity"].forEach((field) => {
      item.total[field] = getTotalValueForSizes(field);
    });
  }

  function getTotalValueForSizes(field) {
    return Object.values(item.sizes)
      .filter((s) => {
        return !!card.data.sizes.find((c) => c.id === s.label)?.value;
      })
      .reduce((a, b) => {
        if (!b.label || b.label === "one size") {
          return a;
        }
        return a + b[field] || 0;
      }, 0);
  }

  function r(val, label) {
    if (val) {
      return `<span>${
        Math.round(val * 100) / 100
      }</span>&nbsp;<span class="text-slate-400">${label || ""}</span>`;
    }
    return "";
  }
</script>

<div class="grid grid-cols-24 border-t-1 border-b-1">
  <div class="col-span-7 flex gap-1 border-l-1 py-1 px-3.5 items-center">
    <WarehouseItemInfo {item}
      ><span slot="category" />
      <span class="font-bold" slot="vendorCode">{item.vendorCode}</span>
      <span slot="title">{item.title}</span>
    </WarehouseItemInfo>

    <div class="flex h-full items-center py-1">
      <span class="text-slate-400 whitespace-nowrap"
        >({item.quantity} {item.unitLabel})</span
      >
    </div>
  </div>
  <div class="col-span-5 border-l-1 py-3 flex px-3.5">
    <input
      class="w-full"
      type="text"
      disabled={card.isFinalized}
      bind:value={item.comment}
      placeholder="Комментарий"
    />
  </div>
  <div class="col-span-1 cursor-pointer border-l-1">
    <Toggle bind:active={item.hasSizes} bind:disabled={card.isFinalized} />
  </div>
  <div
    class="col-span-2 flex justify-center border-l-1 py-3 items-center {item.unit !==
    'meter'
      ? 'bg-slate-100'
      : ''}"
  >
    {#if item.unit === "meter"}
      {#if !item.hasSizes}
        <div class="flex w-full gap-1 justify-center">
          <input
            class="w-1/4 text-center"
            type="number"
            placeholder="-"
            bind:value={item.sizes["one size"].cut}
            disabled={card.isFinalized}
            on:click={(e) => {
              e.target.focus();
              e.target.select();
            }}
          />
          {#if item.sizes["one size"]?.cut}<span class="text-slate-400">см</span
            >{/if}
        </div>
      {/if}
    {/if}
  </div>
  <div
    class="col-span-2 border-l-1 py-3 flex justify-center items-center {item.unit !==
    'meter'
      ? 'bg-slate-100'
      : ''}"
  >
    {#if item.hasSizes}
      {#if item.total.footage}
        {@html r(item.total.footage, "м.")}
      {/if}
    {:else}
      {@html r(item.sizes["one size"].footage, "м.")}
    {/if}
  </div>
  <div class="col-span-2 flex justify-center border-l-1 py-3 items-center">
    {#if !item.hasSizes}
      <div class="flex w-full justify-center gap-1">
        <input
          class="w-1/2 text-right"
          type="number"
          placeholder="-"
          disabled={card.isFinalized}
          on:click={(e) => {
            e.target.focus();
            e.target.select();
          }}
          bind:value={item.sizes["one size"].quantityPerPiece}
        />
        <span class="w-1/2 text-slate-400"
          >{#if item.sizes["one size"]?.quantityPerPiece}шт{/if}</span
        >
      </div>
    {/if}
  </div>
  <div class="col-span-2 border-l-1 py-3 flex justify-center items-center">
    {#if item.hasSizes}
      {@html r(item.total.thisQuantity, "шт.")}
    {:else}
      {@html r(item.sizes["one size"]?.thisQuantity, "шт.")}
    {/if}
  </div>
  <div
    class="col-span-2 border-l-1 border-r-1 py-3 flex justify-center items-center"
  >
    {@html r(item.thisPrice, "₽")}
  </div>
  <div class="col-span-1 w-full h-full cursor-pointer">
    <slot name="deleteButton" />
  </div>
</div>
{#if item.hasSizes}
  {#each card.data.sizes as size}
    {#if size.value}
      <div class="group grid grid-cols-24" transition:slide>
        <div
          class="col-start-13 border-l-1 border-b-1 group-last:border-b-0 py-3 flex justify-center items-center text-center"
        >
          <span class="uppercase">{size.id}</span>
        </div>
        <div
          class="col-span-2 flex justify-center border-l-1 py-3 items-center border-b-1 group-last:border-b-0 {item.unit !==
          'meter'
            ? 'bg-slate-100'
            : ''}"
        >
          {#if item.unit === "meter"}
            <div class="flex w-full justify-center gap-1">
              <input
                class="w-1/2 text-right"
                type="number"
                placeholder="-"
                disabled={item.unit !== "meter" || card.isFinalized}
                on:click={(e) => {
                  e.target.focus();
                  e.target.select();
                }}
                bind:value={item.sizes[size.id].cut}
              />
              <span class="w-1/2 text-slate-400"
                >{#if item.sizes[size.id]?.cut}см{/if}</span
              >
            </div>
          {/if}
        </div>
        <div
          class="col-span-2 border-l-1 py-3 flex justify-center items-center border-b-1 group-last:border-b-0 {item.unit !==
          'meter'
            ? 'bg-slate-100'
            : ''}"
        >
          {@html r(item.sizes[size.id]?.footage, "м.")}
        </div>
        <div
          class="col-span-2 flex justify-center border-l-1 border-b-1 group-last:border-b-0 py-3 items-center"
        >
          <div class="flex w-full justify-center gap-1">
            <input
              class="w-1/2 text-right"
              type="number"
              placeholder="-"
              disabled={card.isFinalized}
              on:click={(e) => {
                e.target.focus();
                e.target.select();
              }}
              bind:value={item.sizes[size.id].quantityPerPiece}
            />
            <span class="text-slate-400 w-1/2"
              >{#if item.sizes[size.id]?.quantityPerPiece}шт.{/if}</span
            >
          </div>
        </div>
        <div
          class="col-span-2 border-l-1 border-b-1 group-last:border-b-0 py-3 flex justify-center items-center"
        >
          {@html r(item.sizes[size.id]?.thisQuantity, "шт.")}
        </div>
        <div
          class="col-span-2 border-l-1 border-r-1 border-b-1 group-last:border-b-0 py-3 flex justify-center items-center"
        >
          {@html r(item.sizes[size.id].price, "₽")}
        </div>
        <div />
      </div>
    {/if}
  {/each}
{/if}
