<script>
  export let value;
  export let label;
  export let cn = "font-bold";
</script>

<span
  class={cn}
  class:text-yellow-500={value === "created"}
  class:text-slate-400={value === "canceled"}
  class:text-green-400={value === "done"}
  class:text-blue-400={["reserved", "shipped", "ready_to_ship"].includes(value)}
  >{label || ""}</span
>
