<script>
  import {fade} from 'svelte/transition'

  export let active = false;
  export let onClose = () => {}

  $: if (active) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
</script>

{#if active}
  <div
      transition:fade={{duration: 100}}
      class="fixed top-0 left-0 z-50 h-screen w-full flex flex-col justify-start items-center py-2.5 pt-10 gap-2.5 overflow-y-scroll bg-slate-400/30"
      on:click|stopPropagation={() => {
      onClose()
      active = false;
    }}
      on:keydown
  >
    <div
        class="max-w-full sm:max-w-96 sm:min-w-[550px] rounded-2.5xl bg-white py-5 px-5 shadow-lg whitespace-nowrap"
        on:click|stopPropagation={() => {}}
        on:keydown
    >
      <slot/>
    </div>
    <slot name="extra"/>
  </div>
{/if}
