export const getTitle = (transfer) => {
  if (transfer.storageFrom && transfer.storageTo) {
    return "Перемещение";
  }

  if (transfer.storageFrom && !transfer.storageTo) {
    return "Списание";
  }

  if (transfer.storageTo && !transfer.storageFrom) {
    return "Зачисление";
  }
  return "Новое перемещение";
};
