<script>
  import {createEventDispatcher} from "svelte";
  import AutoComplete from "simple-svelte-autocomplete";

  import Modal from "./Modal.svelte";
  import WarehouseInput from "./WarehouseInput.svelte";
  import Button from "./Button.svelte";
  import {request, uploadFile} from "../tools/request";
  import {categories} from "../stores/categories";
  import WarehouseItemsHistory from "./WarehouseItemsHistory.svelte";

  export let active = false;
  export let item = {};

  const dispatch = createEventDispatcher();
  let error = {};
  let form;

  $: if (active) {
    error = {};
  }

  async function save(e) {
    e.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    form.isSubmitting = true;
    let path = "items";
    if (item.id) {
      path += `/${item.id}`;
    }
    const {ok, error: submitError} = await request({
      path: path,
      json: form,
    });
    form.isSubmitting = false;
    error = submitError;
    if (ok) {
      dispatch("itemUpdated");
      active = false;
    }
  }

  async function deleteItem(e) {
    e.preventDefault();
    if (!item.id || !confirm(`Удалить ${item.title}?`)) {
      return;
    }
    const {ok, error: submitError} = await request({
      path: `items/${item.id}`,
      method: "DELETE",
    });
    error = submitError;
    if (ok) {
      dispatch("itemUpdated");
      active = false;
    }
  }

  async function savePicture(e) {
    const {url} = await uploadFile(e.target.files[0]);
    if (url) {
      item.picture = url;
    }
  }
</script>

<Modal bind:active>
  <form bind:this={form} class="contents">
    <div class="flex flex-col gap-2.5 overflow-x-hidden">
      <div class="font-bold text-xl">
        {item.id ? item.title : "Новая позиция"}
      </div>
      <input type="hidden" name="id" bind:value={item.id}/>
      <WarehouseInput label="Фотография" name="photo">
        <div slot="input" class="flex w-full items-center">
          <input type="hidden" name="picture" bind:value={item.picture}/>
          <input type="file" on:change={savePicture}/>
        </div>
      </WarehouseInput>
      {#if item.id && item.picture}
        <div class="flex h-44 w-full gap-5">
          <div class="w-36 flex-shrink-0 flex-grow-0"/>
          <div class="flex h-40 flex-col gap-1">
            <img
              class="h-full border-1 border-slate-200 object-cover"
              src={item.picture}
              alt={item.title}
            />
            <div
              class="cursor-pointer text-xs text-slate-400"
              on:keydown
              on:click={() => {
                item.picture = null;
              }}
            >
              Удалить фото
            </div>
          </div>
        </div>
      {/if}
      <WarehouseInput
        label="Категория"
        name="category"
        required
        bind:value={item.category}
      >
        <AutoComplete
          items={$categories}
          labelFieldName="title"
          valueFieldName="id"
          slot="input"
          hideArrow
          required
          selectName="categoryId"
          bind:selectedItem={item.category}
        />
      </WarehouseInput>
      <WarehouseInput
        label="Название"
        name="title"
        required
        bind:value={item.title}
        bind:error
      />
      <WarehouseInput
        label="Артикул"
        name="vendorCode"
        required
        bind:value={item.vendorCode}
        bind:error
      />
      <WarehouseInput
        label="Единица измерения"
        name="unit"
        required
        bind:error
        bind:value={item.unit}
      >
        <div slot="input" class="flex h-full gap-0">
          <input type="hidden" name="unit" bind:value={item.unit}/>
          <button
            class="h-full border-t-1 border-b-1 border-l-1 border-slate-200 px-2.5  {item.unit ===
            'piece'
              ? 'bg-blue-400 text-white'
              : 'text-slate-400'}"
            on:click|preventDefault={() => {
              item.unit = "piece";
            }}>штука
          </button
          >
          <button
            class="h-full border-1 border-slate-200 px-2.5  {item.unit ===
            'meter'
              ? 'bg-blue-400 text-white'
              : 'text-slate-400'}"
            on:click|preventDefault={() => {
              item.unit = "meter";
            }}>метр
          </button
          >
        </div>
      </WarehouseInput>
      <WarehouseInput
        label="Стоимость за ед."
        name="price"
        required
        bind:value={item.price}
        bind:error
      />
      <WarehouseInput
        label="Количество"
        name="quantity"
        required
        bind:value={item.quantity}
        bind:error
      />
      {#if item.id}
        <WarehouseInput
          label="Брак"
          name="defect"
          required
          bind:value={item.defect}
          bind:error
        />
      {/if}
      <div class="h-28">
        <WarehouseInput
          label="Описание"
          name="description"
          required
          bind:value={item.description}
          bind:error
        >
          <div slot="input" class="flex gap-0 h-20">
            <textarea class="resize-none border border-slate-200 w-full h-20" name="description"
                      bind:value={item.description} rows="3"></textarea>
          </div>
        </WarehouseInput>
      </div>
      <div class="flex w-full flex-col gap-2.5">
        <div class="h-9">
          <Button
            disabled={form?.isSubmitting}
            accent
            on:click={save}
          >{item.id ? "Сохранить" : "Добавить"}</Button>
        </div>
        <div class="h-9">
          <div class="flex h-full gap-2.5">
            {#if item.id}
              <Button
                disabled={form?.isSubmitting}
                danger
                on:click={deleteItem}
              >Удалить
              </Button>
            {/if}
            <Button
              disabled={form?.isSubmitting}
              secondary
              on:click={() => {
                active = false;
              }}
            >Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div slot="extra" class="w-3/12 min-w-[550px]">
    {#if item.id}
      <div
        class="rounded-2.5xl bg-white py-5 px-5 shadow-lg overflow-hidden"
        on:click|stopPropagation={() => {}}
        on:keydown
      >
        <WarehouseItemsHistory itemId={item.id}/>
      </div>
    {/if}
  </div>
</Modal>
