import { writable } from "svelte/store";
import { request } from "../tools/request";

function createCategories() {
  const { subscribe, update } = writable([]);

  async function get() {
    const { ok, data } = await request({ path: "categories" });
    if (ok) {
      update(() => data.results);
    }
  }

  return {
    subscribe,
    update,
    get,
  };
}

export const categories = createCategories();
