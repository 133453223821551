export const getTransferTitle = (transfer) => {
  if (!transfer.id) {
    return "Новое перемещение";
  }
  if (transfer.storageFrom && transfer.storageTo) {
    return "Перемещение";
  }

  if (!transfer.storageFrom && transfer.storageTo) {
    return "Зачисление";
  }

  if (transfer.storageFrom && !transfer.storageTo) {
    return "Списание";
  }
};
