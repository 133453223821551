<script>
  import {request} from "../lib/tools/request.js";
  import {sizeLabels} from "../lib/stores/sizeLabels.js";
  import {onMount} from "svelte";
  import SizeLabel from "../lib/components/SizeLabel.svelte";
  import Num from "../lib/components/Num.svelte";

  export let params;
  const dateFormat = new Intl.DateTimeFormat("ru-ru", {month: "long", year: "numeric"})

  let report = {}
  let shops = []
  let categories = []
  let key

  const fetchReport = async () => {
    const {ok, data} = await request({
      path: `sale-reports/${params.id}`,
    })
    if (ok) {
      report = data
    }
  }
  const fetchShops = async () => {
    const {ok, data} = await request({
      path: `shops`,
    })
    if (ok) {
      shops = data.results.map(s => {
        s.selected = true;
        return s
      })
    }
  }
  const fetchCategories = async () => {
    const {ok, data} = await request({
      path: `stock-categories`,
    })
    if (ok) {
      categories = data.results
    }
  }

  const filterShops = (thisShops) => {
    return thisShops.filter(s => s.selected)
  }

  const filterItems = (categoryId) => {
    return Object.keys(report.data.categories[categoryId].items).toSorted().map(k => report.data.categories[categoryId].items[k])
  }

  const getKey = (categoryId, item) => {
    return `${categoryId}_${item.values.id}`
  }

  const selectRow = (categoryId, item) => {
    key = getKey(categoryId, item)
  }

  onMount(async () => {
    await Promise.all([
      fetchShops(),
      fetchReport(),
      fetchCategories(),
    ])
  })
</script>

<div class="w-full flex flex-col gap-4 md:px-7 py-5 bg-white">
  {#if (report.id && categories.length && shops.length)}
    <div class="flex gap-2 whitespace-nowrap overflow-x-scroll">
      <a href="/crm/sale-reports" class="print:hidden flex h-full"><span class="material-symbols-outlined">arrow_back_ios_new</span></a>
      <span class="text-xl font-bold">Отчёт #{report.id} за {dateFormat.format(new Date(report.createdAt))}</span>
    </div>
    <div class="flex gap-2 text-xs whitespace-nowrap overflow-x-scroll">
      {#each shops as shop}
        <button
          class="{shop.selected ? 'bg-blue-400 text-white border-blue-400' : 'bg-white text-slate-400 border-slate-200'} border rounded-xl px-3 py-1.5"
          on:click={() => shop.selected = !shop.selected}>{shop.title}</button>
      {/each}
    </div>
    <div class="whitespace-nowrap overflow-x-scroll">
      <table class="w-full text-sm">
        {#each Object.keys(report.data.categories).toSorted() as categoryId}
          <tr class="border-b">
            <th></th>
            <th></th>
            {#each filterShops(shops) as shop}
              <th class="text-center font-bold py-2" colspan="{sizeLabels.length + 2}">{shop.title}</th>
            {/each}
          </tr>
          <tr class="border-b">
            {#each filterShops(shops) as _, index}
              {#if index === 0 }
                <th class="text-left text-xl font-bold py-2">{report.data.categories[categoryId].title}</th>
                <th class="text-left font-bold pr-2">Итого</th>
                <th class="text-center px-2">|</th>
              {/if}
              {#each sizeLabels as size}
                <th class="text-slate-400 font-bold uppercase px-1 min-w-[40px]">
                  <SizeLabel size={size}/>
                </th>
              {/each}
              <th class="px-1 text-center font-normal text-slate-400">Кол-во</th>
              <th class="px-1 text-left font-normal text-slate-400">|</th>
            {/each}
          </tr>
          {#each filterItems(categoryId) as item}
            <tr class="group hover:bg-blue-100 border-b" class:bg-blue-300={key === getKey(categoryId, item)}
                on:click={() => selectRow(categoryId, item)} on:keydown>
              <td>
                <div class="flex w-full h-full items-center gap-1">
                  <div class="w-[40px] h-[40px]"><img src="{item.values.picture}" alt=""></div>
                  <div>{item.values.title}</div>
                </div>
              </td>

              <td class="font-bold text-left">{item.total}</td>
              <td class="text-center font-normal text-slate-400">|</td>
              {#each filterShops(shops) as shop}
                {#each sizeLabels as size}
                  <td class="text-center">
                    {#if item.shops[shop.id]?.sizes[size]}
                      <div
                        class="relative flex justify-center items-center border-2 mx-auto w-[60px] h-9 rounded-xl px-2 whitespace-nowrap text-center font-bold">
                        <div
                          class="{key === getKey(categoryId, item) ? 'block' : 'hidden'} pointer-events-none mb-2 absolute -top-0 px-2 text-xs border border-white rounded-full left-1/2 -translate-y-1/2 -translate-x-1/2 bg-gray-200 text-slate-400 font-light">
                          <span class="uppercase"><SizeLabel size={size}/></span>
                        </div>
                        <span><Num value={item.shops[shop.id]?.sizes[size]}/></span>
                      </div>
                    {/if}
                  </td>
                {/each}
                <td class="text-center">{item.shops[shop.id]?.total || 0}</td>
                <td class="text-center text-slate-200">|</td>
              {/each}
            </tr>
          {/each}
          <tr class="border-b bg-slate-100 text-slate-400 font-bold text-center">
            <td></td>
            <td class="text-left py-2">{report.data.categories[categoryId].total ?? 0}</td>
            <td class="font-normal">|</td>
            {#each filterShops(shops) as shop}
              {#each sizeLabels as size}
                <td>{report.data.categories[categoryId].shops[shop.id]?.sizes?.[size] || 0}</td>
              {/each}
              <td>{report.data.categories[categoryId].shops[shop.id]?.total || 0}</td>
              <td>|</td>
            {/each}
          </tr>
        {/each}
        <tr class="text-center">
          <td></td>
          <td class="text-left py-2 font-bold">{report.data.total}</td>
          <td class="font-normal">|</td>
          {#each filterShops(shops) as shop}
            {#each sizeLabels as size}
              <td>{report.data.shops[shop.id]?.sizes?.[size] || 0}</td>
            {/each}
            <td>{report.data.shops[shop.id]?.total || 0}</td>
            <td>|</td>
          {/each}
        </tr>
      </table>
    </div>
  {/if}
</div>
