<script>
  export let selected;
  export let options = [];
  export let key = 'id';
  export let label = 'title';
</script>

<select
  autocomplete="off"
  bind:value={selected}
  class="flex h-9 cursor-pointer appearance-none items-center justify-center rounded-lg border-1 border-slate-200 bg-white px-2.5"
>
  {#each options as option}
    <option value={option[key]}>
      {option[label]}
    </option>
  {/each}
</select>
