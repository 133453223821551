<script>
    export let order;
</script>

<div class="break" style:order></div>

<style>
    .break {
        flex-basis: 100%;
        height: 0;
    }

    @media only screen and (min-width: 1180px) {
        .break {
            display: none;
        }
    }
</style>