<script>
  export let card;

  $: itemGroups = formatItems(card);

  function formatItems(card) {
    const itemGroups = {};
    card.data.items.forEach((item) => {
      if (!itemGroups[item.id]) {
        itemGroups[item.id] = {
          hasSizes: true,
          items: [],
          cut: 0,
          quantityPerPiece: 0,
          thisQuantity: 0,
          footage: 0,
          price: 0,
        };
      }
      itemGroups[item.id].hasSizes &= item.hasSizes;
      if (item.hasSizes) {
        itemGroups[item.id].items.push(item);
      } else {
        itemGroups[item.id].items = [item, ...itemGroups[item.id].items];
        itemGroups[item.id].cut += item.sizes["one size"]?.cut || 0;
        itemGroups[item.id].quantityPerPiece +=
          item.sizes["one size"]?.quantityPerPiece || 0;
        itemGroups[item.id].thisQuantity +=
          item.sizes["one size"]?.thisQuantity || 0;
        itemGroups[item.id].footage += item.sizes["one size"]?.footage || 0;
        itemGroups[item.id].price += item.sizes["one size"]?.price || 0;
      }
    });
    return Object.values(itemGroups);
  }

  function getItemSizes(item) {
    const sizes = [];
    card.data.sizes.forEach((size) => {
      if (size.value) {
        item.sizes[size.id].label = size.id;
        sizes.push(item.sizes[size.id]);
      }
    });
    return sizes;
  }

  function hideZero(value, label) {
    if (value) {
      return `${Math.round(value * 100) / 100}&nbsp;${label}`;
    }
    return "";
  }

  function getAveragePrice(sizes) {
    return sizes.reduce((prev, curr) => prev + curr.price, 0) / sizes.length;
  }
</script>

<div class="hidden w-full flex-col gap-2.5 print:flex">
  <div>
    <h1>
      #{card.id || ""}
      {card.data.title || ""}
      {new Date().toLocaleDateString()}
    </h1>
  </div>
  <table class="table-fixed">
    <thead>
      <tr class="uppercase">
        {#each card.data.sizes as size}
          <th>{size.id}</th>
        {/each}
        <th>Итого</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        {#each card.data.sizes as size}
          <td>{@html hideZero(size.value, "шт")}</td>
        {/each}
        <td>
          {card.data.total}
        </td>
      </tr>
      <tr>
        {#each card.data.sizes as _}
          <td></td>
        {/each}
        <td>
          {@html hideZero(card.data.totalPrice, "р")}
        </td>
      </tr>
      <tr>
        {#each card.data.sizes as _}
          <td></td>
        {/each}
        <td>
          {@html hideZero(getAveragePrice(card.data.sizes), "р")}
        </td>
      </tr>
    </tbody>
  </table>
  <table>
    <thead>
      <tr>
        <th>Артикул</th>
        <th>Наименование</th>
        <th>Комментарий</th>
        <th>Размер</th>
        <th>Нарезка</th>
        <th>Кол-во/ед.</th>
        <th>Кол-во</th>
        <th>Метраж</th>
        <th>Подпись</th>
      </tr>
    </thead>
    <tbody>
      {#each itemGroups as itemGroup}
        {#if !itemGroup.hasSizes}
          <tr>
            <td>
              {itemGroup.items[0].vendorCode}
            </td>
            <td>
              {itemGroup.items[0].title}
            </td>
            <td />
            <td class="font-bold">Итого</td>
            <td />
            <td />
            <td class="font-bold">
              {@html hideZero(itemGroup.thisQuantity, "шт")}
            </td>
            <td class="font-bold">{@html hideZero(itemGroup.footage, "м")}</td>
            <td />
          </tr>
        {/if}
        {#each itemGroup.items as item}
          {#if item.hasSizes}
            <tr>
              <td>
                {item.vendorCode}
              </td>
              <td>
                {item.title}
              </td>
              <td>
                {item.comment}
              </td>
              <td class="font-bold">Итого</td>
              <td />
              <td />
              <td class="font-bold">
                {@html hideZero(item.total.thisQuantity, "шт")}
              </td>
              <td class="font-bold">
                {@html hideZero(item.total.footage, "м")}
              </td>
              <td />
            </tr>
            {#each getItemSizes(item) as itemSize}
              <tr>
                <td />
                <td />
                <td />
                <td class="uppercase">{itemSize.label}</td>
                <td>{@html hideZero(itemSize.cut, "см")}</td>
                <td>{@html hideZero(itemSize.quantityPerPiece, "шт")}</td>
                <td>{@html hideZero(itemSize.thisQuantity, "шт")}</td>
                <td>{@html hideZero(itemSize.footage, "м")}</td>
                <td></td>
              </tr>
            {/each}
          {:else}
            <tr>
              <td>
                {#if itemGroup.hasSizes}{item.vendorCode}{/if}
              </td>
              <td>
                {#if itemGroup.hasSizes}{item.title}{/if}
              </td>
              <td>{item.comment}</td>
              <td>&nbsp;</td>
              <td>{@html hideZero(item.sizes["one size"]?.cut, "см")}</td>
              <td
                >{@html hideZero(
                  item.sizes["one size"]?.quantityPerPiece,
                  "шт",
                )}</td
              >
              <td
                >{@html hideZero(
                  item.sizes["one size"]?.thisQuantity,
                  "шт",
                )}</td
              >
              <td>{@html hideZero(item.sizes["one size"]?.footage, "м")}</td>
              <td></td>
            </tr>
          {/if}
        {/each}
      {/each}
    </tbody>
  </table>
</div>

<style>
  table {
    @apply w-full text-xs;
  }

  td,
  th {
    @apply border-1 border-black text-center;
  }
</style>
