<script>
  import page from "page"
  import routes from "./routes"
  import { onMount } from "svelte"
  import { user } from "./lib/stores/user"
  import Navbar from "./lib/components/Navbar.svelte"
  import * as Sentry from "@sentry/svelte"
  import { getBaseUrl } from "./lib/tools/request.js"

  let currentPage = null
  let params = {}

  onMount(async () => {
    if (import.meta.env.VITE_PUBLIC_SENTRY_DSN) {
      Sentry.init({
        dsn: import.meta.env.VITE_PUBLIC_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration()
        ],
        tracesSampleRate: 0.8,
        tracePropagationTargets: ["localhost", "127.0.0.1", /^https:\/\/panel.igandesigner\.ru\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tunnel: new URL('public/sentry', getBaseUrl()).href
      })
    }

    await user.get()
    routes.forEach((route) => {
      page(
        route.path,
        (ctx, next) => {
          params = { ...ctx.params }
          next()
        },
        () => {
          if (!$user && route.path !== "/login") {
            page.redirect(`/login?next=${encodeURIComponent(window.location.pathname)}`)
          } else {
            currentPage = route.component
          }
        }
      )
    })
    page.start()
  })
</script>


<Navbar />
<svelte:component this={currentPage} {params} />
