<script>
  import {onMount} from "svelte";

  export let placeholder = ''
  export let value

  let area = null

  const resize = () => {
    area.style.height = (area.scrollHeight) + "px";
  }

  onMount(() => setTimeout(resize, 500))
</script>

<div class="w-full flex flex-col gap-2.5">
  <textarea bind:this={area} style="form-sizing: content" class="w-full h-full border border-slate-200 rounded-xl p-2 resize-none"
            placeholder={placeholder} bind:value={value} on:input={resize}></textarea>
  <slot/>
</div>
