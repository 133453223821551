<script>
  import { sizeLabels } from "../stores/sizeLabels.js";
  import { storagePopupKey } from "../stores/storagePopupKey.js";
  import SizeLabel from "./SizeLabel.svelte";
  import Num from "./Num.svelte";
  import StorageItemSizesRow from "./StorageItemSizesRow.svelte";
  import { request } from "../tools/request.js";
  import { onMount } from "svelte";
  import StorageItemHistoryModal from "./StorageItemHistoryModal.svelte";
  import { storageTransferHistoryItem } from "../stores/storageTransferHistoryItem";

  export let item;
  export let readonly = false;

  let loading = true;
  let storages = [];
  let total = {};

  $: selectedStorage = storages.find(
    (s) => s.id === $storageTransferHistoryItem.storage,
  );

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = [...data.results];
    }
  };

  const fetchTotal = async () => {
    const { ok, data } = await request({
      path: "storage-items/total-aggregate",
      params: {
        item: item.id,
      },
    });

    if (ok) {
      total = data;
    }
  };

  onMount(async () => {
    await Promise.all([fetchStorages(), fetchTotal()]);
    loading = false;
  });
</script>

{#if !loading}
  <div class="w-full overflow-x-scroll">
    <table
      class="table-fixed text-sm border-collapse -mx-5 {loading
        ? 'opacity-20 pointer-events-none'
        : ''}"
    >
      <thead>
        <tr>
          <th colspan="4" class="text-xl text-left pl-10 py-1">Остатки</th>
          <th class="text-slate-400 font-normal">Кол-во</th>
          {#each sizeLabels as size}
            <th
              class="w-14 text-slate-400 uppercase"
              class:text-black={$storagePopupKey.split("_").at(-1) === size}
            >
              <SizeLabel {size} />
            </th>
          {/each}
          <th class="text-slate-400 font-normal">Цена</th>
          <th class="text-slate-400 font-normal">Сумма</th>
        </tr>
      </thead>
      <tbody>
        {#each storages as storage}
          <StorageItemSizesRow
            on:updated
            {readonly}
            storageId={storage.id}
            {item}
          >
            <div class="text-left pl-5">
              {storage.title}
            </div>
          </StorageItemSizesRow>
        {/each}
        <tr>
          <td colspan="4" class="text-xl font-bold pl-10 py-3">Итого</td>
          <td class="text-center font-bold py-3">
            <Num value={total.quantity} />
          </td>
          {#each sizeLabels as sizeLabel}
            <td
              class="text-center font-bold py-3"
              class:opacity-20={total[sizeLabel] === 0}
            >
              <Num value={total[sizeLabel]} />
            </td>
          {/each}
          <td></td>
          <td class="text-center font-bold py-3">
            <Num value={total.price} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <StorageItemHistoryModal storage={selectedStorage} />
{/if}
