<script>
  export let options = []
  export let value
</script>

<select bind:value class="select" autocomplete="off" on:change>
  {#each options as option}
    <option value={option.value}>{option.label}</option>
  {/each}
</select>

<style>
  .select {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D8D8DA;
    border-radius: 8px;
    padding: 6px 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    background-color: white;
  }
</style>