<script>
  import { request } from "../tools/request";
  import { onMount } from "svelte";
  import SizeLabel from "./SizeLabel.svelte";
  import { reverseSizesMap } from "../stores/sizeLabels.js";
  import Modal from "./Modal.svelte";

  export let revision = {};
  let searchTimer = null;
  let revisionItemTimers = {};
  let itemStatuses = {};
  let loading = false;
  let revisionItems = [];
  let search = "";
  let searchInput;
  let commentTimer = null;
  let selectedRow = null;
  let searchResult = null;
  let disabled = false;

  let totalRevisionQuantity = 0;
  let totalSourceQuantity = 0;
  let totalDelta = 0;

  let uploadingBarcodes = false;
  let showBarcodesModal = false;
  let importedBarcodes = [];
  let importError = new Map();

  $: totalRevisionQuantity = revisionItems.reduce(
    (acc, curr) => acc + curr.revisionQuantity,
    0,
  );
  $: totalSourceQuantity = revisionItems.reduce(
    (acc, curr) => acc + curr.sourceQuantity,
    0,
  );
  $: totalDelta = revisionItems.reduce(
    (acc, curr) => acc + curr.quantityDelta,
    0,
  );

  $: disabled = revision?.status === "locked";

  const fetchRevisionItems = async () => {
    if (!revision.id) {
      return;
    }

    loading = true;

    revisionItems = [];
    let page = 1;
    while (true) {
      const { ok, data } = await request({
        path: "revision-items",
        params: {
          page,
          revision: revision.id,
        },
      });

      if (ok) {
        revisionItems = revisionItems.concat(data.results);
      }
      page += 1;
      if (!data.next) {
        break;
      }
    }
    loading = false;
  };

  const updateComment = async (val) => {
    if (commentTimer) {
      clearTimeout(commentTimer);
    }

    commentTimer = setTimeout(async () => {
      await request({
        path: `revisions/${revision.id}`,
        json: {
          id: revision.id,
          comment: val,
        },
      });
    }, 500);
  };

  const uploadBarcodes = async () => {
    uploadingBarcodes = true;
    importError = new Set();
    const { ok, error } = await request({
      path: `revisions/${revision.id}/upload-barcodes/`,
      method: "post",
      json: {
        barcodes: importedBarcodes,
      },
    });

    if (ok) {
      showBarcodesModal = false;
      importedBarcodes = [];
      await fetchRevisionItems();
    } else {
      importError = new Map();
      error.indexes.forEach((i) => importError.set(i, true));
    }

    uploadingBarcodes = false;
  };

  const filterItems = (items) => {
    return items.filter((i) => !!i.revisionQuantity + i.sourceQuantity);
  };

  const searchBarCode = async (val) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    if (!val) {
      return;
    }

    searchResult = null;

    searchTimer = setTimeout(async () => {
      const { ok, data } = await request({
        path: "stock-items",
        params: {
          search: val,
        },
      });
      if (!ok) {
        return;
      }
      const match = data.results[0];
      if (!match?.sizeByCode) {
        searchResult = false;
        searchInput.select();
        return;
      }
      const revisionItem = revisionItems.find(
        (i) => i.stockItem.id === match.id && match.sizeByCode === i.size,
      );

      if (!revisionItem) {
        searchResult = false;
        searchInput.select();
        return;
      }
      searchResult = true;
      updateRevisionItem(revisionItem.id, revisionItem.revisionQuantity + 1);
      setTimeout(() => {
        search = "";
        document
          .getElementById(revisionItem.id)
          .scrollIntoView({ block: "end" });
        selectedRow = revisionItem.id;
      }, 300);
    }, 800);
  };

  const updateRevisionItem = (itemId, val) => {
    if (revisionItemTimers[itemId]) {
      clearTimeout(revisionItemTimers[itemId]);
    }

    revisionItemTimers[itemId] = setTimeout(async () => {
      const { ok } = await request({
        path: `revision-items/${itemId}`,
        json: {
          id: itemId,
          revisionQuantity: val || 0,
        },
      });

      itemStatuses = {
        ...itemStatuses,
        [itemId]: ok,
      };

      if (ok) {
        const { data } = await request({
          path: `revision-items/${itemId}`,
        });

        revisionItems = revisionItems.map((i) => {
          if (i.id === itemId) {
            return {
              ...data,
              code: i.code,
              stockItem: i.stockItem,
            };
          }
          return i;
        });

        setTimeout(() => {
          itemStatuses = {
            ...itemStatuses,
            [itemId]: undefined,
          };
        }, 1000);
      }
    }, 200);
  };

  const exportRevision = async () => {
    const { ok, data } = await request({
      path: "revision-items/create_export_url",
      method: "POST",
      params: {
        revision: revision.id,
      },
    });
    if (ok) {
      window.open(data.url, "_blank");
    }
  };

  const applyRevision = async () => {
    if (loading || !revision.id) {
      return;
    }

    if (!confirm("Вы уверены?")) {
      return;
    }

    loading = true;

    const { ok } = await request({
      path: `revisions/${revision.id}/apply`,
      method: "POST",
    });

    if (ok) {
      alert("Остатки обновлены успешно");
      window.location.reload();
    } else {
      loading = false;
    }
  };

  onMount(async () => {
    await Promise.all([fetchRevisionItems()]);
  });
</script>

<div class="hidden print:block w-full text-xs">
  <table class="table-fixed border-collapse border border-slate-400">
    <thead>
      <tr>
        <th class="border border-slate-400">Позиция</th>
        <th class="border border-slate-400">Факт</th>
        <th class="border border-slate-400">Документ</th>
        <th class="border border-slate-400">Расхождение</th>
      </tr>
    </thead>
    <tbody>
      {#each filterItems(revisionItems) as item}
        <tr>
          <td class="border border-slate-400 text-left">
            <span>{item.stockItem.title}</span>
            <span><SizeLabel size={reverseSizesMap[item.size]} /></span>
          </td>
          <td class="border border-slate-400 text-center">
            <span>{item.revisionQuantity}</span>
          </td>
          <td class="border border-slate-400 text-center">
            <span>{item.sourceQuantity}</span>
          </td>
          <td class="border border-slate-400 text-center">
            <span>{item.quantityDelta}</span>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<div class="print:hidden w-full flex flex-col gap-2 px-5 py-5 bg-white">
  <div class="whitespace-nowrap overflow-x-scroll flex gap-2 items-center">
    <a href="/revisions" class="print:hidden flex h-full"
      ><span class="material-symbols-outlined">arrow_back_ios_new</span></a
    >
    <span class="font-bold text-xl"
      >Инвентаризация {revision.storage?.title ?? ""} #{revision.id}
      от {new Date(revision.createdAt).toLocaleDateString()}</span
    >
  </div>
  <div
    class="sticky top-0 bg-white py-2 flex flex-col gap-2 md:flex-row md:whitespace-nowrap overflow-x-scroll"
  >
    <div>
      <div
        class:border-red-400={searchResult === false}
        class:bg-red-100={searchResult === false}
        class:border-green-400={searchResult === true}
        class:bg-green-100={searchResult === true}
        class="w-full h-full flex items-center transition-colors justify-center rounded-lg border px-2 py-2.5"
      >
        <span class="material-symbols-outlined">barcode</span>
        <input
          bind:value={search}
          bind:this={searchInput}
          placeholder="Штрихкод"
          on:input={(e) => searchBarCode(e.currentTarget.value)}
          class="bg-transparent border-0 w-full md:min-w-[300px] pl-2.5"
        />
      </div>
    </div>
    <div class="flex gap-2">
      {#if !disabled}
        <button
          on:click={() => (showBarcodesModal = true)}
          class="px-2 py-1 border rounded-xl flex justify-center items-center h-full"
        >
          Загрузить ШК
        </button>
      {/if}
      <button
        class="px-2 py-1 border rounded-xl flex justify-center items-center h-full"
        on:click={exportRevision}
        >Скачать
      </button>
      {#if !loading && !disabled}
        <button
          disabled={loading}
          class="px-2 py-1 rounded-xl flex justify-center items-center h-full text-white bg-pink-500"
          on:click={applyRevision}
          >Обновить остатки
        </button>
      {/if}
    </div>
  </div>
  <div>
    <div class="w-full flex">
      <textarea
        {disabled}
        class="resize-none w-full md:w-1/2 border rounded-xl border-slate-200 p-2"
        rows="2"
        placeholder="Комментарий"
        value={revision.comment}
        on:input={(e) => updateComment(e.currentTarget.value)}
      ></textarea>
    </div>
  </div>
  <div class="w-full whitespace-nowrap overflow-x-scroll">
    {#if loading}
      <tr>
        <td
          ><span class="material-symbols-outlined animate-spin"
            >progress_activity</span
          ></td
        >
      </tr>
    {:else}
      <table class="w-full md:w-fit">
        <thead class="hidden md:table-header-group">
          <tr
            class="font-bold text-sm text-slate-400 border-b-slate-200 border-b"
          >
            <th class="text-[20px] text-black py-4 text-left">Позиции</th>
            <th class="text-right pl-5">Факт: {totalRevisionQuantity}</th>
            <th class="text-right pl-5">Документ: {totalSourceQuantity}</th>
            <th class="text-right px-5"
              >Расхождение: <span
                class:text-green-400={totalDelta > 0}
                class:text-red-400={totalDelta < 0}
                >{totalDelta > 0 ? "+" : ""}{totalDelta}</span
              >
            </th>
          </tr>
        </thead>
        <thead class="md:hidden">
          <tr>
            <th>
              <div
                class="flex flex-col text-left text-slate-400 text-sm font-normal py-4"
              >
                <div class="py-1 font-bold text-black text-lg">Позиции</div>
                <span>Документ: {totalSourceQuantity}</span>
                <span>
                  Факт: {totalRevisionQuantity}
                </span>
                <span
                  >Расхождение: <span
                    class:text-green-400={totalDelta > 0}
                    class:text-red-400={totalDelta < 0}
                    >{totalDelta > 0 ? "+" : ""}{totalDelta}</span
                  >
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {#each filterItems(revisionItems) as item}
            <tr
              id={item.id}
              class="md:table-row flex flex-wrap items-center border-b border-b-slate-200 text-sm py-2"
              class:bg-green-50={item.quantityDelta > 0}
              class:bg-red-50={item.quantityDelta < 0}
              class:bg-blue-300={selectedRow === item.id}
            >
              <td class="py-1 pr-3 w-full">
                <div
                  class="w-full flex gap-1 h-full items-center md:text-black text-slate-400"
                >
                  <a
                    href="/stock/{item.stockItem.id}"
                    class="hidden md:block w-[40px] h-[40px]"
                  >
                    <img alt="" src={item.stockItem.picture} />
                  </a>
                  <div class="whitespace-break-spaces">
                    <span>{item.stockItem.title}</span>
                    <span class="font-bold uppercase"
                      ><SizeLabel size={reverseSizesMap[item.size]} /></span
                    >
                  </div>
                </div>
              </td>
              <td class="text-right font-bold md:w-auto w-1/3">
                <div
                  class="border bg-slate-100 transition-colors rounded-lg text-right px-2 py-1"
                >
                  <input
                    {disabled}
                    type="number"
                    class="w-full bg-transparent"
                    value={item.revisionQuantity}
                    on:input={(e) =>
                      updateRevisionItem(item.id, e.target.value)}
                  />
                </div>
              </td>
              <td class="text-right md:w-auto w-1/3">
                <span
                  ><span class="md:hidden text-slate-400 font-bold"
                    >Документ:
                  </span>{item.sourceQuantity}</span
                >
              </td>
              <td
                class="text-right pr-5 md:w-auto w-1/3"
                class:text-green-400={item.quantityDelta > 0}
                class:text-red-400={item.quantityDelta < 0}
              >
                <span
                  >{item.quantityDelta > 0 ? "+" : ""}{item.quantityDelta}</span
                >
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/if}
  </div>
</div>

<Modal bind:active={showBarcodesModal}>
  <div class="flex flex-col gap-2">
    <h2 class="text-xl font-medium">Загрузить штрихкоды</h2>
    <div class="flex gap-1 w-full">
      <div class="flex flex-col py-1.5 text-slate-400 text-right basis-10">
        {#each importedBarcodes as _, index}
          <div
            class={`w-full pr-1.5 ${
              importError.has(index)
                ? "border-red-400 text-red-400"
                : "border-white"
            }`}
          >
            {index + 1}
          </div>
        {/each}
      </div>
      <div
        contenteditable
        on:input={(e) => {
          importedBarcodes = e.target.innerText
            .trim()
            .split("\n")
            .filter((l) => l.length);
        }}
        class="w-full resize-none border border-slate-200 rounded-lg px-2.5 py-1.5 whitespace-pre-wrap"
      ></div>
    </div>
    <div class="flex w-full justify-end items-center gap-2">
      {#if importError.size !== 0}<span class="text-red-400"
          >Ошибка импорта</span
        >{/if}
      <div>
        <button
          disabled={uploadingBarcodes}
          on:click={uploadBarcodes}
          class="disabled:bg-slate-200 px-2 py-1 border rounded-xl flex justify-center items-center h-full"
          >Импорт
        </button>
      </div>
      <div>
        <button
          on:click={() => (showBarcodesModal = false)}
          class="px-2 py-1 border rounded-xl flex justify-center items-center h-full"
          >Закрыть
        </button>
      </div>
    </div>
  </div>
</Modal>
