<script>
  import { request } from "../tools/request.js"

  export let search = ""
  let loading = false
  let searchTimer = null
  let categories = []

  $: filterItems(search)

  const filterItems = () => {
    clearTimeout(searchTimer)
    searchTimer = setTimeout(fetchItems, 500)
  }

  export async function fetchItems() {
    loading = true
    const results = []

    const { data: categoriesList } = await request({
      path: "stock-categories"
    })

    const calls = []
    for (const c of categoriesList?.results) {
      calls.push(request({
        path: "stock-items",
        params: {
          category: c.id,
          search
        }
      }))
      c.items = []
      results.push(c)
    }

    const res = await Promise.all(calls)
    res.forEach((r, i) => {
      results[i].items = r.data.results
    })
    categories = [...results]
    loading = false
  }

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.totalQuantity + b.totalQuantity === 0) {
        return a.vendorCode > b.vendorCode
      }

      if (b.totalQuantity === 0) {
        return -1
      }
      if (a.totalQuantity === 0) {
        return 1
      }
      return a.vendorCode > b.vendorCode
    })
  }
</script>

<table class="w-full overflow-hidden text-sm border-collapse {loading ? 'opacity-20 pointer-events-none' : ''}">
  <tbody>
  {#each categories as category}
    {#if category.items.length || !search}
      <slot name="header" {category}></slot>
    {/if}
    {#if !category.items.length && !search}
      <slot name="placeholder">
        <tr>
          <td class="pl-5 text-slate-400">
            <span>---</span>
          </td>
        </tr>
      </slot>
    {/if}
    {#each sortItems(category.items) as item}
      <slot name="item" {item}></slot>
    {/each}
    <slot name="category-total" {category}></slot>
  {/each}
  <slot name="total"></slot>
  </tbody>
</table>