<script>
  import { createEventDispatcher } from "svelte";
  export let search = "";
  const dispatch = createEventDispatcher();

  let timer;

  const updateSearch = async (e) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      search = e.target.value;
      dispatch("updated");
    }, 500);
  };
</script>

<div class="w-full flex h-9">
  <div
    class="w-full flex items-center justify-center rounded-lg rounded-tr-none rounded-br-none border-1 border-slate-200 px-2 py-2.5"
  >
    <i class="material-symbols-outlined text-xl">search</i>
    <input
      placeholder="Поиск"
      value={search}
      on:input={updateSearch}
      class="border-0 min-w-[100px] w-full pl-2.5"
    />
  </div>
  <button
    class="flex items-center justify-center rounded-tr-lg rounded-br-lg border-r-1 border-t-1 border-b-1 border-slate-200 px-2.5 text-sm"
    >Искать</button
  >
</div>
